$gallery-nav-space: 70px !default;
$gallery-thumb-border-width: 2px !default;

$gallery-main-image-width: 63.8% !default;
$gallery-sidebar-width: (100% - $gallery-main-image-width) !default;

$gallery-main-image-width-at-xl: 69% !default;
$gallery-sidebar-width-at-xl: (100% - $gallery-main-image-width-at-xl) !default;

.gallery {
  position: relative;

  &__nav {
    display: none;
  }

  &__main-image {
    margin-bottom: $spacer-y;
    background-color: $gray-lightest;
    position: relative;

    .image__copyright {
      bottom: auto;
      top: 0;
    }
  }

  &__button {
    background-color: $white;
    width: 3rem;
    height: 3rem;
    display: block;
    position: absolute;
    right: 1rem;
    bottom: 1rem;
    z-index: 1;
  }

  &__button-icon {
    height: 1.6em;
    width: 1.6em;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: $gray-dark;
    transition: color $link-transition-duration;
  }

  &__button:focus,
  &__button:hover {
    background-color: $brand-primary;

    .gallery__button-icon {
      color: $white;
    }
  }

  &__pos {
    display: inline-block;
    margin-right: ($spacer-x);
  }
  &__pos-current {
    margin-right: ($spacer-x / 3);
  }
  &__pos-total {
    margin-left: ($spacer-x / 3);
  }

  &__controls {
    display: inline-block;
  }

  &__title {
    @include type-scale(1);
    font-family: $font-family-special;
    font-weight: bold;
  }

  &__thumbs {
    @include clearfix;
    margin: 0;
    padding: 0;
    list-style: none;
    display: none;
  }

  &__tour {
    text-align: center;
  }

  &__thumb {
    float: left;
    width: 60px;
    border: $gallery-thumb-border-width solid transparent;

    &:hover {
      border-color: $green-gray;
    }

    &:focus {
      outline: 5px solid $green-gray;
    }

    &.is-active {
      border-color: $brand-primary;
    }
  }

  &__name {
    margin-top: 60px;
    @include type-scale(1);
    font-family: $font-family-special;
    font-weight: bold;
  }

  &__slide:not(:first-child) {
    display: none;
  }

  .slick-initialized &__slide {
    display: block;
  }

  .article &__slide {
    background-color: #fff;
  }

  /**
   * Layout
   */
  // Large+ VPs
  @include media-breakpoint-up('lg') {
    &__dots {
      display: none !important; // Override inline styles
    }

    &__slide {
      @include clearfix;
    }

    &__main-image {
      float: left;
      width: $gallery-main-image-width;
      min-height: 460px;
      margin-bottom: 0;
    }
    &--right &__main-image {
      float: right;
    }

    &__info {
      margin-left: $gallery-main-image-width;
      padding-top: $gallery-nav-space;
      padding-left: $grid-gutter-width;
    }
    &--right &__info {
      margin-left: 0;
      margin-right: $gallery-main-image-width;
      padding-left: 0;
      padding-right: $grid-gutter-width;
    }

    &__nav,
    &__thumbs,
    &__tour {
      display: block;
      position: absolute;
      left: $gallery-main-image-width;
      right: 0;
      margin-left: $grid-gutter-width;
    }
    &--right &__nav,
    &--right &__thumbs,
    &--right &__tour {
      left: 0;
      right: $gallery-main-image-width;
      margin-left: 0;
      margin-right: $grid-gutter-width;
    }

    &__nav {
      top: $grid-gutter-width;
    }

    &__thumbs {
      bottom: 0;
    }

    &__tour {
      text-align: left;
      bottom: 70px;
    }

    &__tour--rows-2 {
      bottom: 110px;
    }

    &__tour--rows-3 {
      bottom: 150px;
    }

    &__tour--rows-4 {
      bottom: 190px;
    }
  }

  // X-Large VPs
  @include media-breakpoint-up('xl') {
    &__main-image {
      width: $gallery-main-image-width-at-xl;
      min-height: 579px;
    }

    &__info {
      margin-left: $gallery-main-image-width-at-xl;
    }
    &--right &__info {
      margin-right: $gallery-main-image-width-at-xl;
    }

    &__nav,
    &__thumbs,
    &__tour {
      left: $gallery-main-image-width-at-xl;
    }
    &--right &__nav,
    &--right &__thumbs,
    &--right &__tour {
      right: $gallery-main-image-width-at-xl;
    }
  }
}

/**
 * No-JS Fallback
 */
.no-js .gallery {
  &__nav {
    display: none;
  }

  &__slide:not(:first-child) {
    display: none;
  }
}
