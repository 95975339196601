$form-multiselect-menu-margin: 10px !default;

.form-multiselect__menu {
  margin: 0;
  padding: 0;
  list-style: none;

  ul {
    margin-left: 15px;
  }

  &:first-child {
    padding: 0;
  }

  &-item {
    @include clearfix;

    &--with-sub {
      padding: $form-multiselect-menu-margin 0;
      border-bottom: 1px solid $gray-light;
    }

    &:hover {
      cursor: pointer;
    }

    &:first-child {
      padding-top: 0;
    }

    &:last-child {
      border: 0;
    }
  }

  &-item-content {
    margin: 5px 0;
    float: left;
  }

  &-item-toggle {
    margin: 5px 0 0 0;
    float: right;
    display: none;
  }

  &-item-toggle-icon {
    width: 0.7rem;
    height: 0.7rem;
  }

  &-checkbox {
    margin-right: $form-multiselect-menu-margin;
    float: left;
  }

  &-info {
    margin: 0 $form-multiselect-menu-margin;

    color: $green-gray;
    font-weight: bold;
  }

  // Small+ VPs
  @include media-breakpoint-up('sm') {
    &-item-toggle {
      display: block;
    }

    &-item {
      ul {
        display: none;
      }

      &.is-expanded ul {
        display: block;
      }

      &.is-expanded &-toggle--collapsed {
        display: none;
      }

      &.is-expanded &-toggle--expanded {
        display: block;
      }

      &-toggle--collapsed {
        display: block;
      }

      &-toggle--expanded {
        display: none;
      }
    }
  }
}
