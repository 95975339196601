.form-text {
  @include type-scale(-1);
  display: block;
  margin-top: ($spacer / 4);
}

.form-subheader {
  display: block;
  margin-top: ($spacer * 2);
}

.form-info {
  > :last-child {
    margin-bottom: 0;
  }
}

.form-errors {
  margin-bottom: $spacer;
  color: $brand-danger;

  > :last-child {
    margin: 0;
  }
}

.required {
  color: $brand-danger;
}
