$event-date-number-font-size-at-md: 6rem !default;
$event-date-number-font-size-at-lg: 9rem !default;

$event-date-number-letter-spacing-at-md: -0.4rem !default;
$event-date-number-letter-spacing-at-lg: -0.8rem !default;

$event-date-lead-font-size: 0.85rem !default;

.event-date {
  &__container {
    color: $white;
    background-color: $green-gray;

    text-align: center;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }

  &__frame--header {
    padding: 40px;
    display: flex;
    flex-direction: row;
  }

  &__number {
    font-family: $font-family-special;
    font-size: $event-date-number-font-size-at-md;
    font-weight: bold;

    line-height: $event-date-number-font-size-at-md;
    letter-spacing: $event-date-number-letter-spacing-at-md;
  }

  &__lead {
    font-weight: bold;
    font-size: $event-date-lead-font-size;

    text-transform: uppercase;
  }

  &__seperator {
    font-family: $font-family-special;
    font-size: $event-date-number-font-size-at-md;
    font-weight: bold;

    line-height: 5rem;
    margin: 0 1rem;
  }

  @include media-breakpoint-up('md') {
    &__frame--header {
      display: flex;
      flex-direction: column;
    }
  }

  @include media-breakpoint-up('lg') {
    &__number, &__seperator {
      font-size: $event-date-number-font-size-at-lg;
    }

    &__number {
      line-height: 8rem;
      letter-spacing: $event-date-number-letter-spacing-at-lg;
    }
  }
}
