$search-options-margin: 80px !default;

$search-filter-item-padding-width-at-md: 20px !default;
$search-filter-item-padding-height-at-md: 5px !default;
$search-filter-item-padding-width-at-lg: 15px !default;
$search-filter-item-padding-width-at-xl: 20px !default;

.search {
  &__results {
    opacity: 1;
    transition: opacity .3s;

    &--loading {
      opacity: .2;
    }
  }

  &__results-footer {
    display: flex;
    justify-content: center;
    padding-top: 2rem;
    padding-bottom: 1rem;
  }

  .article__container {
    margin-top: 0;
  }

  &__filter {
    @include list-unstyled;

    margin: 1rem 0;
    text-align: center;

    &-item {
      padding: 10px 0;

      line-height: 2.5;
      display: inline;

      a {
        @include type-scale(0);

        padding: 5px;
        font-family: $font-family-special;
        color: $black;
        white-space: nowrap;
      }

      &--active,
      &:hover {
        border-bottom: 4px solid $brand-primary;
      }
    }
  }

  &__options {
    @include clearfix;

    border-bottom: 1px solid $frame-color;

    &-title {
      @include type-scale(-1);
    }

    &-list {
      @include list-unstyled;
      @include type-scale(-1);

      margin: 0;
      padding: 5px 0;

      font-weight: bold;
      text-transform: uppercase;

      display: inline-block;
    }

    &-item {
      margin: 0 5px;
      display: inline;

      a {
        color: $green-gray;
      }

      &--active,
      &:hover {
        a {
          color: $brand-primary;
        }
      }
    }
  }

  .article-list {
    .article__title {
      @include type-scale(1);

      margin-bottom: 0.5rem;
      font-weight: bold;
    }
  }

  .article {
    &__tags {
      @include type-scale(-1);
      @include list-unstyled;
      @include clearfix;

      margin: 20px 0;

      &-item {
        margin: 0 10px 10px 0;
        padding: 5px 10px;

        float: left;
        border-radius: 15px;

        color: $white;
        background-color: $green-gray;

        font-weight: bold;
        text-transform: uppercase;
      }
    }

    &__properties {
      @include type-scale(-2);
      @include list-unstyled;
      @include clearfix;

      display: inline-block;

      &-item {
        padding: 0 10px;

        float: left;

        .symbol {
          color: $gray;
        }

        &:not(:last-child) {
          border-right: 1px solid $gray-lighter;
        }

        &:first-child {
          padding-left: 0;
        }
      }

      &-definition {
        align-items: center;
        display: flex;
        margin: 0;
      }

      &-icon {
        margin-right: 5px;
        height: 16px;
      }

      &-number {
        margin: 0;
      }
    }

    &__table {
      &-row {
        border-bottom: 10px solid $white;
      }

      &-item {
        &:first-child {
          padding-right: 2rem;

          text-align: right;
          text-transform: uppercase;

          color: $green-gray;
          font-weight: bold;
          @include type-scale(-1);

          hyphenate-limit-chars: auto 5;
          hyphenate-limit-lines: 2;
        }
      }
    }

    &__content {
      padding-top: 25px;

      float: left;
    }

    &__actions {
      float: right;

      text-align: right;
    }

    &__info {
      @include clearfix;

      margin: 10px 0;
      color: $green-gray;
    }

    &__button-right {
      color: $white;
    }

    &__price-tag {
      text-align: left;
      color: $dove;

      .text {
        border-bottom: 1px solid $orange;
        display: inline-block;
        vertical-align: middle;
        @include type-scale(-1);

        font-weight: bold;
        text-transform: uppercase;

        margin-right: $spacer;
      }

      .price {
        display: inline-block;
        vertical-align: middle;
        @include type-scale(1);
      }
    }
  }

  @include media-breakpoint-up('sm') {
    .article {
      &__button-right {
        position: absolute;
        right: 20px;
        bottom: 32px;
      }
    }
  }

  // Medium+ VPs
  @include media-breakpoint-up('md') {
    .article {
      &__properties {
        @include type-scale(1);
      }

      &__properties-icon {
        height: 21px;
      }

      &__price-tag {
        float: right;
      }

      &__button-right {
        right: 40px;
        bottom: 32px;
      }

      &__content {
        padding-top: 0;
      }
    }

    &__filter {
      margin-bottom: 2rem;

      &-item a {
        padding-left: $search-filter-item-padding-width-at-md;
        padding-right: $search-filter-item-padding-width-at-md;
        padding-top: $search-filter-item-padding-height-at-md;
        padding-bottom: $search-filter-item-padding-height-at-md;
      }
    }

    &__options {
      margin-left: $search-options-margin;
      margin-right: $search-options-margin;
    }
  }

  // Large+ VPs
  @include media-breakpoint-up('lg') {
    &__filter {
      &-item a {
        @include type-scale(1);
        padding-left: $search-filter-item-padding-width-at-lg;
        padding-right: $search-filter-item-padding-width-at-lg;
      }
    }
  }

  // X-Large VPs
  @include media-breakpoint-up('xl') {
    &__filter {
      &-item a {
        padding-left: $search-filter-item-padding-width-at-xl;
        padding-right: $search-filter-item-padding-width-at-xl;
      }
    }

    .article {
      &__price-tag {
        position: relative;
        top: -20px;

        .price {
          @include type-scale(3);
        }
      }
    }
  }
}
