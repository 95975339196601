$hero-search-height-at-md: 430px !default;

$hero-search-outside-padding: 20px !default;
$hero-search-outside-padding-at-md: 100px !default;

.hero-search {
  margin-bottom: 0;

  position: relative;
  // overflow: hidden;

  background-color: $white;

  &__container {
    padding-right: $hero-search-outside-padding;
    padding-left: $hero-search-outside-padding;
    margin-top: 20px;
    margin-bottom: 20px;

    @include make-container-custom;
    @include make-container-max-widths($container-md-max-widths);
  }

  &__kicker {
    display: block;
    color: $green-gray;
    text-transform: uppercase;
    font-weight: bold;

    @include type-scale(-1);
  }

  &__title {
    margin-bottom: 2rem;

    @include type-scale(4);
  }

  // Medium+ VPs
  @include media-breakpoint-up('md') {
    // height: $hero-search-height-at-md;

    &__container {
      padding-right: $hero-search-outside-padding-at-md;
      padding-left: $hero-search-outside-padding-at-md;
    }
  }
}
