.header__nav-list-wrapper--search {
  @include media-breakpoint-up('lg') {
    width: auto;
    position: absolute;
    top: 71px;
    left: 1rem;
    z-index: 201;
    -webkit-box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.2);
    box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.2);
  }
  @include media-breakpoint-up('xxl') {
    top: 74px;
  }
}

.header-search {
  width: 600px;
  background-color: #fff;
  padding: 26px;

  .form-group:last-child {
    margin-bottom: 0;
  }
}
