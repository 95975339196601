/**
 * SVG Icon
 */

.symbol {
	width: 1em;
	height: 1em;
}

.symbol, .symbol use {
	fill: currentColor;
}
