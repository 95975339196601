@import 'shared/link';

$article-container-spacing: 60px !default;
$article-spacing: 20px !default;

$article-item-spacing: 3rem !default;
$article-item-spacing-sm: 1rem !default;

$article-hero-shift-at-md: 60px !default;
$article-hero-shift-at-xl: 110px !default;

$article-header-width: 800px !default;
$article-press-header-width: 900px !default;
$article-body-width: 600px !default;
$article-body-width-wider: 800px !default;

$article-body-spacing-at-lg: 160px !default;
$article-body-width-at-lg: ($article-body-width + 2 * $article-body-spacing-at-lg) !default;
$article-body-width-wider-at-lg: ($article-body-width-wider + 2 * $article-body-spacing-at-lg) !default;
$article-outset-max-at-lg: 80px !default;
$article-outset-gutter-at-lg: 25px !default;
$article-outset-fig-at-lg: ($article-body-spacing-at-lg + $article-outset-max-at-lg - $article-outset-gutter-at-lg) !default;
$article-outset-cap-at-lg: ($article-body-spacing-at-lg - $article-outset-gutter-at-lg) !default;
$article-outset-gallery-at-lg: ($article-body-spacing-at-lg + $article-outset-max-at-lg) !default;
$article-outset-carousel-at-lg: ($article-body-spacing-at-lg + $article-outset-max-at-lg + 70) !default;

$article-body-spacing-at-xl: 180px !default;
$article-body-width-at-xl: ($article-body-width + 2 * $article-body-spacing-at-xl) !default;
$article-body-width-wider-at-xl: ($article-body-width-wider + 2 * $article-body-spacing-at-xl) !default;
$article-outset-max-at-xl: 140px !default;
$article-outset-gutter-at-xl: 40px !default;
$article-outset-fig-at-xl: ($article-body-spacing-at-xl + $article-outset-max-at-xl - $article-outset-gutter-at-xl) !default;
$article-outset-cap-at-xl: ($article-body-spacing-at-xl - $article-outset-gutter-at-xl) !default;
$article-outset-gallery-at-xl: ($article-body-spacing-at-xl + $article-outset-max-at-xl) !default;
$article-outset-carousel-at-xl: ($article-body-spacing-at-xl + $article-outset-max-at-xl + 70) !default;

.article {
  overflow: hidden;
  margin-bottom: $grid-gutter-width;

  &--sticky {
    overflow: visible;
  }

  &__container {
    margin-top: $article-container-spacing;
  }

  &__hero + &__container {
    margin-top: 0;
  }

  &__hero {
    margin-bottom: 0;
  }

  &__links {
    padding: 5px 0;
    text-align: right;

    a {
      @include link;
    }
  }

  &__frame,
  &__frameless {
    @include break-word; // Break words which break the layout
    position: relative;
    padding: $article-spacing;
    background-color: $white;
  }

  &__frameless {
    background-color: transparent;
    padding-bottom: 0;
  }

  &__frame--events-header {
    padding: 0;
    display: flex;
    flex-direction: column;
  }

  &__header {
    margin-bottom: $article-item-spacing;
    text-align: center;
  }

  &__header--events {
    margin-bottom: 0;
    padding: $article-spacing;
    text-align: center;
  }

  &__kicker,
  &__meta {
    @include type-scale(-1);
    display: block;
    color: $green-gray;
    text-transform: uppercase;
    font-weight: bold;
  }

  &__kicker {
    display: block;
    margin-top: $article-item-spacing-sm;
    margin-bottom: $article-item-spacing-sm;
  }

  &__title {
    @include type-scale(4);
    margin-bottom: $article-item-spacing-sm;
    &--no-kicker {
      margin-top: $article-item-spacing-sm;
    }

    &--alert {
      color: $red;
    }
  }

  &__title-smaller {
    @include type-scale(3);
    margin-bottom: $article-item-spacing-sm * 1.5;
    &--no-kicker {
      margin-top: $article-item-spacing-sm;
    }
  }

  &__lead,
  &__lead p {
    margin-bottom: 1rem;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__meta {
    margin-top: $article-item-spacing;
  }

  &__tab {
    .js & {
      height: 0;
      overflow: hidden;
    }

    .js &--active {
      height: auto;
      overflow: visible;
    }
  }

  /**
   * Layout
   */
  &__links,
  &__container {
    @include make-container-custom;
    @include make-container-max-widths($container-md-max-widths);
  }

  &__header,
  &__body {
    @include make-container-custom;
  }

  &__header {
    max-width: $article-header-width;
  }

  &__header--press {
    max-width: $article-press-header-width;
  }

  &__body {
    max-width: $article-body-width;
  }

  &__body-wider {
    max-width: $article-body-width-wider;
  }

  // Small+ VPs
  @include media-breakpoint-down('xs') {
    .ce-gallery,
    .ce-carousel,
    .ce-walkthrough,
    .ce-neubauinfo,
    .ce-groundplan {
      margin-left: -($article-spacing);
      margin-right: -($article-spacing);

      .gallery__info {
        padding-left: $article-spacing;
        padding-right: $article-spacing;
      }
    }
  }

  // Medium+ VPs
  @include media-breakpoint-up('md') {
    margin-top: 0;

    &__links {
      padding: 5px 25px;
    }

    &__header-functions {
      display: block;
    }

    // Add gutter to container
    &__container {
      @include make-container-gutter(map-get($container-gutters, 'md'));
      margin-top: $frame-size * 4;
      margin-bottom: $frame-size;
      &:not(:last-child) {
        margin-bottom: $frame-size * 4;
      }
    }

    &__hero + &__container {
      margin-top: -($article-hero-shift-at-md);
      position: relative;
      z-index: 100;
    }

    // Add a frame around the content
    &__frame {
      box-shadow: 0 0 0 $frame-size $frame-color;
      z-index: 100;
    }

    &__frame--events-header {
      flex-direction: row;
    }
  }

  @include media-breakpoint-up('md') {
    .ce-carousel {
      margin-left: -50px;
      margin-right: -50px;
    }
  }

  // Large+ VPs
  @include media-breakpoint-up('lg') {
    // Add spacing to the body for outset positioning
    &__body {
      position: relative;
      max-width: $article-body-width-at-lg;
      padding-left: $article-body-spacing-at-lg;
      padding-right: $article-body-spacing-at-lg;
    }

    &__body-wider {
      position: relative;
      max-width: $article-body-width-wider-at-lg;
      padding-left: $article-body-spacing-at-lg / 1.5;
      padding-right: $article-body-spacing-at-lg / 1.5;
    }

    .ce-wide {
      margin-left: -$article-body-spacing-at-lg;
      margin-right: -$article-body-spacing-at-lg;
    }

    // Position cites a the left
    .ce-quote__cite {
      position: absolute;
      left: 0;
      width: $article-body-spacing-at-lg;
      margin-top: .2rem; // Align to quote
      padding-right: $article-outset-gutter-at-lg;
      text-align: right;
    }

    // Bleed figures over the container
    .ce-figure--left {
      position: absolute;
      left: -($article-outset-max-at-lg);
      width: $article-outset-fig-at-lg;
      text-align: right;
      z-index: 200;

      // Captions should be inside the container
      .ce-figure__caption {
        display: inline-block;
        width: $article-outset-cap-at-lg;
      }
    }

    // Bleed figures over the container
    .ce-figure--right {
      position: absolute;
      right: -($article-outset-max-at-lg);
      width: $article-outset-fig-at-lg;
      text-align: left;
      z-index: 200;

      // Captions should be inside the container
      .ce-figure__caption {
        display: inline-block;
        width: $article-outset-cap-at-lg;
      }
    }

    // Bleed gallery and walkthrough over the container on both sides
    .ce-gallery,
    .ce-walkthrough,
    .ce-neubauinfo,
    .ce-groundplan {
      margin-left: -($article-outset-gallery-at-lg);
      margin-right: -($article-body-spacing-at-lg);
      z-index: 200;
      position: relative;

      &--right {
        margin-left: -($article-body-spacing-at-lg);
        margin-right: -($article-outset-gallery-at-lg);
      }
    }

    .ce-carousel {
      margin-left: -($article-outset-carousel-at-lg);
      margin-right: -($article-outset-carousel-at-lg);
    }

    .ce-timeline {
      margin-left: -($article-body-spacing-at-lg);
    }

    .ce-timeline__item {
      margin-bottom: 0.875rem;
      position: relative;
    }

    .ce-timeline__time {
      display: inline-block;
      left: 0;
      padding-right: 2rem;
      position: absolute;
      text-align: right;
      width: $article-body-spacing-at-lg;
    }

    .ce-timeline__desc {
      margin-left: $article-body-spacing-at-lg;
    }

    .ce-sticky {
      height: 100%;
      margin-right: calc((100vw - 100%)/-2 + .75rem);
      padding-left: 40px;
      position: absolute;
      top: 0;
      right: 0;
      width: 260px;
      z-index: 100;

      > * {
        position: sticky;
        top: 0;
        // max-height: 100vh;
        overflow-y: auto;
        padding-top: 20px;
        margin-top: -20px;
      }
    }
  }

  // X-Large VPs
  @include media-breakpoint-up('xl') {
    // Move container up
    &__hero + &__container {
      margin-top: -($article-hero-shift-at-xl);
    }

    // Increase body spacing
    &__body {
      max-width: $article-body-width-at-xl;
      padding-left: $article-body-spacing-at-xl;
      padding-right: $article-body-spacing-at-xl;
    }

    &__body-wider {
      max-width: $article-body-width-wider-at-xl;
      padding-left: $article-body-spacing-at-xl / 1.5;
      padding-right: $article-body-spacing-at-xl / 1.5;
    }

    .ce-wide {
      margin-left: -$article-body-spacing-at-xl;
      margin-right: -$article-body-spacing-at-xl;
    }

    // Sync element positions to changed body spacing
    .ce-quote__cite {
      width: $article-body-spacing-at-xl;
      padding-right: $article-outset-gutter-at-xl;
    }

    .ce-figure--left {
      left: -($article-outset-max-at-xl);
      width: $article-outset-fig-at-xl;

      .ce-figure__caption {
        width: $article-outset-cap-at-xl;
      }
    }

    .ce-figure--right {
      right: -($article-outset-max-at-xl);
      width: $article-outset-fig-at-xl;

      .ce-figure__caption {
        width: $article-outset-cap-at-xl;
      }
    }

    .ce-gallery,
    .ce-walkthrough,
    .ce-neubauinfo,
    .ce-groundplan {
      margin-left: -($article-outset-gallery-at-xl);
      margin-right: -($article-body-spacing-at-xl);

      &--right {
        margin-left: -($article-body-spacing-at-xl);
        margin-right: -($article-outset-gallery-at-xl);
      }
    }

    .ce-carousel {
      margin-left: -($article-outset-carousel-at-xl);
      margin-right: -($article-outset-carousel-at-xl);
    }

    .ce-timeline {
      margin-left: -($article-body-spacing-at-xl);
    }

    .ce-timeline__time {
      text-align: right;
      width: $article-body-spacing-at-xl;
    }

    .ce-timeline__desc {
      margin-left: $article-body-spacing-at-xl;
    }

    .ce-sticky {
      margin-right: -140px;
      width: 320px;
    }
  }
}

.article-double {

  &__container {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
  }

  &__framecontainer {
    padding: 0;
    &:first-child {
      margin-left: 0;
    }
    &:last-child {
      margin-top: 40px;
    }
  }

  &__frame {
    padding-bottom: $article-spacing / 4;
  }

  &__frameless {
    padding-bottom: 0;
  }

  ul {
    list-style: none;
    padding-left: 0.2rem;
  }

  @include media-breakpoint-up('md') {
    &__container {
      margin-top: 40px;
    }

    &__framecontainer {
      &:last-child {
        margin-top: 80px;
      }
    }
  }

  @include media-breakpoint-up('lg') {
    &__container {
      flex-direction: row;
    }

    &__framecontainer {
      width: 50%;
      &:first-child {
        margin-left: 0;
      }
      &:last-child {
        margin-top: 0;
        margin-left: 80px;
      }
    }

    &__frame,
    &__frameless {
      height: 100%;
      padding: ($article-spacing * 2) ($article-spacing * 3);
      padding-bottom: $article-spacing;
    }

    &__frameless {
      padding-bottom: 0;
    }
  }

}

.article-job {
  margin-bottom: 0;
}
