.teaser--stacked {
  @media (min-width: 440px) {
    .teaser__frame {
      position: relative;
      margin-top: -160px;
      margin-left: $frame-size;
      margin-right: $frame-size;
    }
    .teaser__frame--press,
    .teaser__frame--unshift {
      margin-top: -50px;
    }
  }

  @include media-breakpoint-up('xl') {
    .teaser__frame {
      margin-left: ($grid-gutter-width + $frame-size);
      margin-right: ($grid-gutter-width + $frame-size);
    }
  }
}
