/**
 * Huge image which spans across the whole viewport
 */

@mixin hero-image-cover-vertical() {
  height: 100%;
  min-height: 0;
  width: auto;
  min-width: 100%;
}

@mixin hero-image-cover-horizontal() {
  height: auto;
  min-height: 100%;
  width: 100%;
  min-width: 0;
}

$hero-image-ratio: (16/9) !default;

$hero-image-height: 250px !default;
$hero-image-ratio-bp: ceil($hero-image-ratio * $hero-image-height);
$hero-image-height-at-md: 380px !default;
$hero-image-ratio-bp-at-md: ceil($hero-image-ratio * $hero-image-height-at-md);
$hero-image-height-at-xl: 580px !default;
$hero-image-ratio-bp-at-xl: ceil($hero-image-ratio * $hero-image-height-at-xl);

$hero-image-tall-height: 380px !default;
$hero-image-tall-ratio-bp: ceil($hero-image-ratio * $hero-image-tall-height);
$hero-image-tall-height-at-lg: 720px !default;
$hero-image-tall-ratio-bp-at-lg: ceil($hero-image-ratio * $hero-image-tall-height-at-lg);
$hero-image-tall-height-at-xl: 880px !default;
$hero-image-tall-ratio-bp-at-xl: ceil($hero-image-ratio * $hero-image-tall-height-at-xl);

$hero-image-newsletter-height: 310px !default;
$hero-image-newsletter-ratio-bp: ceil($hero-image-ratio * $hero-image-newsletter-height);
$hero-image-newsletter-height-at-lg: 310px !default;
$hero-image-newsletter-ratio-bp-at-lg: ceil($hero-image-ratio * $hero-image-newsletter-height-at-lg);
$hero-image-newsletter-height-at-xl: 310px !default;
$hero-image-newsletter-ratio-bp-at-xl: ceil($hero-image-ratio * $hero-image-newsletter-height-at-xl);

.hero-image {
  position: relative;
  overflow: hidden;
  height: $hero-image-height;
  margin-bottom: 0;

  noscript {
    display: block;
    height: 100%;
  }

  img {
    display: block;
    position: relative;
    left: 50%;
    top: 50%;
    max-width: none;
    transform: translate(-50%, -50%);

    @include hero-image-cover-vertical;
    @media (min-width: $hero-image-ratio-bp) {
      @include hero-image-cover-horizontal;
    }
  }

  @include media-breakpoint-up('md') {
    height: $hero-image-height-at-md;

    img {
      @include hero-image-cover-vertical;
      @media (min-width: $hero-image-ratio-bp-at-md) {
        @include hero-image-cover-horizontal;
      }
    }
  }

  // Scale-up at larger VPs
  @include media-breakpoint-up('xl') {
    height: $hero-image-height-at-xl;

    img {
      @include hero-image-cover-vertical;
      @media (min-width: $hero-image-ratio-bp-at-xl) {
        @include hero-image-cover-horizontal;
      }
    }
  }

  /**
   * Tall variant
   */
  // Start at base size
  &--tall {
    height: $hero-image-tall-height;
  }
  &--tall img {
    @include hero-image-cover-vertical;
    @media (min-width: $hero-image-tall-ratio-bp) {
      @include hero-image-cover-horizontal;
    }
  }

  /**
   * Newsletter variant
   */
  // Start at base size
  &--newsletter {
    height: $hero-image-newsletter-height;
  }
  &--newsletter img {
    @include hero-image-cover-vertical;
    @media (min-width: $hero-image-newsletter-ratio-bp) {
      @include hero-image-cover-horizontal;
    }
  }

  // Scale up at larger VPs
  @include media-breakpoint-up('lg') {
    &--tall {
      height: $hero-image-tall-height-at-lg;
    }
    &--tall img {
      @include hero-image-cover-vertical;
      @media (min-width: $hero-image-tall-ratio-bp-at-lg) {
        @include hero-image-cover-horizontal;
      }
    }
    &--newsletter {
      height: $hero-image-newsletter-height-at-lg;
    }
    &--newsletter img {
      @include hero-image-cover-vertical;
      @media (min-width: $hero-image-newsletter-ratio-bp-at-lg) {
        @include hero-image-cover-horizontal;
      }
    }
  }
  @include media-breakpoint-up('xl') {
    &--tall {
      height: $hero-image-tall-height-at-xl;
    }
    &--tall img {
      @include hero-image-cover-vertical;
      @media (min-width: $hero-image-tall-ratio-bp-at-xl) {
        @include hero-image-cover-horizontal;
      }
    }
    &--newsletter {
      height: $hero-image-newsletter-height-at-xl;
    }
    &--newsletter img {
      @include hero-image-cover-vertical;
      @media (min-width: $hero-image-newsletter-ratio-bp-at-xl) {
        @include hero-image-cover-horizontal;
      }
    }
  }


  /**
   * Alignment variants
   */
  // Fixate image to left edge
  &--left img {
    left: 0;
    transform: translateY(-50%);
  }

  // Fixate image to right edge
  &--right img {
    left: 100%;
    transform: translate(-100%, -50%);
  }

  .image__copyright {
    bottom: auto;
    top: 0;

    @include media-breakpoint-down('md') {
      max-width: 35%;
    }
  }
}
