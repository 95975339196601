.social-link {
  @include type-scale(-1);
  display: inline-block;
  padding: .5rem 0;
  line-height: 1;
  font-weight: bold;
  text-transform: uppercase;

  &__icon,
  &__text {
    display: inline-block;
    vertical-align: middle;
  }

  &__icon {
    margin-right: .5rem;
    margin-top: -.1rem;
    font-size: 1.1rem;
  }

  @include media-breakpoint-down('sm') {
    &__text {
      @include sr-only;
    }
  }
}
