.slider {
  position: relative;

  &__prev,
  &__next {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }

  &__prev {
    left: 0;
  }

  &__next {
    right: 0;
  }

  .slider__dots {
    margin-top: $grid-gutter-width;
  }

  @include media-breakpoint-down('sm') {
    .slider__controls {
      display: none;
    }
  }
}

/**
 * No-JS
 */
.no-js .slider__controls,
.no-js .slider__dots {
  display: none;
}
