/**
 * Bootstrap options
 */
$enable-rounded: false !default;
$enable-grid-classes: false !default;
$enable-print-styles: false !default;

/**
 * Breakpoints
 */
$grid-breakpoints: (
  'xs': 0,
  'sm': 540px,
  'md': 720px,
  'lg': 1025px,
  'xlg': 1140px,
  'xl': 1240px,
  'poll-lg': 1300px,
  'xxl': 1480px,
) !default;

/**
 * Grid settings
 */
$grid-columns: 12 !default;
$grid-gutter-width: 20px !default;

/**
 * Container sizes
 */
$container-max-widths: (
  'xs': 1000px,
  'xl': 1340px,
) !default;
$container-md-max-widths: (
  'xs': 1000px,
  'xl': 1140px,
) !default;
$container-sm-max-widths: (
  'xs': 1040px,
) !default;
$container-gutters: (
  'xs': 20px,
  'md': 50px,
) !default;

/**
 * Teaser Container sizes
 */
$teaser-container-max-widths: (
  'sm': 700px,
  'lg': 1000px,
  'xl': 1340px,
) !default;
$teaser-container-gutters: (
  'sm': 20px,
  'md': 50px,
) !default;

/**
 * Gallery Container sizes
 */
$gallery-container-md-max-widths: (
  'md': 600px,
  'lg': 950px,
  'xl': 1100px,
) !default;

/**
 * Typography
 */
$font-family-base: 'Myriad W01', 'Trebuchet MS', sans-serif !default;
$font-family-special: 'FF Speak W03', 'Trebuchet MS', sans-serif !default;

$type-scales: (
  -2:   0.8125rem,                // 13px
  -1:   0.8750rem,                // 14px
   0:   1.0000rem,                // 16px
   1:   1.125rem,                 // 18px
   2: ( 1.500rem, 'md' 2.000rem), // 24px 32px
   3: ( 1.875rem, 'md' 2.500rem), // 30px 40px
   4: ( 1.500rem, 'md' 2.500rem, 'lg' 3.750rem), // 40px 60px
);

$line-height-base: 1.4 !default;
$headings-line-height: 1.1 !default;
$headings-font-weight: normal !default;

/**
 * Colors
 *
 * Try colorhexa.com for name inspiration.
 */
$black: #000;
$gray-dark: #323232;
$gray: #ccc;
$gray-light: #e0e0e0;
$gray-lighter: #efeff0;
$gray-lightest: #f7f7f7;
$gray-disabled: #a9a9a9;
$white: #fff;
$blue-dark: #004c93;
$blue-strong: #277CB4; // #287db7; Changed because of contrast
$blue: #009fe3;
$blue-soft: #83d0f5;
$green-gray: #657676; // #849997; Changed because of contrast
$green: #008539; // #009640; Changed because of contrast
$green-lime: #9ac331;
$yellow: #ffe500;
$orange: #f7a70a;
$red: #d1422f;
$pink: #e46090;
$magenta: #963683;
$mercury: #e5e5e5;
$dove: #666666; // #707070; Changed because of contrast

$brand-primary: $blue-dark;
$brand-success: $green;
$brand-danger: $red;

$body-color: $gray-dark !default;
$body-bg: $gray-lighter;

/**
 * Links
 */
$link-color: $brand-primary !default;
$link-decoration: none !default;
$link-hover-color: darken($link-color, 15%) !default;
$link-hover-decoration: none !default;

/**
 * Box frame
 */
$frame-size: 20px !default;
$frame-color: rgba(#ccc, .25) !default;
