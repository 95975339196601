.wall {
  width: 100%;
  min-height: 100%;
  margin: auto;
  padding: 1rem 2rem;
  color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  background-color: $blue-dark;
  z-index: 101;

  @include type-scale(-1);

  position: absolute;
  top: 0;
  left: 0;
  right: 0;

  .no-js & {
    display: none;
  }

  .no-js &--js-message {
    display: block;
  }

  h3 {
    font-size: 1rem;
  }
}

@include media-breakpoint-up('sm') {
  .wall {
    .no-js &--js-message {
      height: 98%;
      width: 98%;
    }

    h3 {
      font-size: 1.75rem;
    }
  }
}

@include media-breakpoint-up('sm') {
  .wall {
    padding-top: 2rem;
    z-index: 1;
  }
}

@include media-breakpoint-up('md') {
  .wall {
    @include type-scale(0);
  }
}

@include media-breakpoint-up('lg') {
  .wall {
    padding: 2rem;
  }
}

.wall--static {
  aspect-ratio: 3/2;
  position: static;
  border: 1px solid $blue-dark;
  height: auto;
}

.wall__content {
  max-width: 520px;
}

.wall__icon {
  @include type-scale(3);
  line-height: 1;
  margin-bottom: 1rem;
}

.wall__text {
  margin-bottom: 2rem;
}

.wall .link,
.wall .basic-link {
  color: #fff;
}
