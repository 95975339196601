.article-tabnav {
    @include list-unstyled;

    margin: 1rem 0;
    text-align: center;

    display: flex;
    flex-direction: row;
    justify-content: center;

    &--left {
      justify-content: flex-start;
    }

    &__item {
      // padding: 10px 0;
      margin: 0 10px;
      line-height: 2.5;
      display: inline;
      font-family: $font-family-special;

      a, span {
        @include type-scale(1);
        padding: 15px 10px;
      }

      a {
        color: $black;
      }

      &--active,
      &:hover {
        border-bottom: 4px solid $green;
      }

      &--inactive {
        color: $gray;
      }

      &--inactive:hover {
        border-bottom: 0;
      }
    }

  // Medium+ VPs
  @include media-breakpoint-up('sm') {
    &__item {
      margin: 0 10px;
      a, span {
        padding: 15px 20px;
      }
    }
  }

  // Medium+ VPs
  @include media-breakpoint-up('md') {
    &__item {
      margin: 0 10px;
      a, span {
        padding: 15px 40px;
      }
    }
  }

  // Large+ VPs
  @include media-breakpoint-up('lg') {
    &__item a, &__item span {
      padding: 15px 80px;
    }
  }

}
