.form-toggle {
  display: flex;
  align-items: center;
  margin-top: ($spacer / 4);
  cursor: pointer;

  &--inline {
    display: inline-flex;
    margin-top: 0;
    margin-bottom: 0;
  }

  &--hidden {
    display: none;
  }

  // Hide default input
  &__input {
    @include sr-only;
  }

  // Icon
  &__icon {
    display: inline-block;
    vertical-align: middle;
    width: 1rem;
    height: 1rem;
    min-width: 1rem;
    margin-right: ($spacer / 3);
    border: 1px solid $gray;
    background: 50% 50% no-repeat #fff;
  }

  .has-danger &__icon {
    color: $brand-danger;
    border-color: $brand-danger;
  }

  .has-success &__icon {
    color: $brand-success;
    border-color: $brand-success;
  }

  // Icon at the right
  &__label ~ &__icon {
    margin-right: 0;
    margin-left: ($spacer / 3);
  }

  // Label
  &__label {
    text-transform: none;
    font-weight: normal;
    color: $black;
  }

  &__label--strong {
    font-weight: bold;
    color: $green-gray;
  }

  &__label--light {
    text-transform: none;
    font-weight: normal;
  }

  :checked ~ &__label {
    color: $brand-primary;
  }

  &--disabled &__label {
    opacity: 0.5;
  }

  // Checkbox
  &--check &__icon {
    background-size: 90%;
  }

  &--check :checked ~ &__icon {
    background-image: svg-url('<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 11.1 9"><polygon fill="' + $brand-primary + '" points="9.5 0 3.4 6.1 1.5 4.1 0 5.6 3.4 9 11 1.4 9.5 0"/></svg>');
  }

  &--check .form-toggle__input--partial ~ &__icon {
    background-image: svg-url('<svg xmlns="http://www.w3.org/2000/svg" width="200" height="200" viewBox="0 0 200 200"><polygon fill="' + $brand-primary + '" points="190 0 190 30 0 30 0 0" transform="translate(4.677 85.167)" /></svg>');
  }

  &--check.form-toggle--disabled &__icon {
    opacity: 0.7;
  }

  // Radio
  &--radio &__icon {
    padding: .2em;
    border-radius: 50%;
    background-clip: content-box;
  }

  &--radio :checked ~ &__icon {
    background-color: $brand-primary;
  }

  &--map,
  &--map-hover {
    transition: all 0.2s;
  }

  &--map:hover,
  &--map-hover {
    .form-toggle__label {
      color: $blue-dark;
      font-weight: bold;
    }
    .form-toggle__icon {
      border-color: $blue;
    }
  }

  // &--map-hover {
  //   &:after {
  //     content: '';
  //     width: 15px;
  //     height: 10px;
  //     background-image: svg-url('<svg xmlns="http://www.w3.org/2000/svg" viewBox="2 0 6 10"><path d="M8,1.5L4.8,5,8,8.5,6.6,10,2,5,6.6,0Z" fill="#004c93" /></svg>');
  //     background-repeat: no-repeat;
  //     background-position: center right;
  //   }
  // }
}

/**
 * Focus replacement
 */
// Outline icon when (invisible) input is focussed
.form-toggle__input:focus ~ .form-toggle__icon {
  @include focus-border();
}
// Remove focus styles only if a mouse pointer was detected
[data-whatinput="mouse"],
[data-whatinput="touch"] {
  .form-toggle__input:focus ~ .form-toggle__icon {
    outline: 0;
  }
}

/**
 * Validation
 */
.has-danger .toggle__label {
  color: $brand-danger;
}
.has-success .toggle__label {
  color: $brand-success;
}

/**
 * Containers
 */
// Toggle on top of each other
.form-toggle-stack {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  .form-toggle {
    margin-bottom: ($spacer / 4);
  }
}

// Toggles aligned side by side
.form-toggle-row {
  display: flex;
  flex-wrap: wrap;
  align-items: center;

  .form-toggle {
    margin-right: $spacer;
    margin-bottom: ($spacer / 4);

    &:last-child {
      margin-right: 0;
    }
  }

  &--33 {
    .form-toggle {
      flex-basis: 50%;
      margin-right: 0;
    }
    .form-toggle--100 {
      flex-basis: 100%;
    }
  }

  @include media-breakpoint-up('md') {
    &--33 {
      .form-toggle {
        flex-basis: 33%;
      }
    }
  }
}
