/**
 * Document
 */
body {
  font-family: $font-family-base;
}

.print-logo {
  text-align: center;
  width: 100%;
  display: none;
  img {
    margin-left: auto;
    margin-right: auto;
  }
}

/**
 * Headings
 */
h1 {
  font-family: $font-family-special;
}

/**
 * Reset all buttons by default since we're most likely
 * will be styling them on our own.
 */
$button-transition-duration: .1s !default;

button {
  border: 0;
  padding: 0;
  background: 0;
  color: inherit;
  transition:
    color $button-transition-duration,
    background-color $button-transition-duration,
    border-color $button-transition-duration,
    opacity $button-transition-duration;
}

/**
 * Smooth link state updates by default
 */
$link-transition-duration: .1s !default;

a {
  transition:
    color $link-transition-duration,
    background-color $link-transition-duration,
    border-color $link-transition-duration,
    opacity $link-transition-duration;
}

p > a {
  text-decoration: underline;
}

p > a:hover,
p > a:focus {
  text-decoration: underline;
}

.display-none {
  display: none;
}
