.file-multiple {

  &__btn-rem {
    display: none;
    position: absolute;
    top: 0; right: 20%;

    color: $gray;

    .btn__text {
      display: none;
    }

    &:hover {
      color: $green-gray;
    }

  }

  .form-group:last-child():hover &__btn-rem {
    display: inline;
  }

  #template &__btn-rem {
    display: none;
  }

}
