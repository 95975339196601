.teaser-grid {
  @include make-container-custom;
  @include make-container-gutters($teaser-container-gutters);
  max-width: 440px;
  margin-bottom: ($grid-gutter-width * 2);

  &__header {
    max-width: calc(100vw - #{$grid-gutter-width * 2});
    margin-right: auto;
    margin-bottom: $grid-gutter-width;
    margin-left: auto;
  }

  &__title {
    @include type-scale(3);
    margin-bottom: 0;
  }

  @include media-breakpoint-down('sm') {
    &__item {
      &:not(:first-child) {
        padding-top: (2 * $grid-gutter-width);
      }
    }
  }

  @include media-breakpoint-up('md') {
    &__header {
      margin-bottom: ($grid-gutter-width * 2);
    }

    &__row {
      @include make-row;
    }

    .teaser__title {
      font-size: 1.875rem;
    }
  }

  @include media-breakpoint-only('md') {
    max-width: 920px;
    &__item {
      @include make-col(6);

      &:nth-child(n+3) {
        padding-top: (2 * $grid-gutter-width);
      }

      &:nth-child(2n+1) {
        clear: left;
      }

      &--career-2 {
        width: 100%;
      }
    }
  }

  @include media-breakpoint-up('lg') {
    max-width: 1340px;
    &__item {
      @include make-col(4);

      &:nth-child(n+4) {
        padding-top: (2 * $grid-gutter-width);
      }

      &:nth-child(3n+1) {
        clear: left;
      }

      &--career-1 {
        width: 38.5%;
      }

      &--career-2 {
        width: 23%;
      }
    }
  }
}
