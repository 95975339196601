$form-switch-price-width: 160px !default;
$form-switch-price-size: 24px !default;

$form-switch-price-speed: 0.05s !default;
$form-switch-price-border-width: 1px !default;

$form-switch-price-color-off: $gray;
$form-switch-price-color-on: $green-gray;
$form-switch-price-color-border: $gray;
$form-switch-price-color-background: $white;

.form-switch-price {
  width: $form-switch-price-width;
  position: relative;
  float: right;

  &--wide,
  &--uid-search-price-business {
    width: 260px;
  }

  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;

  &__label {
    height: $form-switch-price-size;
    margin: 0;
    padding: 0;

    display: block;
    overflow: hidden;
    cursor: pointer;

    height: 24px;

    line-height: $form-switch-price-size;
    border-radius: 5px;
    border: $form-switch-price-border-width solid $form-switch-price-color-border;
    background-color: transparent;

    transition: background-color $form-switch-price-speed ease-in;

    .off,
    .on {
      position: absolute;
      top: 0;
      width: 50%;
      text-align: center;
    }

    .off {
      left: 0;
      color: $white;
    }

    .on {
      left: 50%;
      color: $red;
    }

    &:before {
      width: $form-switch-price-size;
      margin: 0;

      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;

      width: 50%;

      content: '';
      display: block;

      border: $form-switch-price-border-width solid $blue-strong;
      border-radius: 5px;
      background-color: $blue-strong;
      transition: all $form-switch-price-speed ease-in 0s;
    }
  }

  &__input {
    display: none;

    &:checked + .form-switch-price__label {
      .off {
        color: $blue-strong;
      }

      .on {
        color: $white;
      }

      &:before {
        width: 50%;
        background-color: $red;
        border-color: $red;
        left: 50%;
      }
    }

    &:checked + .form-switch-price__label:before {
      right: 0;
    }
  }
}
