@mixin focus-border() {
    outline: 3px solid $gray-dark !important;
    outline-offset: 1px !important;
}

button:-moz-focusring,
[type='button']:-moz-focusring,
[type='reset']:-moz-focusring,
[type='submit']:-moz-focusring {
  @include focus-border();
}

:focus {
  @include focus-border();
}

:focus:not(:focus-visible) {
  outline: 0;
}
