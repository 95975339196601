@import 'shared/link';

/**
 * Tile
 */
.tile {
  background-color: $green-gray;
  color: $white;

  &__header,
  &__pane {
    padding-right: $grid-gutter-width;
    padding-left: $grid-gutter-width;
  }

  &__header {
    padding-top: $grid-gutter-width * 2;
    padding-bottom: $grid-gutter-width;
  }

  &__pane {
    padding-bottom: $grid-gutter-width * 2;
  }

  &__kicker {
    @include type-scale(-1);
    margin-bottom: ($spacer/2);
    display: block;
    text-transform: uppercase;
    font-weight: bold;
  }

  &__title {
    margin-bottom: 0;
    font-size: 2rem;
  }

  &__body {
    @include type-scale(-1);

    a:not(.tile__block-link) {
      @include link;
    }

    p {
      &:last-child {
        margin: 0;
      }
    }

    &--left {
      text-align: left;
    }
  }

  &__logos {
    padding-top: $grid-gutter-width;
    padding-bottom: $grid-gutter-width * 2;

    display: flex;
    justify-content: flex-start;
    align-items: flex-start;

    img {
      max-width: 40%;
    }
  }

  &__block-link {
    display: block;
  }

  &__icon {
    margin-top: -.15em;
    margin-right: .8em;

    width: 1.5em;
    height: 1.5em;

    display: inline-block;
    vertical-align: middle;

    &--telephone {
      margin-bottom: -.1em;
      margin-right: 1em;
    }
  }

  &__phone-number {
    font-size: 1.3rem;
    letter-spacing: 0.15rem;
  }

  &__list {
    @include list-unstyled;

    padding: 0 0 0 25px;

    font-weight: bold;
    text-transform: uppercase;

    &-item {
      margin: 10px 0;

      &:before {
        height: 0;
        width: 0;

        position: relative;
        left: -1.5em;
        top: 1em;

        border-color: transparent white;
        border-style: solid;
        border-width: 0.35em 0 0.35em 0.45em;

        display: block;
        content: '';
      }
    }
  }

  &__intro {
    font-weight: bold;
    text-transform: uppercase;
  }

  &__notice {
    margin: 30px 0 0 0;

    text-align: left;
    text-transform: uppercase;
    font-weight: bold;

    &-text {
      margin: 0;
      font-size: 0.85rem;
    }
  }

  // Bare link colors
  a:not([class]) {
    color: $white;

    &:hover {
      color: rgba($white, .8);
    }
  }

  // Color variants
  &--blue-dark {
    background-color: $blue-dark;

    & :focus {
        outline-color: $white !important;
    }
  }

  &--blue {
    background-color: $blue-strong;
  }

  // Forms
  .form-toggle {
    margin: 0.4rem 0;

    &__label {
      color: $white;
    }

    &__icon {
      background-color: transparent;
    }

    &--radio :checked ~ .form-toggle__icon {
      background-color: $white;
    }
  }

  .form-input[type='submit'] {
    margin: 10px 0 0 20px;
  }

  // Layout
  @include media-breakpoint-down('sm') {
    &__kicker {
      display: none;
    }

    &__header {
      display: flex;
      align-items: center;
      cursor: pointer;
    }

    &__header-text {
      flex: 1;
    }

    &__toggle {
      background-color: red;
    }

    &:not(.is-expanded) &__pane {
      @include sr-only;
    }

    &:not(.is-expanded) &__expanded {
      display: none;
    }

    &.is-expanded &__collapsed {
      display: none;
    }
  }

  @include media-breakpoint-up('md') {
    display: flex;
    flex-direction: column;
    min-height: 240px;
    text-align: center;

    &__expanded,
    &__collapsed {
      display: none;
    }

    &__pane {
      margin-top: auto;
    }

    &__toggle {
      display: none;
    }
  }
}
