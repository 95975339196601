.teaser-carousel {
  &--event {
    .teaser-carousel__stage {
      margin-right: 0;
      margin-left: 0;
    }

    .teaser-carousel__item {
      padding: 0;
    }

    .teaser--vertical {
      .teaser__frame {
        left: 20px;
        right: 20px;
      }
    }

  }

  &__item--date {
    .teaser {
      width: 100%;
      padding-bottom: 150%;
      position: relative;

      .event-date__container {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
      }
    }
  }
}
