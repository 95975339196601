.hero-slider {
  .handle {
    width: 2px;

    background-color: $white;

    &:after {
      border: 0;
      border-radius: 0;

      color: $black;
      background-color: $white;

      box-shadow: 0 0 0 $frame-size $frame-color;
    }
  }
}
