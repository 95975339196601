$form-multiselect-padding-height: 20px !default;
$form-multiselect-padding-width: 30px !default;
$form-multiselect-extra-padding: .2rem !default;

.form-multiselect {
  position: relative;

  &__header {
    @include clearfix;
    position: relative;
  }

  &__title {
    float: left;
  }

  &__extra {
    padding-top: $form-multiselect-extra-padding;
    float: right;
  }

  &__abo-duration {
    min-width: 130px;
  }

  &__container {
    @include clearfix;

    padding: $form-multiselect-padding-height $form-multiselect-padding-width;

    background-color: $form-select-bg-color;
    border: $form-select-border-width solid $form-select-border-color;

    display: none;

    &--anchor-right {
      right: 0;
    }

    &--area {
      min-width: 140px;
    }
  }

  &__toggle {
    width: 100%;
    text-align: left;
  }

  &__toggle--default {
    color: $gray-disabled;
  }

  &__apply {
    width: 100%;
  }

  &__divider {
    border-top: 1px solid $gray;
    margin: 10px -10px 0 -10px;
  }

  &__custom {
    padding-top: 10px;
  }

  &__custom-title {
    padding-bottom: 0;
  }

  &__custom-row-apply {
    display: none;
  }

  &.is-expanded &__toggle {
    border-color: $form-select-focus-border-color;
  }

  &.is-expanded &__container {
    display: block;
  }

  @include media-breakpoint-up('md') {
    &__container {
      position: absolute;
      z-index: 300;
      width: 100%;
      min-width: 270px;

      &--duration {
        min-width: 180px;
      }

      &--area {
        min-width: 140px;
      }

      &--objektart {
        min-width: 290px;
      }

      &--immo-price {
        min-width: 290px;
      }

      &--immo-price-gesamt {
        min-width: 310px;
      }

      &--features {
        min-width: 390px;
      }

      &--bezirk {
        min-width: 500px;
      }
    }
  }
}
