.accordion {
  border-top: 1px solid rgba($gray-dark, 0.2);
}

.accordion__item {
  border-bottom: 1px solid rgba($gray-dark, 0.2);
}

.accordion__header {
  display: flex;
  align-items: center;
  padding: 0.4rem 0.25rem;
  min-height: 2.75rem;
  cursor: pointer;
}

.accordion__title {
  @include type-scale(0);
  font-weight: bold;
  margin-bottom: 0;
  min-width: 0; // needed to allow 'word-wrap: break-word' for flex items
  word-wrap: break-word;
}

.accordion__badge {
  display: none;
  flex-shrink: 0;
  margin-right: auto;
  padding-left: 0.5rem;
}

.accordion__badge--visible {
  display: block;
}

.accordion__toggle {
  display: flex;
  justify-content: center;
  flex-shrink: 0;
  margin-left: auto;
  padding-left: 0.5rem;
}

.accordion__toggle-symbol {
  font-size: 1rem;
  transition: transform 0.2s;

  .accordion__toggle[aria-expanded="true"] & {
    transform: rotate(180deg);
  }
}

.accordion__content {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.2s;
}

.accordion__content--open {
  max-height: none;
  overflow: visible;
}

.accordion__content-inner {
  padding: 0.25rem 0.25rem 1rem;
}
