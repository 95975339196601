.faq {

  &__item {
    border-bottom: 1px solid $gray;
    padding-bottom: 0.5rem;
    margin-bottom: 1rem;

    &:last-child() {
      border: none;
    }
  }

  &__question {
    position: relative;
    display: block;
    color: $black;
    font-size: 1.25rem;
    line-height: 1.5rem;
    font-weight: bold;
    font-family: $font-family-special;
    margin-bottom: 0.75rem;
    padding-right: 2rem;

    .js &:after {
      position: absolute;
      display: block;
      content: '';
      right: 0;
      top: 0;
      width: 1rem;
      height: 1rem;
      background-repeat: no-repeat;
      background-image: url('../images/arrow-down.svg');
      background-position: center;
      background-size: 1rem;
      margin-top: 0.25rem;
      transition: transform 0.2s;
    }
  }

  .is-expanded &__question {
    .js &:after {
      transform: rotate(180deg);
    }
  }

  &__answer {

    .js & {
      opacity: 1;
      max-height: 0;
      transition: all 0.2s ease-in-out;
      overflow: hidden;
    }
  }

  .is-expanded &__answer {
    opacity: 1;
    max-height: 1600px;
  }
}
