$teaser-hero-max-width-at-lg: (map-get($container-max-widths, 'xs') - 2 * map-get($container-gutters, 'md'));
$teaser-hero-content-max-width-at-xl: 640px;
$teaser-hero-side-max-width-at-xl: map-get($container-max-widths, 'xl');

$teaser-hero-newsletter-max-width-at-md: 100%;
$teaser-hero-newsletter-max-width-at-lg: 820px;
$teaser-hero-newsletter-max-width-at-xl: 820px;

$teaser-hero-main-max-width-at-xl: 450px;

/**
 * Base
 */
[class*="teaser--hero-"] {
  @include media-breakpoint-only('sm') {
    .teaser__container {
      padding-right: (map-get($container-gutters, 'xs') + $frame-size);
      padding-left: (map-get($container-gutters, 'xs') + $frame-size);
      margin-top: -60px;
    }
  }

  @include media-breakpoint-up('md') {
    .teaser__container {
      padding-right: (map-get($container-gutters, 'md') + $frame-size);
      padding-left: (map-get($container-gutters, 'md') + $frame-size);
      margin-top: -60px;
    }
  }

  @include media-breakpoint-up('xs') {
    .teaser__container--newsletter {
      @include make-absolute-container($teaser-hero-newsletter-max-width-at-md);
      top: 0;
      bottom: 0;
      margin-top: 0;
      padding: 0;
      background-image: radial-gradient(circle, lighten(#fff, 15%) 0%, rgba(#fff, 0));
    }
    .teaser__frame--newsletter {
      position: absolute;
      top: 50%;
      right: 0;
      left: 0;
      transform: translateY(-50%);
    }
  }
}

/**
 * Centered variant
 */
.teaser--hero-center {
  @include media-breakpoint-up('lg') {
    position: relative;

    .teaser__container {
      @include make-absolute-container($teaser-hero-max-width-at-lg);
      top: 0;
      bottom: 0;
      margin-top: 0;
      padding: 0;
    }

    .teaser__container--newsletter {
      @include make-absolute-container($teaser-hero-newsletter-max-width-at-lg);
    }

    .teaser__frame {
      position: absolute;
      top: 50%;
      right: 0;
      left: 0;
      transform: translateY(-50%);
      background-color: rgba(#fff, .85);
    }

    .teaser__frame--newsletter {
      background-color: transparent;
    }
  }

  @include media-breakpoint-up('xl') {
    .teaser__container {
      @include make-absolute-container($teaser-hero-content-max-width-at-xl);
      top: 0;
      bottom: 0;
    }

    .teaser__container--newsletter {
      @include make-absolute-container($teaser-hero-newsletter-max-width-at-xl);
    }
  }
}

/**
 * Side variants
 */
.teaser--hero-right,
.teaser--hero-left {
  @include media-breakpoint-up('lg') {
    .teaser__container {
      @include make-container-custom;
      max-width: $teaser-hero-max-width-at-lg;
      padding: 0;
    }
  }

  @include media-breakpoint-up('xl') {
    .teaser__container {
      @include make-absolute-container($teaser-hero-side-max-width-at-xl);
      top: 0;
      bottom: 0;
      margin-top: 0;
    }

    .teaser__container--fullheight {
      margin: ($frame-size * 3) auto;
    }

    .teaser__frame {
      position: absolute;
      top: 50%;
      right: map-get($container-gutters, 'md');
      width: $teaser-hero-content-max-width-at-xl;
      transform: translateY(-50%);
    }

    .teaser__frame--main {
      width: $teaser-hero-main-max-width-at-xl;
    }

    .teaser__frame--fullheight {
      height: 100%;
      top: 0;
      transform: translateY(0);
    }
  }
}

// Left aligned content
.teaser--hero-left {
  @include media-breakpoint-up('xl') {
    .teaser__frame {
      right: auto;
      left: map-get($container-gutters, 'md');
      &--poll {
        left: auto;
        right: map-get($container-gutters, 'md');
        width: 580px;
        box-shadow: none;
      }
    }
  }
}
