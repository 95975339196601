// Path to PhotoSwipe assets
// stylelint-disable scss/dollar-variable-pattern
$pswp__assets-path: '../node_modules/photoswipe/src/css/default-skin/';

// Import PhotoSwipe
@import 'photoswipe/src/css/main';
@import 'photoswipe/src/css/default-skin/default-skin';

// Copyright
// stylelint-disable selector-class-pattern
.pswp__caption__copyright {
  color: #666;
  margin-left: 0.25rem;
}
