.poll {

  background-color: $white;

  .form-toggle__icon {
    border-color: $black;
  }
  .form-toggle__label {
    font-weight: bold;
  }

  &__chart-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    min-height: 480px;
  }

  &__chart {
    max-width: 450px;
    max-height: 450px;
  }

  @include media-breakpoint-up('sm') {
    padding-bottom: $frame-size * 2;
    padding-top: $frame-size;
  }

  @include media-breakpoint-up('md') {
    padding-top: $frame-size * 3;
    padding-bottom: $frame-size * 3;
  }

  @include media-breakpoint-up('xxl') {
    &__chart {
      margin-left: $frame-size * 4;
    }
  }
}
