/**
 * Custom container mixins
 */
@mixin make-container-custom() {
  margin-right: auto;
  margin-left: auto;
}

@mixin make-container-gutter($gutter) {
  padding-right: $gutter;
   padding-left: $gutter;
}

@mixin make-container-gutters($gutters: $container-gutters, $breakpoints: $grid-breakpoints) {
  @each $breakpoint, $gutter in $gutters {
    @include media-breakpoint-up($breakpoint, $breakpoints) {
      @include make-container-gutter($gutter);
    }
  }
}

@mixin make-absolute-container($max-width) {
  display: inline-block;
  position: absolute;
  right: 0;
  left: 0;
  width: 100%;
  max-width: $max-width;
  margin-right: auto;
  margin-left: auto;
}


/**
 * Break long words
 */
@mixin break-word() {
  overflow-wrap: break-word;
  word-wrap: break-word;
}

// Function to create an optimized svg url
@function svg-url($svg) {
  $encoded: '';
  $slice: 2000;
  $index: 0;
  $loops: ceil(str-length($svg) / $slice);

  @for $i from 1 through $loops {
    $chunk: str-slice($svg, $index, $index + $slice - 1);
    $chunk: str-replace($chunk, '"', '\'');
    $chunk: str-replace($chunk, '<', '%3C');
    $chunk: str-replace($chunk, '>', '%3E');
    $chunk: str-replace($chunk, '&', '%26');
    $chunk: str-replace($chunk, '#', '%23');
    $encoded: #{$encoded}#{$chunk};
    $index: $index + $slice;
  }

  @return url('data:image/svg+xml;charset=utf8,#{$encoded}');
}

// Helper function to replace characters in a string
@function str-replace($string, $search, $replace: '') {
  $index: str-index($string, $search);

  @if $index {
    @return str-slice($string, 1, $index - 1) + $replace +
      str-replace(str-slice($string, $index +
      str-length($search)), $search, $replace);
  }

  @return $string;
}

/**
 * Undos sr-only()
 *
 * @param $important Apply all styles as !important
 */
@mixin undo-sr-only($important: false) {
  @if ($important == true) {
    $important: !important;
  } @else if ($important == false) {
    $important: null;
  } @else {
    @error '`#{$important}` needs to be `true` or `false`.';
  }

  clip: auto $important;
  clip-path: none $important;
  height: auto $important;
  margin: 0 $important;
  overflow: visible $important;
  position: static $important;
  white-space: normal $important;
  width: auto $important;
}
