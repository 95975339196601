$form-switch-width: 40px !default;
$form-switch-size: 15px !default;

$form-switch-speed: 0.2s !default;
$form-switch-border-width: 1px !default;

$form-switch-color-off: $gray;
$form-switch-color-on: $green-gray;
$form-switch-color-border: $gray;
$form-switch-color-background: $white;

.form-switch {
  width: $form-switch-width;
  position: relative;

  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;

  &--immo-price,
  &--immo-price-gewerbe {
    width: 100px;
    margin-bottom: 10px;
  }

  &--immo-price-gewerbe {
    width: 160px;
  }

  &__label {
    height: $form-switch-size;
    margin: 0;
    padding: 0;

    display: block;
    overflow: hidden;
    cursor: pointer;

    line-height: $form-switch-size;
    border-radius: $form-switch-size;
    border: $form-switch-border-width solid $form-switch-color-border;
    background-color: $form-switch-color-off;

    transition: background-color $form-switch-speed ease-in;

    &:before {
      width: $form-switch-size;
      margin: 0;

      position: absolute;
      top: 0;
      bottom: 0;
      right: 25px;

      content: '';
      display: block;

      border: $form-switch-border-width solid $form-switch-color-border;
      border-radius: $form-switch-size;
      background-color: $form-switch-color-background;
      transition: all $form-switch-speed ease-in 0s;
    }
  }

  &__label--immo-price,
  &__label--immo-price-gewerbe {
    height: 24px;
    color: $white;
    padding: 4px 0 0 30px;
    background-color: $blue-strong;
    border-color: $blue-strong;

    // line-height: 20px;
    // border-radius: 20px;
    // border: 20px solid $form-switch-color-border;

    &:before {
      width: 24px;
      right: 76px;
    }

    .on {
      display: none;
    }
  }

  &__label--immo-price-gewerbe {
    &:before {
      right: 136px;
    }
  }

  &__input {
    display: none;

    &:checked + .form-switch__label {
      background-color: $form-switch-color-on;
    }

    &:checked + .form-switch__label--immo-price,
    &:checked + .form-switch__label--immo-price-gewerbe {
      background-color: $red;
      padding-left: 10px;
      .on {
        display: inline;
      }
      .off {
        display: none;
      }
    }

    &:checked + .form-switch__label, &:checked + .form-switch__label:before {
      border-color: $form-switch-color-on;
    }

    &:checked + .form-switch__label--immo-price, &:checked + .form-switch__label--immo-price:before,
    &:checked + .form-switch__label--immo-price-gewerbe, &:checked + .form-switch__label--immo-price-gewerbe:before {
      border-color: $red;
    }

    &:checked + .form-switch__label:before {
      right: 0;
    }
  }

  &__tab {
    display: none;

    &--active {
      display: block;
    }
  }
}
