.form-link {
  padding: 10px 0;

  display: inline-block;

  &--disabled {
    color: $black;

    pointer-events: none;
    cursor: default;
  }
}
