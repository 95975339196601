.video {
  &__container {
    position: relative;

    &--rendered {
      padding-bottom: 56.25%;
    }
  }

  &__frame {
    width: 100%;
    height: 100%;

    position: absolute;
    border: 0;
  }
}
