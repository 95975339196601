$flash-padding: 15px !default;
$flash-width: 550px;

.flash {
  &__wrapper {
    margin: 0 10%;

    position: fixed;
    top: 200px;
    left: 0;
    right: 0;

    z-index: 100;
  }

  &__container {
    margin: 0 auto;
    margin-bottom: 25px;

    padding: $flash-padding $flash-padding $flash-padding 25px;
    max-width: $flash-width;

    color: $gray;
    background-color: $white;

    -webkit-box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.2);
    box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.2);

    &.notice {
      border-left: 10px solid $blue-soft;
    }

    &.success {
      border-left: 10px solid $brand-success;
    }

    &.error {
      border-left: 10px solid $brand-danger;
    }
  }

  &__content {
    color: $gray-dark;
  }
}
