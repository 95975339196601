.icon-link {
  display: flex;
  align-items: center;
}

.icon-link__symbol {
  flex-shrink: 0;
  color: $green-gray;
  margin-right: 0.3rem;
}

.icon-link__text {
  color: $link-color;
  text-decoration: underline;

  .icon-link:hover & {
    color: $body-color;
    text-decoration: underline;
  }
}
