.merken {

  .no-js & {
    display: none;
  }

  button {
    padding-left: 2rem;
    background-image: svg-url('<svg xmlns="http://www.w3.org/2000/svg" width="19" height="18" viewBox="0 0 19 18" xmlns:xlink="http://www.w3.org/1999/xlink"><defs><path id="a" d="M9.06000042 13.6990793L3.46061222 17.2331449 5.0914022 10.8157239 -3.01425551e-14 6.58247561 6.60727184 6.15035691 9.06000042 0 11.512729 6.15035691 18.1200008 6.58247561 13.0285986 10.8157239 14.6593886 17.2331449z"/><mask id="b" width="18.1200008" height="17.2331449" x="0" y="0" fill="white"><use xlink:href="#a"/></mask></defs><use fill="none" fill-rule="evenodd" stroke="#FFFFFF" stroke-width="2" mask="url(#b)" xlink:href="#a"/></svg>');
    background-size: 1.2rem;
    background-repeat: no-repeat;
    background-position: center left 0.6rem;
  }

  &__article-list {
    color: $gray-dark;

    display: flex;
    flex-direction: row;

    align-items: center;
    justify-content: flex-end;

    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    height: 2rem;

    background-image: svg-url('<svg xmlns="http://www.w3.org/2000/svg" width="19" height="18" viewBox="0 0 19 18" xmlns:xlink="http://www.w3.org/1999/xlink"><defs><path id="a" d="M9.06000042 13.6990793L3.46061222 17.2331449 5.0914022 10.8157239 -3.01425551e-14 6.58247561 6.60727184 6.15035691 9.06000042 0 11.512729 6.15035691 18.1200008 6.58247561 13.0285986 10.8157239 14.6593886 17.2331449z"/><mask id="b" width="18.1200008" height="17.2331449" x="0" y="0" fill="white"><use xlink:href="#a"/></mask></defs><use fill="none" fill-rule="evenodd" stroke="#657676" stroke-width="2" mask="url(#b)" xlink:href="#a"/></svg>');
    background-size: 1.857em;
    background-repeat: no-repeat;
    background-position: top 1px right;

    .merken__label {
      padding-right: 2.2em;
    }

    &:hover {
      color: $brand-primary;
      background-image: svg-url('<svg xmlns="http://www.w3.org/2000/svg" width="19" height="18" viewBox="0 0 19 18" xmlns:xlink="http://www.w3.org/1999/xlink"><defs><path id="a" d="M9.06000042 13.6990793L3.46061222 17.2331449 5.0914022 10.8157239 -3.01425551e-14 6.58247561 6.60727184 6.15035691 9.06000042 0 11.512729 6.15035691 18.1200008 6.58247561 13.0285986 10.8157239 14.6593886 17.2331449z"/><mask id="b" width="18.1200008" height="17.2331449" x="0" y="0" fill="white"><use xlink:href="#a"/></mask></defs><use fill="none" fill-rule="evenodd" stroke="#004c93" stroke-width="2" mask="url(#b)" xlink:href="#a"/></svg>');
    }

  }

  &__article-list.merken__marked {
    background-image: svg-url('<svg xmlns="http://www.w3.org/2000/svg" width="19" height="18" viewBox="0 0 19 18"><path fill="#004c93" fill-rule="evenodd" d="M9.06000042 13.6990793L3.46061222 17.2331449 5.0914022 10.8157239 -3.01425551e-14 6.58247561 6.60727184 6.15035691 9.06000042 0 11.512729 6.15035691 18.1200008 6.58247561 13.0285986 10.8157239 14.6593886 17.2331449"/></svg>');
    background-size: 1.857em;
    background-repeat: no-repeat;
    background-position: top 1px right;

    &:hover {
      background-image: svg-url('<svg xmlns="http://www.w3.org/2000/svg" width="19" height="18" viewBox="0 0 19 18"><path fill="#004c93" fill-rule="evenodd" d="M9.06000042 13.6990793L3.46061222 17.2331449 5.0914022 10.8157239 -3.01425551e-14 6.58247561 6.60727184 6.15035691 9.06000042 0 11.512729 6.15035691 18.1200008 6.58247561 13.0285986 10.8157239 14.6593886 17.2331449"/></svg>');
    }
  }

  &__marked {

    button {
      background-color: $brand-primary;
      background-image: svg-url('<svg xmlns="http://www.w3.org/2000/svg" width="19" height="18" viewBox="0 0 19 18" xmlns:xlink="http://www.w3.org/1999/xlink"><defs><path id="a" d="M9.06000042 13.6990793L3.46061222 17.2331449 5.0914022 10.8157239 -3.01425551e-14 6.58247561 6.60727184 6.15035691 9.06000042 0 11.512729 6.15035691 18.1200008 6.58247561 13.0285986 10.8157239 14.6593886 17.2331449z"/><mask id="b" width="18.1200008" height="17.2331449" x="0" y="0" fill="white"><use xlink:href="#a"/></mask></defs><use fill="#ffffff" fill-rule="evenodd" stroke="#ffffff" stroke-width="2" mask="url(#b)" xlink:href="#a"/></svg>');

      .merken__label {
        color: #fff;
      }

      &:hover,
      &:focus {
        background-color: darken($brand-primary, 8%);

        .merken__label {
          color: #fff;
        }
      }

      // &:hover {
      //   background-image: svg-url('<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 19 18" xmlns:xlink="http://www.w3.org/1999/xlink"><defs><path id="a" d="M9.06000042 13.6990793L3.46061222 17.2331449 5.0914022 10.8157239 -3.01425551e-14 6.58247561 6.60727184 6.15035691 9.06000042 0 11.512729 6.15035691 18.1200008 6.58247561 13.0285986 10.8157239 14.6593886 17.2331449z"/><mask id="b" width="18.1200008" height="17.2331449" x="0" y="0" fill="white"><use xlink:href="#a"/></mask></defs><use fill="#ffffff" fill-rule="evenodd" stroke="#ffffff" stroke-width="2" mask="url(#b)" xlink:href="#a"/></svg>');
      // }
    }

    &:hover {
      color: $brand-primary;
    }

    .merken__label,
    .merken__label:hover {
      color: $brand-primary;
    }
  }

  @include media-breakpoint-up('md') {
    &__article-list {
      position: absolute;
      top: 32px;
      right: 40px;

      align-items: center;
      justify-content: center;
    }
  }

}
