.teaser-carousel {
  @include make-container-custom;
  @include make-container-gutters($teaser-container-gutters);
  max-width: 440px;

  .teaser__title {
    font-size: 1.475rem;
  }

  @include media-breakpoint-up('md') {
    &__stage {
      @include make-row;
    }
  }

  @include media-breakpoint-only('md') {
    max-width: 920px;
    &__item {
      @include make-col(6);
      .no-js & {
        &:nth-child(2n+1) {
          clear: left;
        }
      }
    }
  }

  @include media-breakpoint-up('lg') {
    max-width: 1340px;
    &__item {
      @include make-col(4);
      .no-js & {
        &:nth-child(3n+1) {
          clear: left;
        }
      }
    }
  }
}
