.skip-link {
  @include sr-only();

  background-color: $blue-strong;
  color: #fff;
  padding: 2rem;
  text-align: center;

  &:hover,
  &:focus,
  &:active {
    @include undo-sr-only($important: true);

    left: 0;
    outline: 0;
    position: fixed !important;
    right: 0;
    top: 0;
    z-index: 1001;
  }
}

.skip-link__text {
  @include focus-border();

  color: $white;
  padding: 1rem;
}
