.property-info {
  margin: $grid-gutter-width 0;
}

.property-info__title {
  @include type-scale(2);
  margin-bottom: 1rem;
}

.property-info__location {
  display: flex;
  margin: -0.25rem -0.5rem;
  padding-bottom: 1.5rem;
  flex-wrap: wrap;

  @include media-breakpoint-up('md') {
    justify-content: space-between;
  }
}

.property-info__location-item {
  display: inline-flex;
  align-items: center;
  line-height: 1;
  margin: 0.25rem 0.5rem;
}

.property-info__location-symbol {
  margin-right: 0.25rem;
  font-size: 1.125rem;
  color: $blue-dark;
}

.property-info__location-link {
  @include basic-link();
}
