$azubis-quotes-gutter-width: 20px;

.azubis-quotes {
  display: flex;
  flex-direction: column;
  margin-left: -($azubis-quotes-gutter-width);
  margin-right: -($azubis-quotes-gutter-width);
  padding: ($grid-gutter-width) 0;

  &__container {
    display: flex;
    flex-direction: column;
    margin: 0 $azubis-quotes-gutter-width;
  }

  &__figure {
    margin-bottom: ($azubis-quotes-gutter-width / 2);
  }

  &__top,
  &__bottom {
    display: flex;
    flex-direction: column;
    margin-bottom: $azubis-quotes-gutter-width;
  }

  &__bottom {
    margin-bottom: ($azubis-quotes-gutter-width * 2);
  }

  &__quote {
    display: flex;
    font-family: $font-family-special;
    align-self: flex-end;
  }

  @include media-breakpoint-up('sm') {
    padding: ($grid-gutter-width * 3) 0;

    &__container {
      padding: 0 $grid-gutter-width;
    }

    &__figure,
    &__footer {
      flex: 0 0 190px;
      margin-right: ($azubis-quotes-gutter-width * 1.5);
    }

    &__footer {
      text-align: right;
    }

    &__figure {
      margin-bottom: 0;
    }

    &__top,
    &__bottom {
      flex-direction: row;
    }
  }

  @include media-breakpoint-up('lg') {
    &__container {
      margin: 0;
    }
  }

  @include media-breakpoint-up('xl') {
    flex-direction: row;
    padding: ($grid-gutter-width * 5) 0;

    &__container {
      flex-basis: 0;
      flex-grow: 1;
      padding: 0;
      margin: 0 $azubis-quotes-gutter-width;
    }

    &__bottom {
      margin-bottom: 0;
    }
  }
}
