.icon-list__item {
  display: flex;
  align-items: center;

  &:not(:last-child) {
    margin-bottom: 0.5rem;
  }
}

.icon-list__icon {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-shrink: 0;
  width: 2.5rem;
  margin: 0;
  font-size: 1.5rem;
  color: $green-gray;
}

.icon-list__desc {
  flex-grow: 1;
  margin: 0;
}
