$teaser-map-frame-height-at-sm: 320px !default;
$teaser-map-frame-height-at-md: 480px !default;

$teaser-map-menu-margin-width: 20px !default;
$teaser-map-menu-margin-height: 10px !default;

$teaser-map-menu-margin-width-at-lg: 25px !default;
$teaser-map-menu-margin-height-at-lg: 10px !default;

$teaser-map-menu-margin-width-at-xl: 35px !default;
$teaser-map-menu-margin-height-at-xl: 10px !default;

.teaser-map {
  &__frame {
    width: 100%;
    height: $teaser-map-frame-height-at-sm;
    border: 1px solid #ccc;
    position: relative;
  }

  &__link {
    @include link;
    display: none;
  }

  &__kicker {
    text-align: left;
    padding: 5px 0 5px 33px;
    background-image: svg-url('<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 46"><path d="M4,0H36a4,4,0,0,1,4,4V36a4,4,0,0,1-4,4H24.9c-1.3,0-3.6,6-4.9,6s-3.8-6-5.1-6H4a4,4,0,0,1-4-4V4A4,4,0,0,1,4,0Z" fill="#004c93" fill-rule="evenodd"/><path d="M16,13l-4,4V12h4Zm14,7L20,9.6,10,20h2V30h5V23h6v7h5V20Z" fill="#fff"/></svg>');
    background-repeat: no-repeat;
    background-size: 1.4rem;
    background-position: center left 3px;

    &:hover {
      color: $brand-primary;
    }
  }

  &__links-list {
    @include list-unstyled;
    margin-left: -$teaser-spacing;
    margin-right: -$teaser-spacing;
    margin-bottom: -$teaser-spacing;
    a {
      display: block;
      padding: ($teaser-spacing / 2) $teaser-spacing;
      margin: 1px;
      background-color: $gray-lighter;
      color: $black;
      &:hover {
        color: $blue-strong;
      }
      &:active,
      &:focus {
        color: $blue-strong;
      }
    }
    .symbol {
      font-size: 0.75em;
      vertical-align: middle;
      color: $blue-strong;
      margin-left: 1em;
      margin-bottom: 2px;
    }
  }

  &__menu {
    margin-top: $teaser-map-menu-margin-height;
    margin-bottom: -($teaser-map-menu-margin-width);
    margin-left: -($teaser-map-menu-margin-width);
    margin-right: -($teaser-map-menu-margin-width);

    position: relative;
  }

  &__menu-list {
    @include list-unstyled;

    margin: 0;
    background-color: $gray-lighter;
  }

  &__menu-header {
    padding: 15px $teaser-map-menu-margin-width;
    display: block;
    margin-top: 1px;
    background-color: $white;
    text-transform: uppercase;
    font-weight: bold;
    @include type-scale(-1);

    cursor: pointer;
    background-image: svg-url('<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 2 10 6" width="10" height="6"><path d="M1.5,2L5,5.2,8.5,2,10,3.4,5,8,0,3.4Z" /></svg>');
    background-repeat: no-repeat;
    background-size: 1rem;
    background-position: center right $teaser-map-menu-margin-width;

    &.open {
      background-image: svg-url('<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 2 10 6" width="10" height="6"><path d="M8.5,8L5,4.8,1.5,8,0,6.6,5,2l5,4.6Z" /></svg>');
    }
  }

  &__menu-list-sub {
    @include list-unstyled;
    margin: 0;
    height: 0;
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.2s ease-in-out;

    &.open {
      height: auto;
      max-height: 600px;
      margin-bottom: -1px;
    }
  }

  &__menu-item-sub {
    height: 58px;
    border-bottom: 1px solid $white;

    &:empty {
      display: none;
    }
  }

  &__menu-item-sub .form {
    &-group {
      margin: 0;
      width: 100%;
      height: 100%;
    }

    &-toggle {
      margin: 0;
      padding: 0 15px;
      height: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      color: $blue;

      &__type-icon {
        width: 1.857rem;
        height: 1.857rem;
        color: inherit;
      }

      &__label {
        color: $black;
        margin-left: 10px;
      }

      &__icon {
        display: none;
      }
    }

  }

  &__menu-item-sub:hover .form,
  &__menu-item-sub.focusin:not(.teaser-map__menu-item-sub--active) .form {
    &-toggle {
      &__label {
        color: $blue;
        text-decoration: underline;
      }
    }
  }

  &__menu-item-sub--active .form {
    &-toggle {
      background-color: $blue-strong;
      color: $white;

      &__type-icon {
        color: inherit;
      }

      &__label {
        color: inherit;
      }
    }
  }

  &__menu-item-sub--active:hover .form {
    &-toggle {
      &__label {
        color: $white;
      }
    }
  }

  // Layout
  @include media-breakpoint-up('md') {

    &__links-list {
      margin-right: -$teaser-spacing-at-md;
      margin-left: -$teaser-spacing-at-md;
      margin-bottom: -$teaser-spacing-at-md;
      a {
        padding: ($teaser-spacing-at-md / 2) $teaser-spacing-at-md;
      }
    }

    &__menu {
      display: block;
    }

    &__frame {
      height: $teaser-map-frame-height-at-md;
    }
}

  @include media-breakpoint-up('xl') {

    &__links-list {
      margin-right: -$teaser-spacing-at-xl;
      margin-left: -$teaser-spacing-at-xl;
      margin-bottom: -$teaser-spacing-at-xl;
      a {
        padding: ($teaser-spacing-at-xl / 3) $teaser-spacing-at-xl;
      }
    }

    &__menu-list {
      display: flex;
    }

    &__menu-list-sub {
      display: block;
      height: auto;
      max-height: none;
      overflow: visible;
    }

    &__menu-item {
      width: 20%;
      width: calc(100% / 5);
    }

    &__menu-header {
      padding: 15px;
      margin-top: 1px;
      margin-right: 1px;
      text-align: center;
      cursor: auto;

      background-image: none;

      &.open {
        background-image: none;
      }

      &--last {
        margin-right: 0;
      }
    }

    &__menu-item-sub {
      height: 58px;
      border-right: 1px solid $white;
      border-bottom: 1px solid $white;

      &--firstcol {
        border-left: 1px solid $white;
      }

      &:empty {
        display: block;
      }
    }
  }

  @include media-breakpoint-between('md', 'xl') {
    &__menu {
      margin-top: $teaser-map-menu-margin-height-at-lg;
      margin-bottom: -($teaser-map-menu-margin-width-at-lg);
      margin-left: -($teaser-map-menu-margin-width-at-lg);
      margin-right: -($teaser-map-menu-margin-width-at-lg);
    }
  }

  @include media-breakpoint-up('xl') {
    &__menu {
      margin-top: $teaser-map-menu-margin-height-at-xl;
      margin-bottom: -($teaser-map-menu-margin-width-at-xl);
      margin-left: -($teaser-map-menu-margin-width-at-xl);
      margin-right: -($teaser-map-menu-margin-width-at-xl);
    }
  }
}

.teaser-map__frame .wall {
  // cursor: pointer;
}

.teaser-map__placeholder {
  cursor: pointer;
  height: 100%;
}

.teaser-map__placeholder-img {
  object-fit: cover;
  height: 100%;
}

.teaser--element-map-left .teaser-map__frame .wall {
  justify-content: flex-start;
}

.teaser--element-map-right .teaser-map__frame .wall {
  justify-content: flex-start;
}

.teaser--element-map-expose .teaser-map__frame .wall {
  justify-content: flex-start;
}

@include media-breakpoint-up('md') {
  .teaser--element-map-left .teaser-map__frame .wall {
    padding-top: calc(10% + 2rem);
  }

  .teaser--element-map-right .teaser-map__frame .wall {
    padding-top: calc(10% + 2rem);
  }

  .teaser--element-map-expose .teaser-map__frame .wall {
    padding-top: calc(10% + 2rem);
  }
}

@include media-breakpoint-up('lg') {
  .teaser--element-map-left .teaser-map__frame .wall {
    justify-content: center;
    padding-top: 2rem;
  }

  .teaser--element-map-right .teaser-map__frame .wall {
    justify-content: center;
    padding-top: 2rem;
  }

  .teaser--element-map-expose .teaser-map__frame .wall {
    justify-content: center;
    padding-top: 2rem;
  }
}

@include media-breakpoint-up('xl') {
  .teaser--element-map-left .teaser-map__frame .wall {
    padding-right: 180px;
    padding-left: 80px;
  }

  .teaser--element-map-right .teaser-map__frame .wall {
    padding-left: 180px;
    padding-right: 80px;
  }
}

@include media-breakpoint-up('poll-lg') {
  .teaser--element-map-left .teaser-map__frame .wall {
    padding-right: 80px;
    padding-left: 40px;
  }

  .teaser--element-map-right .teaser-map__frame .wall {
    padding-left: 80px;
    padding-right: 40px;
  }

  .teaser--element-map-expose .teaser-map__frame .wall {
    justify-content: flex-start;
    padding-top: 4rem;
  }
}
