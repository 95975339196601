
.section {
  margin-top: $grid-gutter-width;
  margin-bottom: $grid-gutter-width;

  // >:last-child,
  // >:last-child >:last-child {
  //   margin-bottom: 0;
  // }

  /**
   * Colored variants
   */
  &--white {
    background-color: $white;
  }

  /**
   * Spacing variants
   */
  &--none {
    margin-top: 0;
    margin-bottom: 0;
  }

  &--lg {
    margin-top: ($grid-gutter-width * 2);
    margin-bottom: ($grid-gutter-width * 2);
  }
  &--lg-top {
    margin-top: ($grid-gutter-width * 2);
  }
  &--lg-btm {
    margin-bottom: ($grid-gutter-width * 2);
  }

  &--pad {
    margin-top: 0;
    margin-bottom: 0;
    padding-top: $grid-gutter-width;
    padding-bottom: $grid-gutter-width;
  }

  @include media-breakpoint-up('md') {
    &--md {
      margin-top: ($grid-gutter-width * 2);
      margin-bottom: ($grid-gutter-width * 2);
    }
    &--md-top {
      margin-top: ($grid-gutter-width * 2);
    }
    &--md-btm {
      margin-bottom: ($grid-gutter-width * 2);
    }

    &--lg {
      margin-top: ($grid-gutter-width * 3);
      margin-bottom: ($grid-gutter-width * 3);
    }
    &--lg-top {
      margin-top: ($grid-gutter-width * 3);
    }
    &--lg-btm {
      margin-bottom: ($grid-gutter-width * 3);
    }

    &--pad {
      padding-top: ($grid-gutter-width * 2);
      padding-bottom: ($grid-gutter-width * 2);
    }
  }
}
