.item-group__title {
  font-size: 1rem;
  font-weight: bold;
  margin-bottom: 0.4rem;
  line-height: 1.1;
}

.item-group__list {
  @include list-unstyled;
  display: flex;
  flex-wrap: wrap;
  margin-top: -0.25rem;
  margin-bottom: 0;
}

.item-group__item {
  margin-top: 0.25rem;

  &:not(:last-child) {
    margin-right: 1rem;
  }
}
