.contact-link {
  bottom: 20px;
  display: flex;
  justify-content: flex-end;
  position: sticky;
  z-index: 2;

  @include media-breakpoint-up('sm') {
    bottom: 40px;
  }
}

.contact-link__link {
  align-items: center;
  background-color: $blue-dark;
  color: $white;
  display: flex;
  font-size: 1.8rem;
  justify-content: center;

  &:hover,
  &:focus {
    background-color: darken($blue-dark, 8%);
    color: $white;
  }

  @include media-breakpoint-up('sm') {
    font-size: 2.4rem;
    height: 4rem;
    width: 4rem;
  }
}
