.dot-nav {
  @include list-unstyled;
  line-height: 1;
  text-align: center;

  li {
    display: inline-block;
    margin: .1rem;
  }

  button {
    display: inline-block;
    padding: .4rem;
    font-size: 0;
    line-height: 0;

    &:before {
      border-radius: 50%;
      content: '';
      display: inline-block;
      height: .75rem;
      width: .75rem;
      background-color: $gray;
      border: 1px solid $gray;
    }
  }

  [aria-hidden="false"] button:before,
  button:hover:before {
    border-color: $green-gray;
    background-color: $white;
  }
}
