.password-meter__indicator {
  display: flex;
  align-items: center;
  background-color: $gray-lighter;
}

.password-meter__track {
  flex-grow: 1;
  background-color: $gray-light;
}

.password-meter__bar {
  width: 0;
  height: 1.1rem;
  background-color: $red;
}

.password-meter__bar--error {
  background-color: $red;
}

.password-meter__bar--warn {
  background-color: $orange;
}

.password-meter__bar--ok {
  background-color: $green;
}

.password-meter__text {
  @include type-scale(-2);

  flex-shrink: 0;
  text-align: center;
  min-width: 4rem;
  line-height: 0.5;
}
