.search-jobs {
  text-align: center;
  margin-bottom: 1rem;
  &__form-container {
    margin-bottom: 2rem;
  }
  &__form-group {
    text-align: left;
    width: 100%;
    margin: 1rem auto;
    padding: 0 10px;

    .form-toggle {
      margin-right: 2rem;
    }

    .form-toggle--check {
      display: block;
      width: 100%;
    }

    .form-label {
      margin-bottom: 0;
    }
  }
  &__submit {
    padding-left: 1.2em;
    padding-right: 1.2em;
  }

  @include media-breakpoint-up('sm') {
    margin: 1rem 0;
    &__form-container {
      margin-bottom: 3rem;
    }
    &__form-group {
      width: auto;
      margin: 1rem auto 1rem auto;

      .form-toggle--check {
        display: inline-block;
        width: auto;
      }
    }
    &__input {
      min-width: 450px;
    }
  }
}
