.feedback-storer {
  position: fixed;
  bottom: 0;
  right: 0;
  z-index: 999;
  width: 100%;
  text-align: center;
  transition: all 0.2s ease-out;
  display: none;
  &.active {
    display: block;
  }
  &__container {
    position: relative;
    display: block;
    background-color: $red;
    padding: 15px;
    box-shadow: 0 0 0 ($frame-size / 2) $frame-color;
  }
  &__collapse {
    display: none;
    position: absolute;
    top: 5px;
    right: 0;
    width: 1rem;
    height: 1rem;
    opacity: 0.7;
    z-index: 1000;
    background-image: svg-url('<svg xmlns="http://www.w3.org/2000/svg" viewBox="2 0 6 10" width="6" height="10"><path d="M2,8.5L5.2,5,2,1.5,3.4,0,8,5,3.4,10Z" fill="#ffffff"/></svg>');
    background-repeat: no-repeat;
    background-size: 0.5rem;
    &:hover {
      opacity: 1;
    }
  }
  &__button {
    display: block;
    text-align: left;
    color: #fff;
    padding-left: 4.3rem;
    margin-left: auto;
    margin-right: auto;
    background-image: svg-url('<svg xmlns="http://www.w3.org/2000/svg" width="50" height="42" viewBox="0 0 50 42"><path fill="#FFFFFF" fill-rule="evenodd" d="M872.524573,814.057143 L902,814.057143 L902,782 L852,782 L852,814.057143 L858.846053,814.057143 L863.19894,823.925586 L872.524573,814.057143 Z" transform="translate(-852 -782)"/></svg>');
    background-repeat: no-repeat;
    background-size: 3.1rem;
    background-position: top 3px left;
    width: auto;
    max-width: 300px;
    &:hover,
    &:focus {
      color: #fff;
    }
  }
  &__text-welcome {
    display: none;
  }
  &__text-c2a {
    font-weight: bold;
  }
  @include media-breakpoint-up('md') {
    right: 10%;
    width: auto;
    &__collapse {
      display: block;
    }
    &.collapsed {
      right: -315px;
    }
    &.collapsed:hover {
      right: -300px;
    }
    &__container {
      width: auto;
      max-width: 400px;
    }
    &__button {
      padding-left: 4.5rem;
      background-size: 3.8rem;
      max-width: none;
    }
    &__text-welcome {
      display: inline;
      font-weight: bold;
    }
    &__text-c2a {
      font-size: 0.857rem;
      font-weight: normal;
    }
  }
  @include media-breakpoint-up('lg') {
    right: 20%;
  }
}
