// Hide lazy loaded images when JS is disabled
.no-js .lazyload {
  display: none;
}

// Animate loaded images
.lazyload,
.lazyloading {
  opacity: 0;
}
.lazyloaded {
  opacity: 1;
  transition: opacity .3s;
}
