/**
 * Centered page region wrapper with side gutters and a maximum width
 */

.l-container {
	@include make-container-custom;
	@include make-container-max-widths;
}
.l-container-md {
	@include make-container-custom;
	@include make-container-max-widths($container-md-max-widths);
}
.l-container-sm {
	@include make-container-custom;
	@include make-container-max-widths($container-sm-max-widths);
}

.l-gutter {
	@include make-container-gutters;
}
.l-gutter-at-sm {
  @include media-breakpoint-only('sm') {
    @include make-container-gutter(map-get($container-gutters, 'xs'));
  }
}
.l-gutter-at-sm,
.l-gutter-at-md {
	@include media-breakpoint-up('md') {
		@include make-container-gutter(map-get($container-gutters, 'md'));
	}
}
