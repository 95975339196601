.article-footer {
  padding-right: $grid-gutter-width;
  padding-left: $grid-gutter-width;

  &,
  &__share,
  &__actions {
    @include list-unstyled;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    margin-bottom: 0;
  }

  &__item {
    padding: $grid-gutter-width / 4;

    &--print {
      display: none;
    }

    // Vertically center inner content
    > * {
      display: flex;
    }
  }

  @include media-breakpoint-down('md') {
    // Move actions to the top
    &__actions {
      order: -1;
      width: 100%;
    }
  }

  @include media-breakpoint-up('md') {
    &__item {
      &--print {
        margin-left: $grid-gutter-width;
        display: flex;
      }
    }
  }

  @include media-breakpoint-up('lg') {
    &__share {
      margin-right: $grid-gutter-width;
    }
  }
}
