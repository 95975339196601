$article-list-item-padding-height: 50px !default;
$article-list-item-padding-width: 100px !default;

$article-list-image-width-at-md: 135px !default;
$article-list-content-padding: 150px !default;

.article-list {
  margin-left: -($article-spacing);
  margin-right: -($article-spacing);

  .article {
    &__meta {
      margin: 0 0 0.5rem 0;
    }

    &__title {
      @include type-scale(3);
    }
  }

  &__item {
    padding: 0 0 ($article-list-item-padding-height / 2) 0;
    border-bottom: 1px solid $frame-color;

    &:last-child {
      border: 0;
    }

    &--immosearch,
    &--jobsearch {
      position: relative;
    }
  }

  &__image {
    margin: 0 0 10px 0;
  }

  &__content {
    @include clearfix;

    padding: 0 $article-spacing;
  }

  &__actions {
    margin-top: 20px;
    text-align: center;
  }

  &--with-padding {
    .article-list__item {
     padding-bottom: 0;
     border: 0;

     &:last-child .article-list__content {
       border: 0;
     }
    }

    .article-list__content {
     padding-bottom: $article-list-item-padding-height;

     border-bottom: 1px solid $frame-color;
    }
  }

  @include media-breakpoint-up('sm') {
    .article {
      &__meta {
        padding-right: 5.4rem;
      }
    }
  }

  // Medium+ VPs
  @include media-breakpoint-up('md') {
    &__item {
      padding-top: $article-list-item-padding-height;
      padding-bottom: $article-list-item-padding-height;
      padding-left: $article-list-item-padding-width;
      padding-right: $article-list-item-padding-width;
    }

    &__image {
      width: $article-list-image-width-at-md;
      margin: 0;

      float: left;
    }

    &__content {
      padding: 0;

      &--with-image {
        padding-left: $article-list-content-padding;
      }
    }
  }
}
