.teaser--vertical {
  position: relative;

  .teaser__frame {
    position: absolute;
    right: $frame-size;
    bottom: $frame-size;
    left: $frame-size;

    margin-bottom: 0;
  }

  @include media-breakpoint-up('lg') {
    padding-bottom: ($grid-gutter-width * 3);

    .teaser__frame {
      bottom: $frame-size;
    }
  }

  @include media-breakpoint-up('xl') {
    .teaser__frame {
      right: $frame-size + $grid-gutter-width;
      left: $frame-size + $grid-gutter-width;
    }
  }
}
