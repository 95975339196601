.search-immo-map {
  height: 360px;

  &__area {
    fill: $green-gray;

    &:hover,
    &--selected {
      fill: $blue;
    }

    &:hover:not(.search-immo-map__area--selected),
    &--hover:not(.search-immo-map__area--selected) {
      fill: $blue;
      opacity: 0.5;
    }

    &--selected:hover {
      opacity: 1;
    }
  }

  &__border {
    fill: none;
    stroke: $white;
    stroke-width: 2;
  }
}
