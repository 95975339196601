.breadcrumbs {
  position: absolute;
  top: -1.7rem;
  left: 0;
  right: 0;
  font-size: type-scale(-1);
  overflow-x: auto;
  white-space: nowrap;
  padding-left: 10px;

  &__list {
    @include list-unstyled;
    margin-bottom: 0;
  }

  &__item {
    display: inline-block;
    color: $black;
    padding-right: 1.5rem;
    line-height: 1;
    background-image: svg-url('<svg xmlns="http://www.w3.org/2000/svg" width="8" viewBox="0 0 13 16"><path d="M13 8.4L0 16V0z" fill="' + $blue-dark + '"/></svg>');
    background-repeat: no-repeat;
    background-position: right 0.36rem center;
  }

  &__item--current {
    background-image: none;
  }

  &__link {
    color: $blue-dark;
    &:hover {
      color: $black;
    }
  }

  @include media-breakpoint-up('lg') {
    bottom: 0.25rem;
    display: block;
    top: auto;
    padding-left: 1rem;

    &__link {
      color: $green-gray;
      &:hover {
        color: $black;
      }
    }

    &__item:not(.breadcrumbs__item--current) {
      background-image: svg-url('<svg xmlns="http://www.w3.org/2000/svg" width="8" viewBox="0 0 13 16"><path d="M13 8.4L0 16V0z" fill="' + $green-gray + '"/></svg>');
    }
  }
}
