$map-100-height-sm: 250px;
$map-100-height-md: 580px;

.map-100 {
  background-color: $white;
  height: calc(#{$map-100-height-sm} + 85px);
  transition: height .5s ease-in-out;
  width: 100%;

  @include media-breakpoint-up('md') {
    height: $map-100-height-md;
  }

  .gm-style-iw {
    background-color: transparent;
    box-shadow: none;
    max-height: none !important;
    padding: 0;
  }

  .gm-style-iw > button {
    display: none !important;
  }

  .gm-style-iw-d {
    overflow: hidden !important;
    max-height: none !important;
  }

  .gm-style-iw-tc {
    display: none;
  }
}

.map-100--expanded {
  height: 500px;

  @include media-breakpoint-up('md') {
    height: 800px;
  }
}

.map-100__cluster-marker-label {
  font-weight: bold;
}

.map-100__dialog {
  padding-left: 40px;
  padding-top: 40px;
  padding-right: 16px;
  padding-bottom: 16px;
  position: relative;

  .image__copyright {
    right: auto;
    left: 40px;
  }
}

.map-100__dialog-inner {
  background-color: $white;
  box-shadow: 0 0 16px rgba($black, 0.2);
  border-radius: 30px;
  padding: 30px;
}

.map-100__dialog-actions {
  text-align: right;
  margin-bottom: 150px;
}

.map-100__dialog-close-icon {
  width: 42px;
  height: 42px;
}

.map-100__dialog-image-wrapper {
  position: absolute;
  top: 0;
  left: 0;
}

.map-100__dialog-image {
  border-radius: 50%;
}

.map-100__dialog-kicker {
  color: $brand-primary;
  font-family: $font-family-special;
  font-size: 16px;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 0.02em;
  margin-bottom: 18px;
}

.map-100__dialog-kicker-icon {
  vertical-align: top;
  margin-right: 1px;
  height: 18px;
  width: 18px;
}

.map-100__dialog-headline {
  color: $brand-primary;
  font-family: $font-family-special;
  font-size: 24px;
  font-weight: bold;
  line-height: 1.08;
  margin-bottom: 15px;
}

.map-100__dialog-text {
  font-family: $font-family-base;
  font-size: 16px;
  line-height: 1.375;
} 

.map-100__dialog-link {
  margin-top: 15px;
  display: inline-block;
  background-color: $brand-primary;
  border-radius: 70px;
  color: $white;
  font-size: 18px;
  font-family: $font-family-base;
  text-align: center;
  width: 100%;
  padding: 15px 0;

  &:focus,
  &:hover {
    color: $white;
    background-color: rgba($brand-primary, 0.9);
  }
}

.map-100__dialog-link-icon {
  margin-left: 5px;
  position: relative;
  top: 1px;
}

.map-100__dialog-mobile {
  background-color: transparent;
  border: 0;
  padding: 0;

  .map-100__dialog {
    padding-left: 30px;
    padding-top: 30px;
  }

  .map-100__dialog-image-wrapper {
    max-height: 160px;
    max-width: 160px;
  }

  .image__copyright {
    left: 30px;
  }

  .map-100__dialog-actions {
    margin-bottom: 80px;
  }
}

.map-100__legend {
  background-color: $white;
  border-radius: 16px;
  box-shadow: 0 0 16px rgba($black, 0.2);
  padding: 12px 20px;
  margin-left: 15px;
  margin-top: 15px;
  min-width: 155px;

  @include media-breakpoint-down('sm') {
    display: none;
  }
}

.map-100__legend-header {
  display: flex;
  justify-content: space-between;

  .map-100__legend[aria-expanded=true] & {
    justify-content: flex-end;
  }
}

.map-100__legend-title {
  align-self: center;
  text-transform: uppercase;
  color: $brand-primary;
  font-size: 16px;
  font-family: $font-family-special;
  margin: 0;

  .map-100__legend[aria-expanded=true] & {
    display: none;
  }
}

.map-100__legend-close {
  position: relative;
  left: 5px;
}

.map-100__legend-close-icon {
  transition: transform .1s ease-in-out;
  height: 20px;
  width: 20px;

  .map-100__legend[aria-expanded=false] & {
    transform: rotate(45deg);
  }
}

.map-100__legend-list {
  @include list-unstyled;

  margin-bottom: 0;
  margin-right: 10px;
}

.map-100__legend-item {
  font-size: 16px;
  font-family: $font-family-base;
  margin-bottom: 14px;

  .map-100__legend[aria-expanded=false] & {
    display: none;
  }
}

.map-100__legend-icon {
  vertical-align: top;
  margin-right: 5px;
  height: 18px;
  width: 18px;
}

.map-100__consent {
  background-color: rgba($gray-dark, 0.95);
  color: $white;
  height: 100%;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 15px;
}

.map-100__consent-inner {
  max-width: 560px;
}

.map-100__consent-title {
  font-size: 16px;
  font-weight: bold;
}

.map-100__consent-text {
  margin-bottom: 30px;
}

.map-100__consent-link {
  color: $white;

  &:focus,
  &:hover {
    color: $white;
  }
}

.map-100__start {
  position: relative;
}

.map-100__image img {
  height: $map-100-height-sm;
  object-fit: cover;
  width: 100%;

  @include media-breakpoint-up('md') {
    height: $map-100-height-md;
  }
}

.map-100__start-button {
  border-radius: 50%;
  background-color: $brand-primary;
  font-family: $font-family-special;
  line-height: 1.1;
  width: 170px;
  height: 170px;
  color: $white;
  text-align: center;
  display: flex;
  align-items: center;
  position: absolute;
  left: 50%;
  bottom: -85px;
  transform: translateX(-50%);
  padding: 20px;
  z-index: 110;

  &:focus,
  &:hover {
    background-color: darken($brand-primary, 8%);

    .map-100__start-text:last-child {
      text-decoration: underline;
    }
  }

  @include media-breakpoint-up('md') {
    font-size: 24px;
    width: 250px;
    height: 250px;
    left: 82.5%;
    bottom: 30px;
  }

  @include media-breakpoint-up('xl') {
    left: 50%;
    margin-left: 480px;
  }
}

.map-100__start-button-inner {
  width: 100%;
}

.map-100__start-text {
  display: block;
  margin-bottom: 10px;
}
