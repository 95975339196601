/**
 * The main page footer
 *
 * @todo
 */

.footer {
  background-color: $blue-dark;
  color: $white;

  &__container {
    @include make-container-custom;
    @include make-container-gutters($teaser-container-gutters);
    padding-top: ($grid-gutter-width/2);
    padding-bottom: ($grid-gutter-width/2);
    font-size: type-scale(-1);
  }

  &__nav {
    @include list-unstyled;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: 1rem 0;
  }

  &__nav-item {
    margin: ($grid-gutter-width / 4) ($grid-gutter-width);
    flex: 1 0 50%;
  }

  &__nav-link {
    color: $white;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    &:hover,
    &:focus {
      color: $white;
      text-decoration: underline;
    }
  }

  &__rss {
    padding: $grid-gutter-width;
  }

  &__up {
    padding: $grid-gutter-width;
    text-align: center;
  }

  &__up-link {
    background-color: $white;
    color: $blue-dark;
    padding: 0.5rem 0.65rem;

    &:hover,
    &:focus {
      color: $black;
    }
  }

  &__up-link-symbol {
    margin-top: -0.1em;
    vertical-align: middle;
  }

  &__up-link-text {
    font-weight: bold;
    margin-left: 0.5rem;
  }

  .lang-switcher--footer {
    display: none;
  }

  @include media-breakpoint-up('md') {
    &__nav {
      width: 100%;
    }

    &__nav-item {
      flex: none;
    }

    &__rss {
      display: none;
    }

    &__up {
      display: block;
    }
  }

  @include media-breakpoint-up('lg') {
    &__nav {
      width: auto;
    }

    .lang-switcher--footer {
      display: inline-block;
      order: 0;
    }

    &__rss {
      display: inline-block;
      order: 2;
      margin: 1rem 0;
      padding: ($grid-gutter-width / 4) ($grid-gutter-width);
    }
  }
}
