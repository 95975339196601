// Default configuration
$type-scales: (
  -1: .875rem,
  0: 1rem,
  1: 1.2rem,
  2: ( 1.3rem, 'lg' 1.4rem ),
  3: ( 1.4rem, 'lg' 1.5rem ),
) !default;

// Checks if $size is a valid unit number
@function type-scale-is-size-valid($size) {
  @if (type_of($size) == number and unitless($size) == false) {
    @return true;
  }

  @warn '`#{$size}` is not a valid value for `font-size`.';
  @return false
}

// Checks if a specified breakpoint in config exists in a breakpoint config map
@function type-scale-is-bp-valid($breakpoint, $breakpoints) {
  @if (map-get($breakpoints, $breakpoint)) {
    @return true;
  }

  @warn 'Breakpoint `#{$breakpoint}` does not exist in breakpoint config.';
  @return false
}

// Returns the font-size for a key in config
@function type-scale($scale, $scales: $type-scales) {
  $scale_set: map-get($scales, $scale);

  @if ($scale_set) {
    $scale_set_type: type-of($scale_set);

    // If it's a valid number, simply return the value
    @if ($scale_set_type == number and type-scale-is-size-valid($scale_set)) {
      @return $scale_set;

    // Get the first item if it's a list
    } @else if ($scale_set_type == list) {
      $first-size: nth($scale_set, 1);

      // If the item is a valid number, return the value
      @if (type-scale-is-size-valid($first-size)) {
        @return $first-size;
      } @else {
        @return false;
      }
    }
  }

  @error '`#{$scale}` was not found in config.';
  @return false;
}

// Responsive type scales
// Generates media-specific font-sizes powered by a configuration map
@mixin type-scale($scale, $scales: $type-scales, $breakpoints: $grid-breakpoints) {
  $scale_sets: map-get($scales, $scale);

  @if ($scale_sets) {
    @each $scale_set in $scale_sets {
      $scale_set_type: type-of($scale_set);

      // If it's a valid number, simply return the value
      @if ($scale_set_type == number and type-scale-is-size-valid($scale_set)) {
        font-size: $scale_set;

      // If it's a list try to get the breakpoint and wrap in in a media query
      } @else if ($scale_set_type == list) {
        $size: nth($scale_set, 2);
        $breakpoint: nth($scale_set, 1);
        @include type-scale-for-bp($size, $breakpoint, $breakpoints);

      } @else {
        @warn 'Scale range for `#{$scale}` is not valid.';
      }
    }
  } @else {
    @warn 'Scale ranges for `#{$scale}` were not found in config.';
  }
}

// Generates a media query with a font-size for a specific breakpoint
@mixin type-scale-for-bp($size, $breakpoint, $breakpoints) {
  @if (
    type-scale-is-bp-valid($breakpoint, $breakpoints) and
    type-scale-is-size-valid($size)
  ) {
    @include media-breakpoint-up($breakpoint) {
      font-size: $size;
    }
  }
}
