$search-immo-col-padding: 10px !default;

.search-immo-form {
  position: relative;

  &__abo,
  &__map {
    position: absolute;
    top: 0;
    left: 0;

    display: none;
    background-color: $white;

    opacity: 0;
    transition: transform .1s ease-in-out, opacity .1s ease-in-out;

    &.is-expanded {
      display: block;
      opacity: 1;
      z-index: 100;

      // Overlay animation
      animation: anim .1s ease-in-out;
      height: 100%;
      width: 100%;
    }
  }

  // Overlay animation
  @keyframes anim {
    0% {
      display: none;
      opacity: 0;
    }
    1% {
      display: block;
      opacity: 0;
      transform: scale(0);
    }
    100% {
      opacity: 1;
      transform: scale(1);
    }
  }

  .form-label {
    padding-top: 0.25rem;
    margin-bottom: 0.35rem;

    &--left {
      display: inline-block;
      float: left;
    }
  }

  &__row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    margin-left: -$search-immo-col-padding;
    margin-right: -$search-immo-col-padding;
    padding-bottom: 0;

    &--last {
      padding-top: 15px;
    }
  }

  &__col {
    flex-basis: 100%;
    flex-grow: 1;
    padding-bottom: 15px;
  }

  &__col--results {
    text-align: center;
  }

  &__col--address,
  &__col--abo {
    position: relative;
  }

  .form-group &__function {
    margin: 0 $search-immo-col-padding 15px $search-immo-col-padding;
  }

  &__button {
    width: 100%;
  }

  &__area,
  &__abo-duration {
    position: absolute;
    right: 0;
    top: 0;
    right: $search-immo-col-padding;
  }

  &__area {
    width: 100px;
  }

  &__abo-duration {
    width: 120px;
  }

  &__abo-kicker,
  &__abo-divider {
    @include type-scale(-1);
    color: $green-gray;
    text-transform: uppercase;
    font-weight: bold;
  }

  &__abo-kicker {
    margin-right: 7px;
  }

  &__abo-divider {
    @include type-scale(1);
    margin: 0 7px;
  }

  &__col--map {
    display: none;
  }

  &__function--abo-email-col {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  &__function--abo-email-row {
    display: flex;
    flex-direction: row;
  }

  @include media-breakpoint-up('md') {
    &__row {
      padding-bottom: 0;
    }

    &__col {
      flex-basis: 50%;
      padding-bottom: 30px;
    }

    &__col--results {
      text-align: left;
    }

    &__col--mapsearch {
      margin-left: 50%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    &__col--area-search {
      width: 100%;

      &:nth-child(odd) {
        padding: 0;
      }
    }

    .form-group &__function {
      margin: 0 $search-immo-col-padding 0 $search-immo-col-padding;
    }
  }

  @include media-breakpoint-up('lg') {
    &__row {
      padding-bottom: 30px;

      &--related {
        padding-bottom: 15px;
      }

      &--last {
        padding-bottom: 0;
        padding-top: 15px;
      }
    }

    &__col {
      flex-basis: 25%;
      padding-bottom: 0;
    }

    &__col--mapsearch {
      margin-left: 0;
    }

    &__button {
      margin-top: 29px;
    }

    &__col--address {
      flex-basis: 50%;
      padding-right: 0;
    }

    &__area {
      padding: 0;
      right: $search-immo-col-padding;
    }

    &__col--map {
      display: inline-block;
      flex-basis: 50%;
      text-align: center;
    }
  }
}
