.ar-start {
  @include media-breakpoint-up('md') {
    display: flex;
  }
}

.ar-start__tile {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #63bfb5;
  margin-bottom: 0.5rem;
  padding: 2rem;
  width: 100%;

  &:hover {
    background-color: rgba(#63bfb5, 0.75);
  }

  @include media-breakpoint-up('md') {
    width: 45%;
    margin-bottom: 0;
    margin-right: 0.5rem;
  }
}

.ar-start__image {
  @include media-breakpoint-up('md') {
    width: 55%;
  }
}

.ar-start__link {
  font-family: "Myriad Pro", "Trebuchet MS", $font-family-base;

  &:focus,
  &:hover {
    color: $brand-primary;
  }
}
