.form-group {
  margin-bottom: $spacer;
  text-align: left;

  &:first-child {
    margin-bottom: 0;
  }

  &--right {
    text-align: right;
  }

  &--inline {
    display: inline-block;
    width: auto;
    position: relative;
  }

  &--relative {
    display: inline;
    position: relative;
  }

  @include media-breakpoint-up('sm') {
    &:first-child {
      margin-bottom: $spacer;
    }

    &--pull-left {
      float: left;
    }

    &--pull-right {
      float: right;
    }
  }
}
