$form-select-bg-color: #fff !default;
$form-select-icon: svg-url('<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 2 10 6" width="10" height="6"><path fill="' + $brand-primary + '" d="M1.5,2L5,5.2,8.5,2,10,3.4,5,8,0,3.4Z"/></svg>')  !default;
$form-select-disabled-icon: svg-url('<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 2 10 6" width="10" height="6"><path d="M1.5,2L5,5.2,8.5,2,10,3.4,5,8,0,3.4Z" fill="#a9a9a9"/></svg>') !default;
$form-select-icon-size: .65rem !default;
$form-select-icon-padding-x: .65rem !default;
$form-select-icon-width: ($form-select-icon-size + 2 * $form-select-icon-padding-x) !default;
$form-select-padding-x: .75rem !default;
$form-select-padding-y: .5rem !default;
$form-select-padding-r: ($form-select-icon-width + $form-select-icon-padding-x) !default;
$form-select-border-width: 1px !default;
$form-select-border-color: $gray !default;
$form-select-focus-border-color: $blue !default;

.form-select {
  display: inline-block;
  max-width: 100%;
  padding: $form-select-padding-y $form-select-padding-r $form-select-padding-y $form-select-padding-x;
  border: $form-select-border-width solid $form-select-border-color;
  line-height: 1.15;
  background: $form-select-bg-color $form-select-icon no-repeat right $form-select-padding-x center;
  background-image: none \9;
  background-size: $form-select-icon-size;
  -moz-appearance: none;
  -webkit-appearance: none;
  // Best-match input height @todo
  // min-height: calc(#{($form-select-padding-y * 2) + 1rem} + #{4px});

  &:disabled {
    color: #A9A9A9;
    background-image: $form-select-disabled-icon;
  }

  &:focus {
    border-color: $form-select-focus-border-color;
  }

  &--block {
    display: block;
    width: 100%;
  }

  &--filter {
    border: none;
    padding-left: 0;
    text-align: right;
    cursor: pointer;
    opacity: 0.7;

    &:hover {
      opacity: 1;
    }
  }

  .has-danger & {
    border-color: $brand-danger;
  }
  .has-success & {
    border-color: $brand-success;
  }
}
