.form-feedback {
  @include type-scale(-1);
  display: none;
  margin-top: ($spacer / 4);

  .has-danger & {
    color: $brand-danger;
    display: block;
  }
  .has-success & {
    color: $brand-success;
    display: block;
  }
}
