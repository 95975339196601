.tile-grid {
  @include make-container-custom;

  @include media-breakpoint-up('md') {
    @include make-container-gutters($teaser-container-gutters);
  }

  @include media-breakpoint-up('md') {
    max-width: 920px;
  }

  @include media-breakpoint-up('xl') {
    max-width: 1340px;
  }
}

.tile-grid__row {
  @include media-breakpoint-up('md') {
    display: flex;
    flex-wrap: wrap;
    margin: $grid-gutter-width / -2;
  }
}

.tile-grid__item {
  @include media-breakpoint-up('md') {
    display: flex;
    flex-direction: column;
    flex: 0 0 50%;
    width: 50%;
    padding: $grid-gutter-width / 2;
  }

  @include media-breakpoint-up('xl') {
    flex-basis: 25%;
    width: 25%;
  }
}
