.energy-scale {
  margin-top: 30px;

  &__canvas {
    height: 40px;

    position: relative;
    border-radius: 5px;
    overflow: hidden;
  }

  &__scale {
    @include list-unstyled;

    width: 100%;
    height: 40px;
    padding: 9px 0;

    position: absolute;
    top: 0;
  }

  &__pointers {
    @include list-unstyled;
    @include clearfix;

    width: 100%;
    height: 45px;
    overflow: hidden;
    margin-bottom: 0;
    // height: 40px;
    // padding: 9px 0;

    // position: absolute;
    // top: 0;
  }

  &__pointer {
    visibility: hidden;
    float: left;
    text-align: center;
    padding-bottom: 1rem;

    &:after {
      content: svg-url('<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 10 8"><path fill="#000" fill-rule="nonzero" d="M5 7.5L0 0 10 0"/></svg>');
      display: inline-block;
      width: 1rem;
      height: 1rem;
      // background-image: svg-url('<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 10 8"><path fill="#000" fill-rule="nonzero" d="M5 7.5L0 0 10 0"/></svg>');
      // background-repeat: no-repeat;
    }

    &--active {
      visibility: visible;
    }

    &--a-plus {
      width: 13%;
    }

    &--a {
      width: 7.1%;
    }

    &--b,
    &--c {
      width: 9.6%;
    }

    &--d {
      width: 12.3%;
    }

    &--e {
      width: 11.4%;
    }

    &--f {
      width: 14.7%;
    }

    &--g {
      width: 15.6%;
    }

    &--h {
      width: 6.7%;
    }
  }

  &__icon {
    width: 2em;
    height: 2em;
    position: relative;
  }

  &__text {
    text-align: center;
    position: relative;
  }

  &__class,
  &__value {
    float: left;
    display: inline-block;
  }

  &__class {
    text-align: center;
    border-right: 1px solid $black;

    &:last-child {
      border: 0;
    }

    &--a-plus {
      width: 13%;
    }

    &--a {
      width: 7.1%;
    }

    &--b,
    &--c {
      width: 9.6%;
    }

    &--d {
      width: 12.3%;
    }

    &--e {
      width: 11.4%;
    }

    &--f {
      width: 14.7%;
    }

    &--g {
      width: 15.6%;
    }

    &--h {
      width: 6.7%;
    }
  }

  &__value {
    @include type-scale(-1);

    width: 9.45%;
    color: $green-gray;

    &:last-child {
      width: 5.5%;
    }
  }

  &__legend {
    @include clearfix;
    @include list-unstyled;
  }
}
