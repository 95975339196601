.neubau-progress {
  margin: 3rem -20px -26px -20px;

  &__status {
    @include list-unstyled;
    padding-left: 0;
    margin-bottom: 0;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
  }

  &__status-item {
    @include type-scale(-1);
    text-align: center;
    width: 16.666%;
    padding-bottom: 1rem;
  }

  &__status-item--inactive {
    opacity: 0.3;
  }

  &__status-icon {
    width: 2rem;
    height: 2rem;
    color: $green-gray;
  }

  &__status-title {
    display: block;
    text-transform: uppercase;
    font-weight: bold;
  }

  &__indicator[value] {
    /* Reset the default appearance */
    -webkit-appearance: none;
    appearance: none;
    border: none;
    width: 100%;
    height: 20px;

  }

  &__indicator[value]::-webkit-progress-bar {
    background-color: $white;
  }

  &__indicator[value]::-webkit-progress-value {
    background-color: rgba($green, .1);
    background-image:  linear-gradient(-45deg, $green 33%, transparent 33%, transparent 66%, $green 66%);
    background-size: 80px 20px, 100% 100%, 100% 100%;
  }

  @include media-breakpoint-down('md') {
    display: none;
  }
}
