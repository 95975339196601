$walkthrough-left-column-width-at-lg: 500px !default;

$walkthrough-right-column-width-at-lg: 360px !default;
$walkthrough-right-column-width-at-xl: 440px !default;

$walkthrough-line-position-at-lg: 533px !default;
$walkthrough-line-position-at-xl: 573px !default;

.walkthrough {
	position: relative;

	&__item {
		margin: 20px 0;

		@include clearfix;
	}

	&__col {
		&--left {
			width: 100%;
			margin: 20px 0;

			float: left;
		}

		&--center {
			float: left;
		}
	}

	&__pin {
		margin: 0 10px 0 0;
		background-color: $white;

		position: relative;
		z-index: 1;

		.symbol {
			width: 3.5em;
			height: 3.5em;

			color: $brand-primary;
		}

		&-number {
			position: absolute;
			left: 15px;
			top: 7px;

			font-weight: bold;
			font-size: 1.5rem;

			color: $white;
		}
	}

	&__duration {
		margin: 10px 10px 0 2px;
		padding: 10px 0 0 0;

		color: $brand-primary;
		background-color: $white;

		text-align: center;
		z-index: 1;

		.symbol {
			width: 2em;
			height: 2em;

			margin: 0 0 -5px -9px;
		}

		&-time {
			font-size: 1.5rem;
			font-weight: bold;
		}

		&-unit {
			font-size: 0.85rem;
			font-weight: bold;

			text-transform: uppercase;
		}
	}

	/**
	 * Layout
	 */

	// Large+ VPs
	@include media-breakpoint-up('lg') {
		&__image {
			max-width: $walkthrough-left-column-width-at-lg;
		}

		&__col {
			&--left {
				width: $walkthrough-left-column-width-at-lg;
				margin: 0;
			}

			&--center {
				margin: 0 10px;
			}

			&--right {
				width: $walkthrough-right-column-width-at-lg;
				float: right;
			}
		}

		&__pin {
			margin: 0;
		}

		&__duration {
			position: absolute;
			bottom: 0;
			margin: 0;
		}

		&:after {
			position: absolute;
			left: $walkthrough-line-position-at-lg;
			top: 0;
			bottom: 0;

			border-left: 10px solid $blue;

			content: '';
			z-index: 0;
		}
	}

	// X-Large+ VPs
	@include media-breakpoint-up('xl') {
		&__col {
			&--center {
				margin: 0 50px;
			}

			&--right {
				width: $walkthrough-right-column-width-at-xl;
			}
		}

		&:after {
			left: $walkthrough-line-position-at-xl;
		}
	}
}
