.combo-section {
  margin-bottom: ($grid-gutter-width * 2);

  &__header,
  &__body {
    padding-top: $grid-gutter-width;
  }

  &__header {
    padding-top: $grid-gutter-width * 4;
    padding-bottom: $grid-gutter-width * 2;
    background-color: $white;
  }

  &__header--search-immo {
    padding-bottom: $grid-gutter-width;
  }

  @include media-breakpoint-up('md') {
    margin-bottom: ($grid-gutter-width * 3);

    &__header {
      padding-top: ($grid-gutter-width * 4);
      padding-bottom: ($grid-gutter-width * 4);
    }
    &__header--search-immo {
      padding-bottom: $grid-gutter-width * 2;
    }
    &__body {
      padding-top: 0;
      margin-top: -($grid-gutter-width * 2);
    }
  }

  @include media-breakpoint-up('xl') {
    &__header {
      padding-bottom: ($grid-gutter-width * 6);
    }
    &__header--search-immo {
      padding-bottom: $grid-gutter-width * 4;
    }
    &__body {
      padding-top: 0;
      margin-top: -($grid-gutter-width * 3);
    }
  }
}
