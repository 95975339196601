/**
 * The language switcher element
 *
 * @todo
 */

.lang-switcher {
  background-color: $blue-dark;
  position: relative;

  &__btn {
    display: block;
    width: 100%;
    text-align: left;
    color: $white;
    padding: 1rem;
    padding-left: 1.500rem;
    background-image: svg-url('<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 2 10 6" width="10" height="6"><path d="M1.5 2L5 5.2 8.5 2 10 3.4 5 8 0 3.4z" fill="#fff"/></svg>');
    background-repeat: no-repeat;
    background-size: 0.857rem;
    background-position: center right 10px;
    &:hover,
    &:focus {
      color: $white;
    }
  }

  &__btn--footer {
    background-image: svg-url('<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 10 8" width="10" height="8"><path d="M8.5 8L5 4.8 1.5 8 0 6.6 5 2l5 4.6z" fill="#fff"/></svg>');
    padding: 5px 2rem 0 1rem;
    margin: 1rem 0;
    background-position: center right 5px;
  }

  .open &__btn {
    background-image: svg-url('<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 2 10 6" width="10" height="6"><path d="M8.5 8L5 4.8 1.5 8 0 6.6 5 2l5 4.6z" fill="#fff"/></svg>');
  }

  .open &__btn--footer {
    background-image: svg-url('<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 10 8" width="10" height="8"><path d="M1.5 2L5 5.2 8.5 2 10 3.4 5 8 0 3.4z" fill="#fff"/></svg>');
  }

  &__icon {
    vertical-align: middle;
    margin-top: -0.1em;
    color: $white;
  }

  &__current-language {
    padding-left: 0.375rem;
  }

  &__current-language--footer {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }

  &__list {
    @include list-unstyled;
    margin-bottom: 0;
    max-height: 0;
    transition: max-height .2s ease-in-out;
    position: static;
    overflow: hidden;
  }

  &__list--footer {
    position: absolute;
    bottom: 100%;
    left: 0;
    min-width: 130px;
    background-color: $blue-dark;
  }

  .open > &__list {
    max-height: 300px;
  }

  &__list-item {
    background-color: $white;
  }

  &__list-item--footer {
    background-color: $blue-dark;
    &:first-child {
      padding-top: 0.4rem;
    }    
  }

  &__link {
    display: block;
    padding: 0.500rem 0 0.500rem 1.500rem;
    color: $black;
    &:hover {
      text-decoration: none;
      background-color: $gray-lighter;
    }
  }

  &__link--footer {
    color: $white;
    padding: 0.500rem 1.1rem 0.500rem 1.1rem;
    &:hover {
      color: $white;
      background-color: $blue-dark;
      text-decoration: underline;
    }
  }

}
