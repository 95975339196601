$form-input-padding-x: .75rem !default;
$form-input-padding-y: .5rem !default;
$form-input-border-color: $gray !default;
$form-input-focus-border-color: $blue !default;
$form-input-bg-color: $white !default;
$form-input-placeholder-color: $gray !default;

.form-input {
  display: block;
  width: 100%;
  padding:0.4rem 0.75rem 0.35rem 0.75rem;
  border: 1px solid $form-input-border-color;
  // line-height: 1;
  background-color: $form-input-bg-color;

  &:focus {
    border-color: $form-input-focus-border-color;
  }

  &::placeholder {
    color: $form-input-placeholder-color;
  }

  &[type='submit'] {
    width: auto;

    border: 0;
    color: $brand-primary;

    display: inline;

    &:hover {
      background-color: darken($white, 5%);
    }
  }

  .has-danger & {
    border-color: $brand-danger;
  }

  .has-success & {
    border-color: $brand-success;
  }

  &--with-btn {
    position: relative;

    .form-input {
      padding: 0.5rem 7.75rem 0.5rem 0.75rem;
    }

    .btn {
      height: 100%;

      position: absolute;
      top: 0;
      right: 0;
    }
  }

  &--file {
    border: 0;
    padding-left: 0;
    display: inline-block;
    width: auto;
  }

  &--abo-email {
    max-width: 400px;

    border-right: none;
  }

  &--immosearch-address,
  &--immosearch-abo-email {
    display: flex;
    flex-direction: row;

    .form-input {
      display: block;
    }
  }

  &--immosearch-address .form-input,
  &--immosearch-address .form-suggestions {
    margin-right: 100px;
  }

  &--immosearch-address .form-reset {
    position: absolute;
    right: 115px;
  }

  &--immosearch-abo-email .form-input {
    margin-right: 120px;
  }

  &--with-btn-newsletter {
    position: relative;
    width: 100%;
    max-width: 400px;
    margin-left: auto;
    margin-right: auto;

    .form-input {
      padding: 0.5rem 7.75rem 0.45rem 0.75rem;
    }

    .btn {
      font-family: $font-family-special;
      margin-top: 0.5rem;
      width: 100%;
      padding-bottom: 0.5em;
    }

    @include media-breakpoint-up('sm') {
      .btn {
        width: auto;
        margin-top: 0;
        position: absolute;
        top: 0;
        right: 0;
      }
    }

    @include media-breakpoint-up('md') {
      width: 450px;
      max-width: none;
    }
  }

  &--with-icon {
    position: relative;
    border-bottom: 1px solid $form-input-border-color;

    .form-input {
      padding: 0.5rem 0.75rem 0.5rem 2.5rem;
      border: 0;
    }

    .symbol {
      position: absolute;
      left: 5px;
      bottom: 8px;
    }
  }

  &--inline {
    display: inline-block;
    margin-right: 5px;
    width: auto;
  }

  &--multiselect {
    display: inline-block;
    margin-right: 5px;
  }

  .form-input-spinner & {
    padding-right: 2rem;
  }

  &--to,
  &--from {
    margin-right: 5px;
  }

  &--price {
    width: 60px;
  }

  &--qm {
    width: 50px;
  }

  &--rooms {
    width: 35px;
  }
}

textarea.form-input {
  min-height: 4rem;
}

.form-input--nav-search {
  border: 0;
  border-bottom: 1px solid $form-input-border-color;
  background-image: svg-url('<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 32"><path d="M27.5,28,22,20.6a12.4,12.4,0,0,0,.5-15.7A12.6,12.6,0,0,0,4.9,2.5,12.4,12.4,0,0,0,2.6,20a12.6,12.6,0,0,0,15.6,3.6l5.5,7.5a2.4,2.4,0,0,0,1.8.9,2.4,2.4,0,0,0,1.4-.5,2.5,2.5,0,0,0,1-1.7A2.5,2.5,0,0,0,27.5,28ZM3.8,19.1A10.9,10.9,0,0,1,5.9,3.8,11,11,0,0,1,21.3,5.9a10.9,10.9,0,0,1-2.1,15.3A11,11,0,0,1,3.8,19.1Z"/></svg>');
  background-repeat: no-repeat;
  background-size: 0.857rem 1rem;
  background-position: 0.625rem;
  padding-left: ($form-input-padding-x*3)
}

.form-input-wrap--contract {
  position: relative;

  &::after {
    content: '_ _ _ _ / _ _ _ _ _ _ - _ _ _ _ _ _';
    color: $form-input-placeholder-color;
    padding:0.4rem 0.75rem 0.35rem 0.75rem;
    position: absolute;
    top: 1px;
    left: 1px;
    display: block;
    z-index: 1;
  }
}

.form-input--contract {
  position: relative;
  z-index: 2;
  background-color: transparent;
  letter-spacing: 3.7px;
}


/* visually hidden screenreader text */
.form__counter{
    &-hint{
        font-weight: bold;
        display: none;

        &.visible{
            display: block;
        }
    }
    &-screenreader-text {
        position: absolute !important;
        width: 1px !important;
        height: 1px !important;
        padding: 0 !important;
        margin: -1px !important;
        overflow: hidden !important;
        clip: rect(0,0,0,0) !important;
        white-space: nowrap !important;
        border: 0 !important;
    }
}

