@import 'shared/link';

/**
 * Content elements
 */

/**
 * Spacing base
 */
%ce,
.ce {
  margin-bottom: 2rem;

  // &:last-child,
  // >:last-child {
  //   margin-bottom: 0;
  // }
}

/**
 * Long text & and headings
 */
.ce-text {
  @extend %ce;

  ul,
  ol,
  p,
  table {
    @include type-scale(0);
    margin-bottom: 1.5rem;
  }

  ul,
  ol {
    padding-left: 1rem;
    &.unstyled {
      list-style: none;
      padding-left: 0.2rem;
    }
  }

  li + li {
    margin-top: .5rem;
  }

  table {
    width: 100%;
    margin-bottom: 1.5rem;
    tr {
      border-bottom: 1px solid $gray;
    }

    td,
    th {
      padding: 8px 0 3px;
    }

    &.simple {
      thead {
        display: none;
      }
      tr {
        border: none;
      }
    }
  }

  @include media-breakpoint-down('sm') {

    //Responsive tables
    table:not(.simple) {
      thead {
        display: none;
      }
      tr {
        display: block;
        border: 0;
        margin-bottom: 30px;
      }
      td {
        display: block;
        border-bottom: 1px solid $gray;

        &:before {
          content: attr(aria-label);
          float: left;
          font-weight: bold;
        }
      }
    }
  }

  ul > a,
  ol > a,
  li > a,
  p > a {
    @include link-inline;
  }

  a {
    @include link;
  }
}

.ce-h2,
.ce-text h2 {
  @include type-scale(2);
  margin-top: 3rem;
  margin-bottom: 1rem;
}

.article-job__h2,
.ce-text h2.article-job__h2 {
  font-size: 1.5rem;
  margin-bottom: 1rem;
  color: $blue-dark;
}

.ce-h3,
.ce-text h3 {
  @include type-scale(1);
  margin-bottom: 1rem;
}

.ce-h4,
.ce-text h4 {
  @include type-scale(0);
  margin-bottom: 1rem;
  font-family: $font-family-special;
  font-weight: bold;
}

.ce-text--small {
  &,
  ul,
  ol,
  p,
  table {
    @include type-scale(-1);
    margin-bottom: 1rem;
  }

  h2,
  h3,
  h4 {
    margin-top: 0;
    margin-bottom: 0.5rem;
  }

  &:last-child,
  >:last-child {
    margin-bottom: 0;
  }
}

/**
 * Video
 */
.ce-video {
  @extend %ce;
}

/**
 * Webcam
 */
.ce-webcam {
  @extend %ce;
}

/**
 * Accordion
 */
.ce-accordion {
  @extend %ce;
}

/**
 * Figure
 */
.ce-figure {
  @extend %ce;
  &__caption {
    @include type-scale(-1);
    margin-top: .5rem;
    text-transform: uppercase;
    font-weight: bold;
    color: $green-gray;
  }

  &__media {
    position: relative;
    text-align: center;
    background-color: $gray-lightest;
  }

  &__media-bordered {
    text-align: center;
    background-color: $white;
    border: 1px solid $black;
    padding: $grid-gutter-width;
  }

  &__button {
    width: 3rem;
    height: 3rem;
    display: block;
    position: absolute;
    right: 1rem;
    bottom: 1rem;
    z-index: 1;
    color: $gray-dark;
    background-color: $white;
    transition: color $link-transition-duration, background-color $link-transition-duration;

    &:focus,
    &:hover {
      color: $white;
      background-color: $brand-primary;
    }
  }

  &__button-icon {
    height: 1.6em;
    width: 1.6em;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

/**
 * Gallery
 */
.ce-gallery,
.ce-carousel {
  @extend %ce;

  margin-top: 3rem;
  margin-bottom: 1rem;
}

/**
 * Quote
 */
.ce-quote {
  @extend %ce;

  &__cite {
    @include type-scale(-1);
    display: block;
    margin-bottom: .5rem;
    font-style: normal;
    font-weight: bold;
    text-transform: uppercase;
    color: $green-gray;
  }

  &__text {
    @include type-scale(2);
    line-height: 1.2;
    color: $green-gray;
  }
}

.ce-quote h2 {
  @include type-scale(2);
  margin-top: 3rem;
}

.ce-timeline {
  @include list-unstyled;
  padding-left: 0;

  &__item {
    margin-bottom: 1rem;
  }

  &__time {
    color: $green-gray;
    font-weight: bold;
    text-transform: uppercase;
  }
}

ul.ce-checklist,
ol.ce-checklist {
  @include list-unstyled;
  font-size: type-scale(1);
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;

  li {
    position: relative;
    padding-left: 2rem;
  }

  li:before {
    position: absolute;
    display: block;
    content: '\2022';
    text-indent: -999999px;
    left: 0;
    width: 1.5rem;
    height: 1.5rem;
    background-repeat: no-repeat;
    background-image: svg-url('<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25 25"><title>list check</title><path d="M12.5 0A12.5 12.5 0 1 0 25 12.5 12.5 12.5 0 0 0 12.5 0zm-2.1 17L7 13.6l1.5-1.4 2 2 6-6.2L18 9.4z" fill="#657676"/></svg>');
    background-size: 1.5rem;
  }
}

/**
 * File list
 */
.ce-file-list {
  margin-bottom: 1rem;

  &:last-child {
    margin-bottom: 0;
  }
}

/**
 * Map
 */
.ce-map {
  @extend %ce;

  &__frame,
  .iframe__frame {
    width: 100%;
    height: 400px;

    border: 0;
  }
}

/**
 * Walkthrough
 */
.ce-walkthrough {
  @extend %ce;
}

/**
 * Form
 */
.ce-form {
  margin-left: auto;
  margin-right: auto;

  &--small {
    max-width: 400px;
  }
}

/**
 * Table
 */
.ce-table {
  @extend %ce;

  &__list {
    @include list-unstyled;

    text-align: center;
  }

  &__list-item-title {
    display: inline-block;
    width: 100%;
    font-weight: bold;
    color: $green-gray;
  }

  &__row {
    @include clearfix;
    text-align: center;
  }

  &__row-flex {
    display: flex;
    flex-direction: row;
  }

  &__row-item {
    margin: 0 20px;
    display: inline-block;
  }
}

.ce-iframe {
  background-color: #fff;
}
