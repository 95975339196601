$search-immo-options-margin: 0 !default;
$search-immo-options-margin-at-md: 20px !default;

$search-immo-list-item-padding-top-at-md: 50px !default;
$search-immo-list-item-padding-bottom-at-md: 10px !default;
$search-immo-list-item-padding-left-at-md: 40px !default;
$search-immo-list-item-padding-right-at-md: 40px !default;

.search-immo-results {
  .search__options {
    margin-left: $search-immo-options-margin;
    margin-right: $search-immo-options-margin;
  }

  .article-list {
    .article__title {
      font-family: $font-family-special;
      font-weight: normal;
      font-size: 1.6rem;
    }
  }

  .hint-list {
    @include list-unstyled;

    padding: $search-immo-options-margin-at-md $search-immo-list-item-padding-top-at-md;

    &__item {
      color: $green-gray;
    }
  }

  // Medium+ VPs
  @include media-breakpoint-up('md') {
    .search__options {
      margin-left: $search-immo-options-margin-at-md;
      margin-right: $search-immo-options-margin-at-md;
    }

    .article-list {
      .article-list__item {
        padding-top: $search-immo-list-item-padding-top-at-md;
        padding-bottom: $search-immo-list-item-padding-bottom-at-md;
        padding-left: $search-immo-list-item-padding-left-at-md;
        padding-right: $search-immo-list-item-padding-right-at-md;
      }
    }
  }
}
