@keyframes loadingSpin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.form-input-spinner {
  position: relative;
}

.form-input-spinner--loading::after {
  content: '';
  position: absolute;
  top: 0.625rem;
  right: 0.75rem;
  width: 1rem;
  height: 1rem;
  border: 0.25rem solid $gray-light;
  border-right-color: transparent;
  border-radius: 50%;
  animation: 0.75s loadingSpin infinite linear;
}
