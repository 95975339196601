/**
 * Color presets
 */
$teaser-colors: (
  'blue-dark': (
    'color': $blue-dark,
    'button-background': $blue-dark,
    'button-background-hover': darken($blue-dark, 8%),
  ),
  'blue-strong': (
    'color': $blue-strong,
    'button-background': $blue-strong,
    'button-background-hover': darken($blue-strong, 8%),
  ),
  'blue': ( // changed because of contrast
    'color': $blue-strong,
    'button-background': $blue-strong,
    'button-background-hover': darken($blue-strong, 8%),
  ),
  'green': (
    'color': $green,
    'button-background': $green,
    'button-background-hover': darken($green, 8%),
  ),
  'red': (
    'color': $red,
    'button-background': $red,
    'button-background-hover': darken($red, 8%),
  ),
  'pink': (
    'color': $pink,
    'button-background': $pink,
    'button-background-hover': darken($pink, 8%),
  ),
  'magenta': (
    'color': $magenta,
    'button-background': $magenta,
    'button-background-hover': darken($magenta, 8%),
  ),
  'black': (
    'color': $black,
    'button-background': $black,
    'button-background-hover': darken($black, 8%),
  ),
);

@each $name, $preset in $teaser-colors {
  .teaser--#{$name} {
    .teaser__kicker {
      color: map-get($preset, 'color');
    }

    a {
      color: map-get($preset, 'color');

      &:hover,
      &:focus {
        color: darken(map-get($preset, 'color'), 10%);
      }
    }

    .symbol {
      color: map-get($preset, 'color');
    }

    .btn {
      background-color: map-get($preset, 'button-background');
      color: $white;

      &:hover,
      &:focus {
        background-color: map-get($preset, 'button-background-hover');
        color: $white;
      }

      &--ghost {
        background-color: transparent;
        color: $blue-strong;
        font-weight: bold;
        &:hover {
          background-color: transparent;
          color: $blue-strong;
        }
      }
    }
  }
}
