@import 'shared/link';

.link {
  @include link;

  &__icon {
    margin-top: -.25em;
    margin-right: .5em;

    display: inline-block;
    vertical-align: middle;
  }

  &--small {
    @include type-scale(-2);
  }
}
