/**
 * Myriad W01
 */
@font-face {
  font-family: 'Myriad W01';
  src: url('../fonts/myriad/w01-light.woff2') format('woff2'),
       url('../fonts/myriad/w01-light.woff') format('woff');
}

@font-face {
  font-family: 'Myriad W01';
  font-weight: bold;
  src: url('../fonts/myriad/w01-bold.woff2') format('woff2'),
       url('../fonts/myriad/w01-bold.woff') format('woff');
}

/**
 * FF Speak W03
 */
@font-face {
  font-family: 'FF Speak W03';
  src: url('../fonts/ff-speak/w03-regular.woff2') format('woff2'),
       url('../fonts/ff-speak/w03-regular.woff') format('woff');
}

@font-face {
  font-family: 'FF Speak W03';
  font-weight: bold;
  src: url('../fonts/ff-speak/w03-heavy.woff2') format('woff2'),
       url('../fonts/ff-speak/w03-heavy.woff') format('woff');
}
