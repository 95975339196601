/**
 * Layout
 */
[class*="teaser--graphic-"] {
  background-color: $white;

  .teaser__container {
    @include make-container-custom;
    @include make-container-max-widths;
    @include make-container-gutters;
    padding-top: ($grid-gutter-width * 2);
    padding-bottom: ($grid-gutter-width * 2);
  }

  .teaser__frame {
    // Reset defaults
    padding: 0;
    margin: 0;
    box-shadow: none;
    background: 0;
  }

  .teaser__image {
    margin-bottom: ($grid-gutter-width * 2);
  }

  @include media-breakpoint-up('md') {
    .teaser__container {
      padding-top: ($grid-gutter-width * 2);
      padding-bottom: ($grid-gutter-width * 2);
    }
  }

  @include media-breakpoint-between('md', 'lg') {
    .teaser__image {
      max-width: 600px;
      margin: 0 auto ($grid-gutter-width * 3);
    }

    .teaser__frame {
      max-width: 860px;
      margin-right: auto;
      margin-left: auto;
    }
  }
}

@include media-breakpoint-up('xl') {
  [class*="teaser--graphic-"] {
    .teaser__container {
      display: flex;
      align-items: center;

      // No-flex fallback
      @include clearfix;
    }

    .teaser__image {
      width: 50%;
      margin-right: auto;
      margin-bottom: 0;
      padding-right: $grid-gutter-width;

      // No-flex fallback
      float: left;
    }

    .teaser__frame {
      width: 50%;
      padding-left: $grid-gutter-width;
      padding-right: ($grid-gutter-width + $frame-size);

      // No-flex fallback
      float: right;
    }
  }

  .teaser--graphic-right {
    .teaser__image {
      order: 1; // Move after __frame
      margin-right: 0;
      margin-left: auto;
      padding-right: 0;
      padding-left: $grid-gutter-width;
    }

    .teaser__frame {
      padding-right: $grid-gutter-width;
      padding-left: ($grid-gutter-width + $frame-size);
    }
  }
}

/**
 * Color presets
 */
$teaser-graphic-colors: (
  'blue-dark': (
    'background': $blue-dark,
    'color': $white,
    'button-background': $white,
    'button-color': $blue-dark,
  ),
  'blue-strong': (
    'background': $blue-strong,
    'color': $white,
    'button-background': $white,
    'button-color': $blue-strong,
  ),
  'blue': (
    'background': $blue,
    'color': $black,
    'button-background': $white,
    'button-color': $black,
  ),
  'blue-soft': (
    'background': $blue-soft,
    'color': $black,
    'button-background': $white,
    'button-color': $black,
  ),
  'green-gray': (
    'background': $green-gray,
    'color': $white,
    'button-background': $white,
    'button-color': $green-gray,
  ),
  'green': (
    'background': $green,
    'color': $white,
    'button-background': $white,
    'button-color': $green,
  ),
  'green-lime': (
    'background': $green-lime,
    'color': $black,
    'button-background': $white,
    'button-color': $black,
  ),
  'yellow': (
    'background': $yellow,
    'color': $black,
    'button-background': $white,
    'button-color': $black,
  ),
  'orange': (
    'background': $orange,
    'color': $black,
    'button-background': $white,
    'button-color': $black,
  ),
  'red': (
    'background': $red,
    'color': $white,
    'button-background': $white,
    'button-color': $red,
  ),
  'pink': (
    'background': $pink,
    'color': $black,
    'button-background': $white,
    'button-color': $black,
  ),
  'magenta': (
    'background': $magenta,
    'color': $white,
    'button-background': $white,
    'button-color': $magenta,
  ),
);

@each $name, $preset in $teaser-graphic-colors {
  .teaser--graphic-#{$name} {
    color: map-get($preset, 'color');
    background-color: map-get($preset, 'background');

    .teaser__kicker {
      color: map-get($preset, 'color');
    }

    .btn {
      color: map-get($preset, 'button-color');
      background-color: map-get($preset, 'button-background');

      &:hover {
        opacity: .85;
      }
    }
  }
}
