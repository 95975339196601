.header__nav-list-wrapper--immosuche {
  @include media-breakpoint-up('lg') {
    width: auto;
    position: absolute;
    top: 71px;
    right: 1rem;
    z-index: 201;
    -webkit-box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.2);
    box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.2);
  }
  @include media-breakpoint-up('xxl') {
    top: 74px;
  }
}

.header-immosuche {
  width: 100%;
  background-color: #fff;
  padding: 60px 20px 20px 20px;

  &__col--rooms {
    width: 50%;
    float: left;
    padding-right: 10px;
  }

  &__col--qm {
    width: 50%;
    float: left;
    padding-left: 10px;
  }

  &__row--last {
    padding-top: 10px;
    clear: both;
  }

  &__row--hidden {
    display: none;
  }

  @include media-breakpoint-up('lg') {
    width: 400px;
    padding-top: 20px;
  }
}
