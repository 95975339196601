// Prevent images breaking their containers.
img {
  max-width    : 100%;
  height       : auto;
  margin-left  : auto;
  margin-right : auto;
}

.image__copyright {
  bottom    : 0;
  color     : #FFFFFF;
  max-width : 75%;
  opacity   : 1;
  position  : absolute;
  right     : 0;
  z-index   : 2;
}

.copyright {
  align-items    : flex-start;
  background     : #333333;
  color          : #FFFFFF;
  display        : flex;
  font-size      : .8rem;
  letter-spacing : -.17px;
  line-height    : 1;
}

.copyright__symbol {
  padding : .5rem .8rem;
}

.copyright__text {
  -webkit-box-decoration-break : clone;
  box-decoration-break         : clone;
  display                      : none;
  padding                      : .5rem .8rem .5rem 0;
}

.copyright:focus .copyright__text,
.copyright:hover .copyright__text {
  display : inline-flex;
}
