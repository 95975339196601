$teaser-frame-width: 640px !default;
$teaser-frame-height: 360px !default;
$teaser-element-width: 640px !default;
$teaser-element-height: 480px !default;

.teaser--element-left,
.teaser--element-right {
  @include make-container-custom;
  @include make-container-max-widths($teaser-container-max-widths);
  @include make-container-gutters($teaser-container-gutters);
  @include media-breakpoint-only('sm') {
    .teaser__frame {
      margin-top: -30%;
      margin-right: $frame-size;
      margin-left: $frame-size;
    }
  }

  @include media-breakpoint-only('md') {
    .teaser__frame {
      margin-top: -30%;
      margin-right: ($grid-gutter-width + $frame-size);
      margin-left: ($grid-gutter-width + $frame-size);
    }
  }

  @include media-breakpoint-down('xl') {
    .teaser__frame--poll {
      margin-top: $frame-size;
    }
  }
}

@include media-breakpoint-up('lg') {
  .teaser--element-left,
  .teaser--element-right {
    .teaser__container {
      @include clearfix;
      position: relative;
      min-height: $teaser-element-height;
    }

    .teaser__element {
      width: $teaser-element-width;
    }

    .teaser__frame {
      position: absolute;
      top: 50%;
      right: $frame-size;
      right: 0;
      width: $teaser-frame-width;
      min-height: $teaser-frame-height;
      transform: translateY(-50%);
    }
  }

  .teaser--element-right {
    .teaser__element {
      float: right;
    }

    .teaser__frame {
      right: auto;
      left: $frame-size;
      left: 0;
    }
  }
}

.teaser--element-poll {
  @include media-breakpoint-down('xl') {
    .teaser__element {
      position: relative;
      float: none;
      left: auto;
      right: auto;
      width: auto;
    }
    .teaser__frame {
      position: relative;
      float: none;
      margin-right: 0;
      margin-left: 0;
      left: 50%;
      right: auto;
      top: auto;
      transform: translateX(-50%);
      width: auto;
      min-height: auto;
      max-width: 640px;
    }
  }
}

.teaser--element-map-left,
.teaser--element-map-right {
  @include make-container-custom;
  @include make-container-max-widths($teaser-container-max-widths);
  @include make-container-gutters($teaser-container-gutters);

  @include media-breakpoint-between('sm', 'xlg') {
    .teaser__frame {
      margin-top: -10%;
      margin-right: ($grid-gutter-width + $frame-size);
      margin-left: ($grid-gutter-width + $frame-size);
    }
  }
}

.teaser--element-map-expose {

  .no-js & {
    display: none;
  }

  @include make-container-custom;
  @include make-container-max-widths($teaser-container-max-widths);
  @include make-container-gutters($teaser-container-gutters);

  @include media-breakpoint-up('sm') {
    .teaser__frame {
      margin-top: -10%;
      margin-right: ($grid-gutter-width + $frame-size);
      margin-left: ($grid-gutter-width + $frame-size);
    }
  }
}

@include media-breakpoint-up('xl') {
  .teaser--element-map-left,
  .teaser--element-map-right,
  .teaser--element-poll-left,
  .teaser--element-poll-right{
    .teaser__container {
      @include clearfix;
      position: relative;
      min-height: $teaser-element-height;
    }

    .teaser__element {
      width: $teaser-element-width;
    }

    .teaser__frame {
      position: absolute;
      top: 50%;
      right: $frame-size;
      right: 0;
      width: $teaser-frame-width;
      min-height: $teaser-frame-height;
      transform: translateY(-50%);
    }
  }

  .teaser--element-map-right {
    .teaser__element {
      float: right;
    }

    .teaser__frame {
      right: auto;
      left: $frame-size;
      left: 0;
    }
  }
}
