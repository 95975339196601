.audio-player {

  &__controls {
    @include list-unstyled;
    line-height: 1;
    display: flex;
    flex-direction: row;
  }

  &__control-item {
    margin-right: 1rem;
  }

  a {
    color: $green-gray;
    &:hover {
      color: $black;
    }
  }

  &__control-item--pause {
    display: none;
  }

  .no-js &__control-item--play,
  .no-js &__control-item--pause {
    display: none;
  }

  &__time {
    display: none;
  }

  &__progress {
    cursor: pointer;
  }

  &__progress[value] {
    /* Reset the default appearance */
    -webkit-appearance: none;
    appearance: none;
    border: none;
    width: 100%;
    height: 5px;
    line-height: 1;
    margin-bottom: -6px;
  }

  &__progress[value]::-webkit-progress-bar {
    background-color: $gray;
  }

  &__progress[value]::-webkit-progress-value {
    background-color: rgba($green-gray, 1);
  }
}
