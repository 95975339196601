.teaser-audio-player {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;

  &__controls {
    margin: 0 $teaser-spacing;
  }

  @include media-breakpoint-up('md') {
    &__controls {
      margin: 0 $teaser-spacing-at-md;
    }
  }

  @include media-breakpoint-up('xl') {
    &__controls {
      margin: 0 $teaser-spacing-at-xl;
    }
  }
}
