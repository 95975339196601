$neubauinfo-left-column-width-at-lg: 500px !default;

.neubauinfo {
  position: relative;

  &__item {
    margin: 20px 0;

    @include clearfix;
  }

  &__col {
    &--left {
      width: 100%;
      margin: 20px 0;
      float: left;
    }
  }

  &__list {
    @include list-unstyled;
    padding-left: 0;
    margin: 0 20px;
    font-size: 0.9375rem;
  }

  &__list-item {
    margin-bottom: 1rem;
  }

  &__list-data {
    color: $green-gray;
    @include type-scale(-1);
    font-weight: bold;
    text-transform: uppercase;
  }

  @include media-breakpoint-up('sm') {
    &__list {
      margin: 0;
    }
  }

  // Large+ VPs
  @include media-breakpoint-up('lg') {
    &__image {
      max-width: $neubauinfo-left-column-width-at-lg;
    }

    &__col {
      &--left {
        width: $neubauinfo-left-column-width-at-lg;
        margin: 0;
      }

      &--right {
        margin-left: 40px;
        float: left;

        // @todo Quickfix for breaking layout when list-value is too long
        max-width: calc(100% - #{$neubauinfo-left-column-width-at-lg + 40px});
      }
    }
  }

  // X-Large+ VPs
  @include media-breakpoint-up('xl') {

    &__list-item {
      position: relative;
    }

    &__list-data {
      display: inline-block;
      left: 0;
      position: absolute;
      text-align: left;
      width: 190px;
    }

    &__list-value {
      margin-left: 190px;

       // @todo Quickfix for breaking layout list-value is empty
      &:empty:after {
        content: '\00a0';
      }
    }
  }
}
