.teaser--nav-list {
  position: relative;
  padding-bottom: ($grid-gutter-width + $frame-size);

  .teaser__frame {
    position: absolute;
    right: ($grid-gutter-width + $frame-size);
    bottom: $frame-size;
    left: ($grid-gutter-width + $frame-size);
    margin-bottom: 0;
    padding: $teaser-spacing-at-md;
  }

  .teaser__lead {
    @include type-scale(0);
  }
}
