.print-hint {
  text-align: center;
  font-style: italic;
  display: none;
}

@media print {
  html, body {
    height: 99%;
    max-width: 700px;
    font-size: 13px;
    background-color: #fff;
  }

  .print-logo,
  .print-hint {
    display: block;
  }

  a[href]:after{
      content: " (" attr(href) ") ";
      color: $green-gray;
  }

  .header,
  .js-slider,
  .js-carousel,
  .js-video,
  .ce-video,
  .js-webcam,
  .ce-webcam,
  .js-gallery,
  .ce-gallery,
  .teaser,
  .article-footer,
  .social-footer,
  .footer,
  .feedback-storer,
  .feedback-storer.active {
    display: none;
  }

  img,
  figure {
      page-break-before: auto; /* 'always,' 'avoid,' 'left,' 'inherit,' or 'right' */
      page-break-after: auto; /* 'always,' 'avoid,' 'left,' 'inherit,' or 'right' */
      page-break-inside: avoid; /* or 'auto' */
  }

  .article {
    &__hero {
      display: none;
    }
    &__hero + &__container {
      margin-top: 20px;
    }
    &__container {
      padding-left: 20px;
      padding-right: 20px;
      width: 100%;
      max-width: 100%;
    }
    &__frame {
      box-shadow: none;
      padding: 0;
    }
    &__header,
    &__body {
      width: 100%;
      max-width: 100%;
      padding-left: 0;
      padding-right: 0;
    }
    .ce-quote__cite {
      position: relative;
      width: auto;
      text-align: left;
    }
    .ce-figure--left {
      position: relative;
      left: auto;
      text-align: left;
      max-width: 300px;
    }
    .ce-figure--right {
      position: relative;
      right: auto;
      text-align: right;
      max-width: 300px;
    }

    .js-gallery,
    .ce-gallery {
      display: block !important;
      margin-bottom: 2rem !important;
      margin-left: 0 !important;
      margin-right: 0 !important;

      .slick-list {
        height: auto !important;
      }
      .slick-track {
        width: 100% !important;
      }
      .slick-slide {
        width: 48.75% !important;
        height: auto;
        opacity: 1 !important;
        left: 2.5% !important;
        &:nth-child(odd) {
          left: 0 !important;
          clear: both;
        }

        page-break-before: auto; /* 'always,' 'avoid,' 'left,' 'inherit,' or 'right' */
        page-break-after: auto; /* 'always,' 'avoid,' 'left,' 'inherit,' or 'right' */
        page-break-inside: avoid; /* or 'auto' */
      }
      .gallery__main-image {
        width: 100% !important;
        height: auto !important;
        min-height: auto !important;
        float: none !important;
        div[class^="ratio-"] {
          height: 245px !important;
          img {
            max-height: 245px !important;
          }
        }
      }
      .gallery__info {
        width: 100% !important;
        padding: 0 !important;
        margin: 0 !important;
        margin-top: 1rem !important;
      }
      .dot-nav {
        display: none !important;
      }
      .gallery__thumbs {
        display: none !important;
      }
      .gallery__nav {
        display: none !important;
      }
    }
  }
}
