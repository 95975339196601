.webcam {

  &__container {
    position: relative;

    &--rendered {
      padding-bottom: 56.25%;
    }
  }

  &__frame {
    width: 100%;
    height: 100%;
    background-color: #000;

    position: absolute;
    border: 0;
  }

  .no-js & .teaser__icon {
    display: none;
  }
}
