.file-list {
  margin: 0;
  padding: 0;
}

.file-list__item {
  display: flex;
  margin: 0;
  line-height: 1.1;

  &:not(:last-child) {
    margin-bottom: 1rem;
  }
}

.file-list__desc {
  min-width: 0; // needed to allow 'word-wrap: break-word' for flex items
  word-wrap: break-word;
}

.file-list__icon {
  display: flex;
  flex-shrink: 0;
  flex-direction: column;
  margin-right: 0.5rem;
  font-size: 2rem;

  @include media-breakpoint-up('sm') {
    margin-right: 0.75rem;
    font-size: 3.75rem;
  }

  .symbol {
    line-height: 1;
  }
}

.file-list__header {
  display: flex;
}

.file-list__name {
  @include type-scale(0);

  min-width: 0; // needed to allow 'word-wrap: break-word' for flex items
  margin-bottom: 0.3rem;
  line-height: 1.1;

  &:not(:last-child) {
    margin-right: 0.5rem;
  }
}

.file-list__badge {
  flex-shrink: 0;
  margin-top: -0.25rem;
}

.file-list__alert {
  @include type-scale(-1);

  margin-bottom: 0.3rem;
  margin-top: 0.3rem;
  color: $gray-dark;
  font-weight: bold;
  font-style: italic;
}

.file-list__alert-desc {
  @include type-scale(-2);

  margin-bottom: 0.3rem;
  color: $gray-dark;
}
