.form-label {
  @include type-scale(-1);
  display: block;
  margin-bottom: ($spacer / 4);
  text-transform: uppercase;
  letter-spacing: .015em;
  font-weight: bold;
  color: $green-gray;

  .has-danger & {
    color: $brand-danger;
  }

  .has-success & {
    color: $brand-success;
  }

  &--row {
    display: inline-block;
    margin-right: $spacer * 1.5;
    margin-bottom: 0;
  }

  &--filter {
    display: inline-block;
    margin-right: $spacer * 2;
  }

  &--light,
  &--light-inline {
    font-weight: normal;
  }

  &--light-inline {
    display: inline-block;
    color: $black;
    text-transform: none;
    margin-right: 5px;
  }
}

.form-label--hidden {
  display: none;
}

.form-group--disabled .form-label {
  color: $gray;
}
