$btn-letter-spacing: 0.04rem;

.btn {
  @include type-scale(1);
  display: inline-block;
  vertical-align: middle;
  padding: .5em .6em .4em;
  line-height: 1.15;
  letter-spacing: $btn-letter-spacing;
  background-color: $green-gray;
  color: $white;
  font-weight: normal;
  cursor: pointer;
  border: 0;

  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }

  &:hover,
  &:focus,
  .active-overlay & {
    color: #fff;
    background-color: darken($green-gray, 12%);
  }

  // Icon aligmnent
  &__icon,
  &__text {
    display: inline-block;
    vertical-align: middle;
  }
  &__icon {
    margin-top: -.15em;
    margin-right: .5em;
  }

  // Viewport dependent texts
  &__text--long {
    @include media-breakpoint-down('sm') {
      display: none;
    }
  }
  &__text--short {
    @include media-breakpoint-up('md') {
      display: none;
    }
  }

  // Block variant
  &__text--center {
    text-align: center;
  }

  // Block variant
  &--block {
    display: block;
    text-align: center;
  }

  &--header-events {
    margin-top: 1.5rem;
  }

  // Primary variant
  &--prim {
    background-color: $brand-primary;

    &:hover,
    &:focus,
    .active-overlay & {
      background-color: darken($brand-primary, 8%);
      outline-offset: 2px;
    }
  }

  // Blue variant
  &--blue {
    background-color: $blue-strong;

    &:hover,
    &:focus,
    .active-overlay & {
      background-color: darken($blue, 8%);
    }
  }

  // Red variant
  &--red {
    background-color: $red;

    &:hover,
    &:focus
    .active-overlay & {
      background-color: darken($red, 8%);
    }
  }

  // White variant
  &--white {
    color: $brand-primary;
    background-color: $white;

    &:hover,
    &:focus,
    .active-overlay & {
      color: darken($brand-primary, 8%);
      background-color: $white;
    }
  }

  // Transparent variant
  &--trans {
    border: 1px solid $green-gray;
    background-color: transparent;
    padding: .4em .6em .3em;
    color: darken($green-gray, 12%);

    &:hover,
    &:focus,
    .active-overlay & {
      border-color: darken($green-gray, 12%);
    }
  }

  // Ghost variant
  &--ghost {
    border: 1px transparent;
    background-color: transparent;
    padding: .4em .6em .3em;
    color: darken($green-gray, 12%);

    &-small {
      @include type-scale(-1);
    }

    &:hover,
    &:focus,
    .active-overlay & {
      border: 1px transparent;
      background-color: transparent;
      color: $brand-primary;
    }
  }

  &--inline {
    margin-bottom: 20px;

    @include media-breakpoint-up('md') {
      margin-bottom: 0;
      margin-right: 20px;
    }
  }

  // Large variant
  &--lg {
    padding: .85em 1em .75em;
  }

  &--immosearch {
    padding: .95em 2em .85em;
  }

  &--loading {
    background-image: url(../images/loading.svg);
    background-size: 15px;
    background-repeat: no-repeat;
    background-position: 10px;
  }
}
