$tileset-gutter-width: 10px !default;
$tileset-table-item-width: 250px !default;

.teaser-tileset {
  @include clearfix;
  @include make-container-custom;
  @include make-container-max-widths($container-md-max-widths);

  &__tile {
    margin: 0 0 ($tileset-gutter-width*2) 0;
    padding: $grid-gutter-width;

    color: $black;
    background-color: $white;

    overflow: hidden;
  }

  &__tile--form {
    position: relative;
  }

  &__tile--prim {
    background-color: $brand-primary;
    color: $white;

    a.btn {
      background-color: $white;
      color: $black;
    }
  }

  &--form {

    .js & {
      display: none;
    }

    &.open {
      display: block;
    }
  }

  &__tile--close {

    .no-js & {
      display: none;
    }

    position: absolute;
    top: ($tileset-gutter-width * 2);
    right: ($tileset-gutter-width * 2);

    padding-right: 1.5rem;
    height: 1rem;

    background-image: svg-url('<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 11 11"><polygon points="6.9 5.5 11 1.4 9.6 0 5.5 4.1 1.4 0 0 1.4 4.1 5.5 0 9.6 1.4 11 5.5 6.9 9.6 11 11 9.6 6.9 5.5" fill-rule="evenodd"/></svg>');
    background-repeat: no-repeat;
    background-size: 1rem;
    background-position: center right;

    span {
      display: none;
    }
  }

  &__header {
    margin-bottom: 2rem;
    text-align: center;
  }

  &__functions {
    margin-bottom: 1rem;
    text-align: center;
  }

  &__title {
    @include type-scale(3);

    font-family: $font-family-special;
    font-weight: normal;

    margin-top: 1rem;
    margin-bottom: 1rem;
  }

  &__table {
    margin-left: auto;
    margin-right: auto;

    &-row {
      border-bottom: 10px solid $white;
    }

    &-item {
      width: $tileset-table-item-width;

      &:first-child {
        padding-right: 2rem;

        text-align: right;
        text-transform: uppercase;

        color: $green-gray;
        font-weight: bold;
        @include type-scale(-1);

        hyphenate-limit-chars: auto 5;
        hyphenate-limit-lines: 2;
      }
    }
  }

  // Layout
  @include media-breakpoint-up('md') {
    @include make-container-gutter(map-get($container-gutters, 'md'));

    &__body--form,
    &__header--form {
      margin-left: auto;
      margin-right: auto;
      max-width: 480px;
    }

    &__tile--close {
      height: auto;

      span {
        display: inline;
      }
    }

  }

  @include media-breakpoint-up('lg') {
    display: flex;

    &__col-1,
    &__col-2 {
      @include make-col(6);
      display: flex;
      flex-direction: column;
    }

    &__title {
      @include type-scale(3);
    }

    &__col-1 {
      padding-left: 0;
      padding-right: $tileset-gutter-width;
    }

    &__col-2 {
      padding-left: $tileset-gutter-width;
      padding-right: 0;
    }

    &__tile {
      padding: $grid-gutter-width;

      flex-grow: 1;
    }

    &__header--form {
      text-align: left;
      margin-bottom: 2rem;
    }
  }
}
