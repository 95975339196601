.link-group {
  @include list-unstyled;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  &__item {
    padding: ($grid-gutter-width / 4) ($grid-gutter-width / 2);
  }

  @include media-breakpoint-up('md') {
    &__item {
      padding: ($grid-gutter-width / 4) $grid-gutter-width;
    }

    &__item--narrow {
      padding: ($grid-gutter-width / 4) ($grid-gutter-width / 2);
    }
  }
}

.tile-link-group {
  justify-content: space-between;
  padding-left: $grid-gutter-width;
  padding-right: $grid-gutter-width;

  &__item {
    padding: 0;
    padding-top: $grid-gutter-width / 2;
  }
  .social-link {
    &:before {
      content: "";
    }
  }
}
