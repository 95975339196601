.participation-grid {
  display: grid;
  grid-template-columns: 1fr;
  gap: 0.5rem;

  @include media-breakpoint-up('md') {
    grid-template-columns: repeat(2, 1fr);
  }
}

.participation-grid__headline {
  font-size: 32px;
}

.participation-grid__tile {
  padding: 2rem;
  color: $brand-primary;
  font-size: 15px;
}

.participation-grid__tile--light {
  background-color: #e2e5eb;

  &.participation-grid__tile--link:hover {
    background-color: rgba(#e2e5eb, 0.5);
  }
}

.participation-grid__tile--primary {
  background-color: rgba($brand-primary, 0.5);

  &.participation-grid__tile--link:hover {
    background-color: rgba($brand-primary, 0.25);
  }
}

.participation-grid__tile--secondary {
  background-color: #63bfb5;

  &.participation-grid__tile--link:hover {
    background-color: rgba(#63bfb5, 0.75);
  }
}

.participation-grid__title {
  font-size: 24px;

  &:focus,
  &:hover {
    color: inherit;
  }
}

.participation-grid__text {
  font-family: "Myriad Pro", "Trebuchet MS", $font-family-base;
  margin-bottom: 0;
}
