.expose {
  &__header-form-group {
    text-align: center;
  }

  &__header-details {
    padding-top: 2rem;
    border-top: 1px solid $gray;
  }

  &__header-functions {
    margin-top: 1rem;
    display: none;
  }

  &__footer-functions {
    margin-top: 0;
    margin-bottom: 4rem;
  }

  &__meta {
    @include type-scale(-1);
    display: inline-block;
    color: $black;
    text-transform: uppercase;
    font-weight: bold;
    padding-left: 20px;

    background-image: svg-url('<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 55 80"><path d="M27.5,0C16.5,0,0,5,0,25,0,35,22,70,27.5,80,33,70,55,35,55,25,55,5,38.51,0,27.5,0Zm0,33.75A8.75,8.75,0,1,1,36.25,25,8.75,8.75,0,0,1,27.5,33.75Z" fill="#000000"/></svg>');
    background-repeat: no-repeat;
    background-size: 0.75rem;
    background-position: center left;
  }

  &__price-tag {
    font-size: 3rem;

    position: relative;

    color: $brand-primary;
    font-weight: normal;

    .text {
      @include type-scale(0);

      margin-left: 0.5rem;
      font-weight: normal;
    }
  }

  @include media-breakpoint-up('md') {
    &__header-functions {
      display: block;
    }

    &__meta {
      margin-bottom: 2rem;
    }
  }
}
