.print-extended {
  font-size: 12px;

  .article__container {
    margin-top: 0;
    margin-bottom: 0;
    background-color: #fff;
  }

  .article__header {
    margin-bottom: 1.5rem;
  }

  .article__title {
    margin: 0;
  }

  .expose__meta {
    margin-top: 1rem;
    margin-bottom: 0;
  }

  .expose__header-details {
    margin-bottom: 1rem;
    padding-top: 1rem;
  }

  .teaser-tileset__table--desc {
    width: auto;
    margin-right: 50px;
  }

  .teaser-tileset__body--details {
    float: left;
    width: 50%;
  }

  .teaser-tileset__header {
    margin-bottom: 1rem;
  }

  .teaser-tileset__title {
    font-size: 2rem;
    margin-top: 0;
    margin-bottom: 0.5rem;
  }

  .teaser-tileset__table-row {
    border-bottom: 0;
  }

  .teaser-tileset__table--desc .teaser-tileset__table-row {
    border-bottom: 10px solid transparent;
  }

  td {
    vertical-align: top;
  }

  .teaser-tileset__table-item {
    width: auto;
    padding-left: 1rem;
  }

  .teaser-tileset__table-item:first-child {
    padding-left: 0;
    padding-right: 1rem;
  }

  .map-container {
    display: inline-block;
    width: 100%;
    height: auto;
    margin-bottom: 1rem;
  }

  .map-container img {
    width: 100%;
  }

  .plan-container {
    width: 100%;
    padding: 10px;
    text-align: center;
  }

  .plan-container img {
    margin-left: auto;
    margin-right: auto;
    max-width: 100%;
  }

  .qr-container {
    text-align: center;
  }

  .ansicht-container {
    float: left;
    display: inline-block;
    width: 33%;
    padding: 10px;
  }

  .ansicht-container img {
    max-width: 100%;
  }

}

.print {
  font-size: 14px;

  .logo-print {
    max-width: 100%;
  }

  .article__kicker {
    font-size: 1rem;
    margin-bottom: 0.5rem;
  }

  .article__kicker--title {
    font-size: 1rem;
    margin-bottom: 1rem;
    margin-top: 0;
  }

  .article__title {
    font-size: 2rem;
    margin-right: 20px;
  }

  .article__title2 {
    font-size: 1.5rem;
    margin-right: 20px;
    margin-bottom: 1rem;
  }

  .article__subtitle {
    font-weight: bold;
    margin-top: 1rem;
  }

  table {
    width: 100%;
    // font-size: 0.857rem;
    border-top: 1px solid $gray;
    margin-bottom: 0.5rem;
  }

  table * table {
    border-top: 0;
  }

  td {
    padding: 0 20px 0 0;
  }

  td.desc {
    width: 200px;
  }

  table.table--top {
    border: 0;
    margin-top: 0;
    margin-bottom: 2rem;
    table-layout: fixed; // Fixes IE issues with images breaking out of their container
  }

  .expose__price-tag--print {
    @include type-scale(1);

    margin-top: 0.5rem;

    .text {
      margin-left: 0;
    }
  }

  .expose-image {
    max-width: 100%;
    max-height: 160px;
  }

  .plan-container {
    width: 100%;
    max-height: 100%;
    padding: 10px;
    text-align: center;
  }

  .qr-container {
    margin-bottom: 1rem;
    text-align: center;

    img {
      width: 2cm;
    }
  }

  .map-container {
    display: inline-block;
    width: 100%;
    height: auto;
    margin-bottom: 3rem;
    text-align: center;
  }

  .map-container img {
    width: 100%;
  }

  .ansicht-container {
    float: left;
    display: inline-block;
    width: 33%;
    padding: 10px;
  }

  .ansicht-container img {
    max-width: 100%;
  }

}
