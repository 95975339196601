.form-row {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  margin: ($grid-gutter-width / -2);

  &--divider {
    margin-top: $grid-gutter-width * 1.5;
    padding-top: $grid-gutter-width / 2;
    border-top: 1px solid $gray-lighter;
    &:first-child {
      border-top: 0;
    }
  }

  &__item {
    flex: 2 0 14rem;
    padding: ($grid-gutter-width / 2);
  }

  &__item--smm {
    flex: 1 0 12rem;
  }

  &__item--sm {
    flex: 1 0 10rem;
  }

  &__item--xs {
    width: 7rem;
    flex: none;
  }

  &__item--inline {
    // width: auto;
    flex: none;
    padding-right: 0;
  }

  &__item--align-top {
    align-self: flex-start;

    &:not(:first-child) {
      margin-top: -20px;
    }
  }

  @include media-breakpoint-up('md') {
    &__item--align-top {
      &:not(:first-child) {
        margin-top: 0;
      }
    }
  }
}
