.form-suggestions {
  @include type-scale(-2);
  left: 0;
  position: absolute;
  right: 0;
  top: 100%;
  z-index: 2;
  padding-top: 0.75rem;
  padding-bottom: 0.5rem;
  box-shadow: 0 0 2px 2px $frame-color;
  background-color: #fff;
}

.form-suggestions__title {
  @include type-scale(-1);
  margin-bottom: 0.25rem;
  padding: 0 0.75rem;
  font-weight: bold;
  line-height: 1.4;
}

.form-suggestions__list {
  @include list-unstyled;
  margin: 0;
}

.form-suggestions__item {
  padding: 0.25rem 0.75rem;
  background-color: #fff;
  cursor: pointer;
  transition: 0.1s background-color;

  &:not(:last-child) {
    border-bottom: 1px solid $gray-lighter;
  }

  &:hover {
    background-color: $gray-lighter;
  }
}

.form-suggestions__message {
  @include type-scale(-1);
  margin: 0;
  padding: 0 0.75rem;
  line-height: 1.4;
}
