.form-reset {
  padding: 2px 10px;
  margin: 5px 0;

  color: $gray-dark;
  background-color: $gray-lightest;

  &--hidden {
    display: none;
  }
}
