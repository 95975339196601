.pac {
  &-container {
    box-shadow: 0 0 2px 2px $frame-color;

    &:after {
      margin: 5px;
    }
  }

  &-item {
    &:hover {
      cursor: pointer;
    }

    // Only display a maximum of 3 items
    &:nth-child(n+4) {
      display: none;
    }
  }
}
