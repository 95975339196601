/**
 * Force aspect ratios to elements. Based on this technique:
 * mademyday.de/css-height-equals-width-with-pure-css.html
 */

$ratio-z-index: 1 !default; // @todo

.ratio-edison,
.ratio-portrait,
.ratio-portrait-career-1,
.ratio-portrait-career-2,
.ratio-wide {
  display: block;
  position: relative;

  &:after {
    content: '';
    display: block;
    height: 0;
    width: 100%;
  }

  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: $ratio-z-index;
  }
}

// 4:3
.ratio-edison:after {
  padding-bottom: 75%;
}

// 2:3
.ratio-portrait:after {
  padding-bottom: 150%;
}

// ?:?
.ratio-portrait-career-1:after {
  padding-bottom: 125%;
}

// ?:?
.ratio-portrait-career-2:after {
  padding-bottom: 290.5%;
}

// 16:9
.ratio-wide:after {
  padding-bottom: 56.25%;
}

img[data-sizes="auto"] { display: block; }

// Flex
// Needs padding-bottom via inline style
.ratio-flex {
  display: block;
  position: relative;
  height: 0;

  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
  }
}

.ratio-unknown {
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    max-width: 100%;
    max-height: 100%;
    width: auto;
    height: auto;
  }

}

.ratio-unknown-w {
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    width: 100%;
    height: auto;
  }

}

.ratio-unknown-h {
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    height: 100%;
    width: auto;
  }

}

// 16:9 with a background. Targets every descendant child, not just images.
.ratio-box {
  display: block;
  position: relative;
  background-color: #fff;

  &::after {
    content: '';
    display: block;
    height: 0;
    padding-bottom: percentage(9 / 16);
  }

  > * {
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    z-index: $ratio-z-index;
  }
}
