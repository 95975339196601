$teaser-spacing: 20px !default;
$teaser-spacing-at-md: 25px !default;
$teaser-spacing-at-xl: 35px !default;
$teaser-item-spacing: 2rem !default;
$teaser-item-spacing-sm: 1rem !default;
$teaser-item-spacing-md: 1.5rem !default;

.teaser {
  position: relative;

  &__image {
    margin-bottom: 0;

    a {
      transition: opacity .1s;

      img {
        -webkit-backface-visibility: hidden;
      }

      &:hover img {
        opacity: .9;
      }
    }

    &--narrow {
      display: none;
    }
  }

  &.active-overlay &__image img {
    opacity: .9;
  }

  &__narrow {
    color: $black;
    background-color: $white;
  }

  .teaser__narrow-title,
  .teaser__narrow-content {
    padding: $frame-size;
    margin: 0;
    color: inherit;
    z-index: 100;
  }

  .teaser__narrow-title {
    padding-bottom: 0;
    color: inherit;
    a {
      display: block;
    }
  }

  .teaser__narrow-content {
    padding-top: 0;
  }

  &__frame {
    @include break-word;
    position: relative;
    display: flex;
    flex-direction: column;
    padding: $teaser-spacing;
    background-color: $white;
    box-shadow: 0 0 0 $frame-size $frame-color;
    margin-bottom: $frame-size;
    z-index: 1;

    &--podcast {
      padding-bottom: $teaser-spacing + 50px;
    }

    @include media-breakpoint-up('md') {
      padding: $teaser-spacing-at-md;
      &--podcast {
        padding-bottom: $teaser-spacing-at-md + 40px;
      }
    }

    @include media-breakpoint-up('xl') {
      padding: $teaser-spacing-at-xl;
      &--podcast {
        padding-bottom: $teaser-spacing-at-xl + 40px;
      }
    }

    >:last-child {
      margin-bottom: 0;
    }

    &--newsletter {
      box-shadow: unset;
      background-color: transparent;
    }

    &--transparent {
      @include media-breakpoint-up('xl') {
        color: $white;
        background: none;
        box-shadow: unset;
      }
    }

    &--darktext {
      @include media-breakpoint-up('xl') {
        color: $black;
      }
    }

    &--center,
    &--newsletter,
    &--main {
      text-align: center;
    }
  }

  &__kicker,
  &__meta {
    @include type-scale(-1);
    display: block;
    color: $green-gray;
    text-transform: uppercase;
    font-weight: bold;
  }

  &__kicker {
    display: block;
    margin-bottom: $teaser-item-spacing-sm;
  }

  &__kicker--newsletter {
    margin-bottom: $teaser-item-spacing-sm / 2;
  }

  &__datetime {
    display: block;
    margin-bottom: 0.5rem;
  }

  &__title {
    @include type-scale(3);
    margin-bottom: $teaser-item-spacing-sm;
  }

  &__title--small {
    font-size: 2rem;
    margin-bottom: $teaser-item-spacing-sm;
  }

  &__title--newsletter {
    font-family: $font-family-special;
    font-size: 3rem;
    line-height: 1;
    margin-bottom: $teaser-item-spacing-sm * 2;
  }

  &__kicker,
  &__title {
    a {
      color: inherit;
      display: block;

      &:hover {
        color: $link-color;
      }
    }
  }

  &.active-overlay .teaser__title a,
  &.active-overlay .teaser__kicker a {
    color: $link-color;
  }

  &__lead,
  &__body {
    margin-bottom: $teaser-item-spacing;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__body {
    ul.teaser__link-list {
      padding-left: 0;
      @include type-scale(-1);
      font-weight: bold;
      text-transform: uppercase;
      li {
        padding-bottom: 0.5rem;
      }
      a {
        &:before {
          content: '»';
          margin-right: 0.4em;
        }
      }
    }
  }

  &__body--main {
    margin-bottom: $teaser-item-spacing-sm;
  }

  &__body--compact {
    margin-bottom: $teaser-item-spacing-md;

    > * {
      margin-bottom: $teaser-item-spacing-sm;
    }

    &:last-child,
    > :last-child {
      margin-bottom: 0;
    }
  }

  // Push actions to the bottom
  // (parent must be a flex container)
  &__actions {
    margin-top: auto;
    margin-bottom: 0;
  }

  &__icon {
    width: 70px;
    height: 70px;

    margin: auto;

    border: 0;
    border-radius: 0;

    background-color: rgba(255, 255, 255, 0.4);
    box-shadow: 0 0 10px 3px rgba(0, 0, 0, 0.1);

    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;

    z-index: 100;

    &:hover {
      background-color: rgba(255, 255, 255, 0.6);
    }

    .symbol-link {
      width: 100%;
      height: 100%;

      color: $white;
      display: block;

      .symbol {
        margin: 15px 0;
      }
    }

    &--video-link {
      background-color: rgba(255, 255, 255, 0.85);

      .symbol {
        height: 40px;
        width: 73px;
      }

      &:hover,
      &:focus {
        background-color: rgba(255, 255, 255, 0.6);
      }
    }
  }

  &__link-list {
    @include list-unstyled;

    &--item {
      margin: 0 10px;
      display: inline;
    }
  }

  @include media-breakpoint-up('md') {
    &__title--newsletter,
    &__title--main {
      font-size: 3.750rem;
      margin-bottom: $teaser-item-spacing-sm * 2;
    }

    &__title--main {
      margin-bottom: $teaser-item-spacing-sm;
    }
  }

  @include media-breakpoint-up('lg') {
    &__image {
      &--narrow {
        display: block;
      }
    }
    .teaser__narrow-title,
    .teaser__narrow-content {
      color: $white;
      padding: ($grid-gutter-width + $frame-size) ($grid-gutter-width + ($frame-size / 2));
      margin: 0;
    }
    .teaser__narrow-title {
      position: absolute;
      top: 0;
      left: 0;
      .teaser__title {
        font-size: 2.5rem;
        line-height: 1.1;
      }
    }
    .teaser__narrow-content {
      position: absolute;
      bottom: 0;
      left: 0;
      .teaser__lead {
        font-size: 1.15rem;
      }
    }
  }

  @include media-breakpoint-up('xl') {
    &__frame--main {
      text-align: left;
    }
    &__body--main {
      margin-bottom: $teaser-item-spacing * 2;
    }
    &__main-text {
      p {
        @include type-scale(1);
      }
    }
  }
}
