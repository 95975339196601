.badge {
  @include type-scale(-1);

  display: inline-block;
  background-color: $blue-strong;
  border-radius: 0.75rem;
  color: #fff;
  line-height: 1;
  padding: 0.35rem 0.65rem 0.25rem;
  text-transform: uppercase;
}

.badge--inherit {
  background-color: #fff;
  color: currentColor;
}
