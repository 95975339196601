.teaser-webcam {
  @include make-container-custom;
  @include make-container-gutters($teaser-container-gutters);

  &__footer {
    color: $gray;

    @include type-scale(-1);
  }
}
