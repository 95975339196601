$plyr-control-icon-size-large: 30px;
$plyr-control-icon-size-large-at-sm: 40px; // Custom variable
$plyr-color-main: $blue;
$plyr-audio-control-color: $blue;

@import 'plyr/src/sass/plyr';

.plyr__control--overlaid {
  border-radius: 0;
  padding: 8px;
  background-color: rgba(#fff, 0.85);

  &:focus,
  &:hover {
    background-color: rgba(#fff, 0.6);
  }

  svg {
    fill: $green-gray;
  }

  @include media-breakpoint-up('sm') {
    padding: 15px;

    svg {
      width: $plyr-control-icon-size-large-at-sm;
      height: $plyr-control-icon-size-large-at-sm;
    }
  }
}

// Hide controls until first 'play' action
.plyr:not(.plyr--activated) .plyr__controls {
  display: none;
}

// Hide poster overlay to make video accessible on Safari (iOS)
.plyr__poster {
    display: none;
}
