/**
 * A fixed positioned block with legal information
 */

$cookie-msg-z-index: 10000 !default; // @todo

.cookie-msg {
  padding: 40px 0;
  z-index: $cookie-msg-z-index;
  background-color: $blue-dark;
  color: #fff;
  border-top: 1px solid darken($blue-dark, 5%);
  max-height: 100vh;
  overflow-x: auto;

  &.is-fixed {
    position: fixed;
    position: sticky;
    bottom: 0;
    width: 100%;
  }

  :focus {
      outline-color: $white !important;
  }
}

.cookie-msg__inner {
  overflow: auto;
}

.cookie-msg__title {
  font-size: 1rem;
  font-weight: bold;
  letter-spacing: 0.02rem;
}

.cookie-msg__text {
  @include type-scale(-1);
  margin-bottom: 2rem;

  .cookie-msg__sub &,
  .accordion__content-inner & {
    margin-bottom: 1rem;
  }
}

.cookie-msg__link {
  color: #fff;
  text-decoration: underline;

  &:hover,
  &:focus {
    color: #fff;
    text-decoration: none;
  }
}

.cookie-msg__actions {
  display: flex;
  gap: 1rem;
  padding-top: 1rem;

  .btn {
    // min-width: 8rem;
    // max-width: 8rem;
    // width: 100%;
  }

  >:last-child {
    margin-bottom: 0;
  }
}

.cookie-msg__form {
  display: flex;
  flex-direction: column;

  .form-toggle__label {
    color: #fff;
  }

  .form-toggle__input[disabled] .form-toggle__label {
    color: rgba(255, 255, 255, 0.5);
  }
}

.cookie-msg__toggle {
  align-items: center;
  appearance: none;
  display: flex;
  font-size: 0.875rem;
  font-weight: bold;
  text-transform: uppercase;
  text-align: left;
  padding-top: 2rem;
  padding-bottom: 0.2rem;
}

.cookie-msg__toggle--sub {
  text-transform: none;
  font-weight: normal;
  margin-top: -0.5rem;
}

.cookie-msg__toggle-icon {
  display: inline-block;
  margin-right: 0.5rem;
  width: 0.875rem;
  height: 0.875rem;

  .cookie-msg__toggle[aria-expanded="true"] & {
    transform: rotate(180deg);
  }

  .cookie-msg__toggle--sub & {
    width: 0.6rem;
    height: 0.9rem;
    font-size: 0.8rem;
  }
}

.cookie-msg__collapsible {
  display: none;
  margin-top: 1rem;

  .cookie-msg__toggle[aria-expanded="true"] + & {
    display: block;
  }
}

.cookie-msg__table {
  overflow-y: auto;
  font-size: 0.875rem;

  table {
    border-left: 1px solid #fff;
    min-width: 40rem;
  }

  table tr td:first-child {
    white-space: nowrap;
  }

  table td,
  table th {
    border-right: 1px solid #fff;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }

  table thead,
  table tr {
    border-bottom: 1px solid #fff;
  }

  table thead {
    border-top: 1px solid #fff;
  }

  a {
    color: #fff;
    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }
  }
}

.cookie-msg-open[type='button'] {
  appearance: none;
  color: $blue-dark;
}

.cookie-msg__subcheck {
  margin-bottom: 1rem;

  .form-toggle__label {
    color: #fff;
    font-weight: bold;
  }
}

.cookie-msg__sub {
  border-top: 1px solid rgba($gray-dark, 0.2);
  padding-top: 0.5rem;

  & + & {
    margin-top: 1rem;
  }
}

// @include media-breakpoint-up('md') {
//   .cookie-msg__form {
//     flex-direction: row;
//     justify-content: space-between;
//   }

//   .cookie-msg__actions {
//     padding-top: 0;
//   }

//   .cookie-msg__toggle {
//     padding-top: 0;
//   }
// }

@include media-breakpoint-up('xl') {
  .cookie-msg__text {
    width: 100%;
    margin-bottom: 1rem;
  }

  // .cookie-msg__actions {
  //   white-space: nowrap;
  // }
}

/**
 * Hide on print
 */
@media print {
  .cookie-msg {
    display: none;
  }
}
