$teaser-home-width-at-s: 200px;
$teaser-home-spacing-at-s: 20px;
$teaser-home-width-at-md: 225px;
$teaser-home-spacing-at-md: 22px;
$teaser-home-width-at-xl: 250px;
$teaser-home-spacing-at-xl: 25px;
$teaser-home-max-width: map-get($container-md-max-widths, 'xl');

/**
 * Base
 */


/**
 * Side variants
 */
.teaser--home-right,
.teaser--home-left,
.teaser--home-center {

  .teaser__frame {
    align-items: center;
    display: flex;
    justify-content: center;
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    text-align: center;

    margin-top: 2rem;
    margin-right: $article-spacing*1;
    padding: $teaser-home-spacing-at-s;
    width: 170px;
    height: 170px;
    border-radius: $teaser-home-width-at-s;
  }

  .teaser__container {
    @include make-absolute-container($teaser-home-max-width);
    top: 0;
    bottom: 0;
    margin-top: 0;
  }
  .teaser-home-symbol-stoerer {
    width: 2.25rem;
    height: 2.25rem;
    display: none;

    @include media-breakpoint-up('md') {
      display: inline;
    }
  }
  p {
    font-size: type-scale(-1);
    line-height: 1.25;
    margin: 0.5rem 0 0;
  }
  .teaser__actions {
    font-size: type-scale(-1);
    font-weight: bold;
    text-transform: uppercase;

    :before {
      content: '\00BB\00a0';
    }
    a:hover {
      text-decoration: underline;
    }
  }
  .teaser__frame--red,
  .teaser__frame--pink {
    p,
    a {
      color: $white;
    }
    .teaser-home-symbol-stoerer {
      color: $gray-light;
    }
  }
  .teaser__frame--red {
    background-color: $red;
  }
  .teaser__frame--pink {
    background-color: $magenta; // Changed because of contrast
  }

  @include media-breakpoint-up('sm') {
    .teaser__frame {
      margin-right: $article-spacing*1.5;
      width: 170px;
      height: 170px;
    }
  }

  @include media-breakpoint-up('md') {
    .teaser__frame {
      margin-top: -($teaser-home-width-at-md/5);
      margin-right: $article-spacing*3;
      padding: $teaser-home-spacing-at-md;
      width: $teaser-home-width-at-md;
      height: $teaser-home-width-at-md;
      border-radius: $teaser-home-width-at-md;
    }
    p {
      font-size: type-scale(0);
      margin: 1.25rem 0 0;
    }
  }

  @include media-breakpoint-up('xl') {
    .teaser__frame {
      margin-top: -($teaser-home-width-at-xl/4);
      margin-right: $article-spacing*3;
      padding: $teaser-home-spacing-at-xl;
      width: $teaser-home-width-at-xl;
      height: $teaser-home-width-at-xl;
      border-radius: $teaser-home-width-at-xl;
    }
    .teaser-home-symbol-stoerer {
      width: 2.5rem;
      height: 2.5rem;
    }
    p {
      font-size: type-scale(1);
      margin: 1.5rem 0;
    }
  }

}

.teaser--home-left {
  .teaser__frame {
    left: 0;
    margin-left: $article-spacing*3;
    margin-right: 0;
  }
}

.teaser--home-center {
  .teaser__frame {
    left: 50%;
    margin-left: -($teaser-home-width-at-xl/2);
    margin-right: 0;
  }
}
