$event-specs-icon-col-width: 2.5rem !default;
$event-specs-icon-size: 1.5rem !default;
$event-specs-row-spacing: 1rem !default;

.event-specs {
  @include list-unstyled;

  &__row {
    display: flex;
    align-items: center;

    &:not(:last-child) {
      margin-bottom: $event-specs-row-spacing;
    }
  }

  &__icon {
    display: flex;
    flex-direction: column;
    width: $event-specs-icon-col-width;
    font-weight: normal;
    color: $green-gray;
    font-size: 1.5rem;
  }

  &__desc {
    // width: 100%;
    width: calc(100% - #{$event-specs-icon-col-width});
  }

  &__title,
  &__text {
    display: block;
  }

  &__title {
    font-weight: bold;
    text-transform: uppercase;
  }

  // Small variant
  &--sm {
    @include type-scale(-1);
  }

  // Press detail page variant
  &--header {
    margin: 1rem auto;
    text-align: left;
    max-width: 230px;
  }
  &--press {
    margin-top: 2rem;
  }
  &--events {
    margin-top: 1.5rem;
    margin-bottom: 2.25rem;
  }
}
