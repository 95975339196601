.player {
  position: relative;

  &::before {
    content: '';
    display: block;
    padding-bottom: percentage(9/16);
  }

  > * {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
}
