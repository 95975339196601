$form-tabs-padding: 20px !default;
$form-tabs-border-color: $gray;

$form-tabs-menu-item-color: $black !default;
$form-tabs-menu-item-hover-color: $green-gray !default;
$form-tabs-menu-item-background-color: $gray-lighter !default;

$form-tabs-menu-item-active-color: $white !default;
$form-tabs-menu-item-active-hover-color: $white !default;
$form-tabs-menu-item-active-background-color: $green-gray !default;

$form-tabs-menu-item-padding-left: 5px !default;
$form-tabs-menu-item-padding-right: 7px !default;
$form-tabs-menu-item-padding-top: 5px !default;
$form-tabs-menu-item-padding-bottom: 4px !default;

$form-tabs-menu-link-padding-height: 2px !default;
$form-tabs-menu-link-padding-width: 10px !default;

.form-tabs {
  &__menu {
    @include type-scale(-1);
    @include list-unstyled;

    margin: 0;
    padding: 0;

    position: relative;
    top: 1px;
  }

  &__menu-item {
    padding-left: $form-tabs-menu-item-padding-left;
    padding-right: $form-tabs-menu-item-padding-right;
    padding-top: $form-tabs-menu-item-padding-top;
    padding-bottom: $form-tabs-menu-item-padding-bottom;
    position: relative;

    background-color: $form-tabs-menu-item-background-color;
    border-bottom: 1px solid $form-tabs-border-color;
    display: inline-block;

    // &:before {
    //   width: 0;
    //   content: '';
    //
    //   position: absolute;
    //   top: 0;
    //   right: 0;
    //
    //   border-top: 27px solid $white;
    //   border-left: 5px solid $form-tabs-menu-item-background-color;
    // }
  }

  &__menu-item--active {
    background-color: $form-tabs-menu-item-active-background-color;

    a {
      color: $form-tabs-menu-item-active-color;

      &:hover {
        color: $form-tabs-menu-item-active-hover-color;
      }
    }

    &:before {
      border-left: 5px solid $form-tabs-menu-item-active-background-color;
    }
  }

  &__menu-link {
    padding: $form-tabs-menu-link-padding-height $form-tabs-menu-link-padding-width;
    color: $form-tabs-menu-item-color;

    &:hover {
      color: $form-tabs-menu-item-hover-color;
    }
  }

  &__container {
    padding: ($form-tabs-padding*2) 0 $form-tabs-padding 0;

    border-top: 1px solid $form-tabs-border-color;
    display: none;

    // transition: height 0.5s ease-in-out;

    &--active {
      display: block;
    }
  }

  @include media-breakpoint-up('md') {
    &__menu {
      padding: 0 $form-tabs-padding;
    }

    &__container {
      margin: 0 $form-tabs-padding;
    }
  }
}
