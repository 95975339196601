/**
 * Communication teaser layout
 */
.comm-teaser {
  @include make-container-custom;
  @include make-container-max-widths;

  @include media-breakpoint-down('sm') {
    &__row {
      display: flex;
      flex-direction: column;
    }

    &__tile-container {
      order: -1;
    }
  }

  @include media-breakpoint-up('md') {
    padding-right: map-get($container-gutters, 'md');
    padding-left: map-get($container-gutters, 'md');
  }

  @include media-breakpoint-between('md', 'xl') {
    &__main-col {
      margin-bottom: $grid-gutter-width * 2;
    }

    &__tile-container {
      @include make-row;
    }

    &__tile-col-1,
    &__tile-col-2 {
      @include make-col(6);

      padding-top: $grid-gutter-width * 2;

      .tile:not(:last-child) {
        margin-bottom: $grid-gutter-width;
      }
    }
  }

  @include media-breakpoint-up('xl') {
    &__row {
      @include make-row;
    }

    &__main-col {
      @include make-col(6);

      padding-top: $grid-gutter-width;
    }

    &__tile-col-1,
    &__tile-col-2 {
      @include make-col(3);

      padding-top: $grid-gutter-width;

      .tile:not(:last-child) {
        margin-bottom: $grid-gutter-width;
      }
    }
  }
}
