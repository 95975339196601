.darkpage {
  background-color: rgba($blue-dark, 0.95);
  color: #fff;
  height: calc(100vh - 81px);
  padding-top: 4rem;
  position: absolute;
  overflow-y: auto;
  top: 114px;
  z-index: 198;
  left: 0;
  right: 0;

  @include media-breakpoint-up('md') {
    top: 127px;
  }

  @include media-breakpoint-up('lg') {
    top: 132px;
    height: calc(100vh - 132px);
  }
}

.darkpage__inner {
  @include make-container-custom;
  @include make-container-max-widths;

  padding-right: map-get($container-gutters, 'xs');
  padding-left: map-get($container-gutters, 'xs');

  @include media-breakpoint-up('md') {
    padding-right: map-get($container-gutters, 'md');
    padding-left: map-get($container-gutters, 'md');
  }

  @include media-breakpoint-up('xlg') {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
}

.darkpage__kicker {
  @include type-scale(-1);
  display: block;
  text-transform: uppercase;
  font-weight: bold;
  margin-top: $article-item-spacing-sm;
  margin-bottom: $article-item-spacing-sm;
}

.darkpage__title {
  @include type-scale(4);
  margin-bottom: $article-item-spacing-sm;
}

.darkpage__kicker-time {
  border-left: 1px solid #fff;
  margin-left: 0.5rem;
  padding-left: 0.5rem;
  font-weight: normal;
}

.darkpage__text,
.darkpage__teaser-text {
  padding-right: 1rem;

  a {
    color: #fff;
  }

  p > a:hover {
    text-decoration: none;
  }
}

.btn.darkpage__close {
  border: 1px solid #fff;
  color: #fff;

  &:hover {
    border-color: $blue-dark;
    background-color: #fff;
    color: $blue-dark;
  }
}

.darkpage__close--x {
  position: fixed;
  top: 144px;
  right: 10px;
  z-index: 2;

  @include media-breakpoint-up('lg') {
    top: 149px;
    right: 20px;
  }
}

.darkpage__content {
  @include media-breakpoint-up('xlg') {
    flex-basis: 65%;
    flex-grow: 1;
    max-width: 650px;
  }
}

.darkpage__teaser {
  margin-top: 2rem;

  @include media-breakpoint-up('xlg') {
    flex-basis: 35%;
    flex-shrink: 0;
    max-width: 400px;
    margin-top: 0;

    .darkpage__content + & {
      padding-left: 2rem;
    }
  }
}

.darkpage__teaser-image {
  img {
    margin-left: 0;
  }
}

.darkpage__footer {
  width: 100%;
  padding-bottom: 4rem;
}
