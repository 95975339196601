$groundplan-nav-space: 70px !default;
$groundplan-thumb-border-width: 2px !default;

$groundplan-main-image-width: 63.8% !default;
$groundplan-sidebar-width: (100% - $groundplan-main-image-width) !default;

$groundplan-main-image-width-at-xl: 60% !default;
$groundplan-sidebar-width-at-xl: (100% - $groundplan-main-image-width-at-xl) !default;

.groundplan {
  position: relative;
  margin-bottom: 2rem;

  &__nav {
    display: none;
  }

  &__main-image {
    margin-bottom: $spacer-y;
  }

  &__enlarge {
    position: absolute;
    top: 7px;
    right: 0;
    z-index: 1000;
    width: $groundplan-main-image-width-at-xl;
    height: 90%;
    text-align: right;
  }

  &__enlarge-label {
    @include type-scale(-1);
    font-weight: bold;
    padding: 10px;
    background-color: $white;
  }

  &__enlarge-icon {
    border: 1px solid $gray-light;
    border-radius: 2px;
    color: $blue-dark;
    margin-left: 5px;
    padding: 0 5px;
  }

  &__caption-link {
    display: block;
    @include type-scale(-1);
    font-weight: bold;
    background-color: $gray-light;
    text-transform: uppercase;
    color: $blue-strong;
    padding: 7px 10px;
  }

  &__list {
    @include list-unstyled;
  }

  &__list-title {
    color: $green-gray;
    @include type-scale(-1);
    font-weight: bold;
    text-transform: uppercase;
    padding: 5px 0 5px 20px;
    margin-top: 20px;
    margin-bottom: 10px;
    &:first-child {
      margin-top: 0;
    }
  }

  &__list-link {
    display: block;
    padding: 7px 0 7px 20px;
    &:hover {
      color: $black;
    }
  }

  &__list-link--active {
    color: $white;
    background-color: $blue-strong;
    background-image: svg-url('<svg xmlns="http://www.w3.org/2000/svg" viewBox="2 0 6 10" width="6" height="10"><path d="M2 8.5L5.2 5 2 1.5 3.4 0 8 5l-4.6 5z" fill="#ffffff"/></svg>');
    background-repeat: no-repeat;
    background-position: right 20px center;
    &:focus {
      color: $white;
    }
  }

  /**
   * Layout
   */
  // Large+ VPs
  @include media-breakpoint-up('lg') {
    &__dots {
      display: none !important; // Override inline styles
    }

    &__slide {
      @include clearfix;
    }

    &__main-image {
      float: left;
      width: $groundplan-main-image-width;
      margin-bottom: 0;
    }
    &--right &__main-image {
      float: right;
    }

    // &__info {
    //   margin-left: $groundplan-main-image-width;
    //   padding-top: $groundplan-nav-space;
    //   padding-left: $grid-gutter-width;
    // }
    // &--right &__info {
    //   margin-left: 0;
    //   margin-right: $groundplan-main-image-width;
    //   padding-left: 0;
    //   padding-right: $grid-gutter-width;
    // }

    &__nav {
      display: block;
      position: absolute;
      left: $groundplan-main-image-width;
      right: 0;
      margin-left: $grid-gutter-width;
    }
    &--right &__nav {
      left: 0;
      right: $groundplan-main-image-width;
      margin-left: 0;
      margin-right: $grid-gutter-width * 2;
    }

    &__nav {
      top: $grid-gutter-width;
    }
  }

  // X-Large VPs
  @include media-breakpoint-up('xl') {
    &__main-image {
      width: $groundplan-main-image-width-at-xl;
    }

    &__info {
      margin-left: $groundplan-main-image-width-at-xl;
    }
    &--right &__info {
      margin-right: $groundplan-main-image-width-at-xl;
    }

    &__nav {
      left: $groundplan-main-image-width-at-xl;
    }
    &--right &__nav {
      right: $groundplan-main-image-width-at-xl;
    }
  }
}

/**
 * No-JS Fallback
 */
.no-js .groundplan {
  &__nav {
    display: none;
  }

  &__slide:not(:first-child) {
    display: none;
  }
}
