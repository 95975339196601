.btn-group {
  @include list-unstyled;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  &--left {
    justify-content: flex-start;
  }

  &__item {
    padding: $grid-gutter-width / 4;
  }

  &--block &__item {
    flex: 1;
  }
  &--block .btn {
    display: block;
    text-align: center;
    white-space: nowrap;
  }
}
