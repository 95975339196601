.link-tile {
  position: relative;
  display: block;
  flex-grow: 1;
  padding: $grid-gutter-width;

  &:focus {
    color: #fff;
    outline-width: 10px;
    outline-offset: 4px;
  }
}

.link-tile {
  background-color: $green-gray;
  color: #fff;
  transition: background-color .2s;

  .link-tile__badge {
    color: $green-gray;
  }

  &:hover {
    color: #fff;
    background-color: darken($green-gray, 5%);
  }
}

.link-tile--blue {
  background-color: $blue-strong;

  .link-tile__badge {
    color: $blue-strong;
  }

  &:hover {
    background-color: darken($blue-strong, 5%);
  }
}

.link-tile--blue-dark {
  background-color: $blue-dark;

  .link-tile__badge {
    color: $blue-dark;
  }

  &:hover {
    background-color: darken($blue-dark, 5%);
  }
}

.link-tile--gray-dark {
  background-color: $gray-dark;

  .link-tile__badge {
    color: $gray-dark;
  }

  &:hover {
    background-color: darken($gray-dark, 5%);
  }
}

.link-tile__title {
  @include type-scale(2);
  margin-bottom: 0;

  .link-tile:focus & {
    text-decoration: underline;
  }
}

.link-tile__lead {
  margin-top: 1rem;
  margin-bottom: 0;
}

@include media-breakpoint-down('sm') {
  .link-tile {
    display: flex;
    justify-content: space-between;
  }

  .link-tile__badge {
    margin: ($grid-gutter-width / -2) ($grid-gutter-width / -2) 0 ($grid-gutter-width / 2);
  }

  .link-tile__symbol,
  .link-tile__lead {
    display: none;
  }
}

@include media-breakpoint-up('md') {
  .link-tile {
    padding-top: $grid-gutter-width * 1.5;
    padding-bottom: $grid-gutter-width * 1.5;
    text-align: center;
  }

  .link-tile__badge {
    position: absolute;
    top: $grid-gutter-width / 2;
    right: $grid-gutter-width / 2;
  }

  .link-tile__symbol {
    margin: 0 auto $grid-gutter-width;
    font-size: 5rem;
  }
}

@include media-breakpoint-up('xl') {
  .link-tile {
    padding-top: $grid-gutter-width * 2;
    padding-bottom: $grid-gutter-width * 2;
  }

  .link-tile__symbol {
    margin-bottom: $grid-gutter-width * 1.5;
    font-size: 7rem;
  }
}
