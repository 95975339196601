.cookie-fingerprint {
  bottom: 20px;
  padding-left: 20px;
  display: none;
  justify-content: flex-start;
  position: sticky;
  z-index: 1000;
}

.cookie-fingerprint--visible {
  display: flex;
}

.cookie-fingerprint__button {
  align-items: center;
  background-color: #fff;
  color: #000;
  display: flex;
  font-size: 16px;
  justify-content: center;
  border-radius: 2rem;
  box-shadow: 0px 3px 16px 0px rgba(0, 0, 0, 0.20);
  overflow: hidden;
}

.cookie-fingerprint__icon-wrapper {
  background-color: #fff;
  border-radius: 2rem;
  width: 4rem;
  height: 4rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  transition-duration: 0.2s;
  transition-property: background-color, color;

  .cookie-fingerprint__button:hover &,
  .cookie-fingerprint__button:focus & {
    background-color: darken($blue-dark, 8%);
    color: $white;
  }
}

.cookie-fingerprint__icon {
  color: #000;
  height: 2rem;
  width: 2rem;
  transition-duration: 0.2s;
  transition-property: background-color, color;

  .cookie-fingerprint__button:hover &,
  .cookie-fingerprint__button:focus & {
    color: $white;
  }
}

.cookie-fingerprint__button-text {
  // padding: 0 1.25rem;
  width: 0px;
  max-width: 0px;
  transition-duration: 0.2s;
  transition-property: max-width;

  .cookie-fingerprint__button:hover &,
  .cookie-fingerprint__button:focus & {
    width: 100%;
    max-width: 250px;
    padding: 0 1.25rem 0 1rem;
  }
}
