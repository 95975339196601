.service-nav {
  @include type-scale(-1);
  padding: 0.4rem 1rem;
  background-color: $mercury;
  color: $dove;
}

.service-nav__list {
  @include list-unstyled;
  display: flex;
  margin-bottom: 0;
  justify-content: flex-end;
}

.service-nav__item:not(:last-child) {
  margin-right: 1rem;

  &::after {
    content: '|';
    padding-left: 1rem;
  }
}

.service-nav__link {
  color: $dove;

  &:hover {
    color: $blue-strong;
  }
}

.service-nav__link-image {
  max-height: 16px;
}
