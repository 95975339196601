.article-headernav {
    @include list-unstyled;

    margin: 1rem 0 2rem 0;
    padding-bottom: 2rem;
    text-align: left;
    border-bottom: 1px solid $gray-lighter;

    display: flex;
    flex-direction: row;
    justify-content: space-between;

    &__item {
      padding: 0 10px 10px 10px;
      margin: 0 10px;
      flex-grow: 1;
      flex-basis: 33.333%;
      line-height: 1;
      display: inline;
      font-family: $font-family-special;
      text-align: center;
      border-bottom: 4px solid transparent;

      a, span {
        @include type-scale(1);
      }

      a {
        color: $black;
      }

      &--active,
      &:hover {
        border-bottom: 4px solid $green;
      }

      &--inactive {
        color: $gray;
      }

      &--inactive:hover {
        border-bottom: 0;
      }
    }

  // Large+ VPs
  @include media-breakpoint-up('lg') {
    justify-content: center;
    max-width: $article-body-width-wider-at-lg;
    padding-left: $article-body-spacing-at-lg / 1.5;
    padding-right: $article-body-spacing-at-lg / 1.5;

    &__item {
      flex-grow: 0;
    }
  }
}
