.content-header {
  &__kicker {
    @include type-scale(-1);
    display: block;
    margin-bottom: .5rem;
    color: $green-gray;
    text-transform: uppercase;
    font-weight: bold;
  }

  &__title {
    @include type-scale(4);
    margin-bottom: 1.5rem;
  }

  &__actions {
    @include type-scale(-1);
  }
}
