.control-btn {
  display: inline-block;
  line-height: 1;
  width: 1.25rem;
  height: 1.25em;
  background-color: $gray-lighter;

  &:hover {
    background-color: $brand-primary;
    color: $white;
  }

  &__icon {
    font-size: .7rem;
  }

  // Large variant
  &--lg {
    width: 3.125rem;
    height: 3.125rem;
    background-color: $white;
  }
  &--lg &__icon {
    font-size: 1rem;
  }
}

// Container
.control-btns {
  .control-btn + .control-btn {
    margin-left: .25rem;
  }
}
