.header-dropdown {
  display: none;
  position: absolute;
  top: 100%;
  z-index: 200;

  background-color: #fff;
  -webkit-box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.2);
  box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.2);

  text-align: left;

  &--right {
    right: 0;
  }

  &--immosuche {
    top: 83px;
  }

  &--left {
    left: 0;
  }

  &.open {
    display: block;
  }

  @include media-breakpoint-up('lg') {
    &--immosuche {
      right: 1rem;
      position: absolute;
    }
  }

}
