.teaser-sticky__content {
  padding: 35px;
  background-color: $gray-lightest;

  .teaser-sticky--highlight & {
    background-color: $blue;
    color: #fff;
  }
}

.teaser-sticky__image {
  margin-bottom: 0;
}

.teaser-sticky__kicker {
  @include type-scale(-1);

  display: block;
  color: $green-gray;
  text-transform: uppercase;
  font-weight: bold;
  margin-bottom: 20px;

  .teaser-sticky--highlight & {
    color: #fff;
  }

}

.teaser-sticky__title {
  margin-bottom: 15px;
}

.teaser-sticky__text {
  margin-bottom: 0;
}

.teaser-sticky__list {
  margin: 0;
  padding: 0 0 0 1em;
}
