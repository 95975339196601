@mixin link() {
  font-weight: bold;
  text-transform: uppercase;
  text-decoration: underline;
  @include type-scale(-1);

  &:before {
    content: '»';
    display: inline-block;
    margin-right: .4em;
    transform: translateY(-.05em);
    text-decoration: none;
  }

  &:hover {
    text-decoration: underline;
  }
}

@mixin link-inline() {
  font-weight: normal;
  text-transform: none;
  font-size: inherit;
  text-decoration: underline;

  &:before {
    content: '';
    margin: 0;
    transform: translateY(0);
    text-decoration: none;
  }

  &:hover {
    text-decoration: underline;
  }
}

@mixin basic-link() {
  text-decoration: underline;

  &:hover {
    text-decoration: underline;
  }
}

@mixin bare-link() {
  cursor: default;
  text-decoration: underline;

  &,
  &:hover {
    color: inherit;
    text-decoration: underline;
  }
}
