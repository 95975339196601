/**
 * Tooltip panel
 */

.info {
  position: relative;

  &__icon {
    position: relative;
    display: inline-block;
    width: 1.5rem;
    height: 1.5rem;
    border-radius: 50%;
    border: 2px solid $green-gray;
    line-height: 1;
    text-align: center;
    font-weight: bold;
    color: $green-gray;
  }

  &__char {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &__drawer {
    @include sr-only;
  }

  &__icon:focus ~ &__drawer,
  &:hover &__drawer {
    position: absolute;
    right: 0;
    top: 100%;
    width: 200px;

    // Reset sr-only
    height: auto;
    margin: 0;
    overflow: visible;
    clip: auto;
  }

  &__text {
    @include type-scale(-1);
    margin-top: .5rem;
    margin-bottom: 0;
    padding: 8px;
    outline: 8px solid $frame-color;
    background-color: $white;
    line-height: 1.2;
  }

  &:hover &__drawer {
    display: block;
  }
}
