/**
 * The main page header and navigation
 *
 * @todo
 */

// Define main lebel nav item specific colours from left to right
$navcolors:  (1, $blue-dark),(2, $blue-strong),(3, $green),(4, $green-lime),(5, $blue);
$nav-link-color: $black;

.header {
  background-color: $white;
  position: relative;
  min-height: 81px;
  margin-top: 2rem;

  // The centered logo
  &__nav-logo {
    position: absolute;
    top: -0.7rem;
    left: 50%;
    margin-left: -67px;
    width: 136px;
    z-index: 200;
    span {
      @include sr-only;
      @include sr-only-focusable;
    }

    &--sub {
      display: none;
    }
  }

  &__deg-logo > a {
    display: block;
  }

  // Nav buttons (Search, Login, Hamburger, etc.)

  &__btn {
    width: 48px;
    height: 45px;
    line-height: 1.5;
  }

  &__btn--search {
    padding-left: 0.3em;
  }

  &__nav-symbol {
    width: 1.5em;
    height: 1.5em;
    vertical-align: middle;
    margin-top: -0.2rem;
  }

  &__btn {
    span.header__btn-desc {
      @include sr-only();
      margin-left: 0.6em;
    }
    span.header__btn-desc--suche {
      margin-left: 0.3em;
    }
  }

  .header__btn-label {
    display: none;
  }

  &__function--immosuche {
    position: absolute;
    top: 17px;
    left: 10px;
  }

  &__function--login {
    display: none;
    text-align: left;
  }

  .header__nav-symbol--search {
    font-size: type-scale(-1);
    line-height: 1.6;
  }

  &__function--hamburger {
    position: absolute;
    top: 17px;
    right: 10px;
  }

  // Reset positioning and overflow for dropdowns
  &__nav-item-dropdown {
    position: static;
    overflow: hidden;
  }

  &__nav-list-dropdown,
  &__nav-list-dropdown--level3 {
    max-height: 0;
    transition: max-height .1s ease-in-out;
  }

  .open > &__nav-list-dropdown,
  .open > &__nav-list-dropdown--level3 {
    max-height: 600px;
  }

  // Nav items and wrapper
  &__nav {
    position: relative;
  }

  &__nav-list-wrapper {
    width: 100%;
    padding-top: 81px;
  }

  // The main navigation list(s)
  &__nav-list {
    @include list-unstyled;
    margin-bottom: 0;
    font-size: type-scale(0);
  }

  &__nav-list ul {
    font-size: 0.857em;
  }

  &__nav-list ul ul {
    font-size: 1em;
  }

  // Nav (list)items
  &__nav-item--level1 {
    display: block;
    margin-bottom: 1px;
  }

  // Nav links
  &__nav-link {
    color: $nav-link-color;
    display: block;
    padding: 0.375rem 0.5rem;
    &:hover {
      color: $nav-link-color;
      text-decoration: none;
    }
  }

  &__nav-link--level1 {
    display: block;
    position: relative;
  }

  &__nav-link--level2,
  &__nav-link--level3 {
    position: relative;
    display: block;
    padding: 0.500rem 0 0.500rem 0.625rem;
    // &:hover {
    //   background-color: $gray-lighter;
    // }
  }

  &__nav-list--level2.open &__nav-link--level2,
  &__nav-list--level3.open &__nav-link--level3 {
    background-color: $gray-lighter;
  }

  &__nav-link--level3 {
    padding-left: 1.250rem;
  }

  //Colored main navigation styles
  @each $navcolors, $color in $navcolors {
    &__nav-link--#{$navcolors},
    &__nav-list--#{$navcolors} a {
      border-left: 4px solid $color;
    }
    .open > &__nav-link--#{$navcolors} {
      background-color: $color;
      color: $white;
    }
  }

  //Menu item arrows (up and down) in dropdown
  .header__nav-item-dropdown > &__nav-link {
    background-image: svg-url('<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 2 10 6" width="10" height="6"><path d="M1.5 2L5 5.2 8.5 2 10 3.4 5 8 0 3.4z" fill="#323232"/></svg>');
    background-repeat: no-repeat;
    background-size: 0.857rem;
    background-position: center right 10px;
  }
  .header__nav-item-dropdown.open > &__nav-link {
    background-image: svg-url('<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 2 10 6" width="10" height="6"><path d="M8.5 8L5 4.8 1.5 8 0 6.6 5 2l5 4.6z" fill="#323232"/></svg>');
    background-repeat: no-repeat;
    background-size: 0.857rem;
  }
  .header__nav-item-dropdown.open > &__nav-link--level1 {
    background-image: svg-url('<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 2 10 6" width="10" height="6"><path d="M8.5 8L5 4.8 1.5 8 0 6.6 5 2l5 4.6z" fill="#fff"/></svg>');
  }

  .header__nav-item-dropdown.open > &__nav-link--level2 {
    background-color: $gray-lighter;
  }

  &__nav-search {
    display: block;
    margin: ($grid-gutter-width*3) 0.722rem $grid-gutter-width 0.722rem;
  }

  //Close button inside dropdown
  &__nav-item--close {
    display: none;
  }

  &__btn-close {
    font-size: type-scale(-1);
    margin-left: 0.25rem;
    padding: .4em .5em .3em .5em;
    color: $black;
    background-color: transparent;
    &:hover {
      background-color: transparent;
      color: $brand-danger;
    }
    .symbol {
      vertical-align: middle;
      margin-top: -3px;
      margin-left: 0.25rem;
    }
  }

  //Gradient colored top border inside dropdown
  &__nav-border-top,
  &__nav-border-top--mobile {
    // border: 0;
    border-top: 4px solid transparent;
    border-bottom: 0;

    -moz-border-image: -moz-linear-gradient(left, $blue-soft 0%, $green 50%, $blue 100%);
    -webkit-border-image: -webkit-linear-gradient(left, $blue-soft 0%, $green 50%, $blue 100%);
    border-image: linear-gradient(to right, $blue-dark 0%, $green 50%, $blue 100%);

    -webkit-border-image-slice: 1;
    border-image-slice: 1;
  }

  &__nav-border-top {
    display: none;
  }

  &__nav-mobile-btns {
    @include list-unstyled;
    margin: (0.75rem / 2);
  }

  &__nav-mobile-btn {
    display: inline-block;
    margin: (0.75rem / 2);
  }

  @include media-breakpoint-up('sm') {
    // > 540
    min-height: 95px;

    &__nav-logo {
      width: 154px;
      margin-left: -80px;
    }

    &__nav-list-wrapper {
      padding-top: 95px;
    }

    &__function--hamburger {
      top: 24px;
      right: 20px;
    }
    &__function--immosuche {
      top: 24px;
      left: 20px;
    }
  }

  @include media-breakpoint-up('lg') {
    // > 1240
    min-height: 100px;
    margin-top: 0;

    #navbar {
      display: block;
    }

    &__function--hamburger {
      display: none;
    }

    &__nav-logo {
      width: 154px;
      margin-left: -73px;

      &--sub {
        display: block;
        top: -79px;
      }
    }

    &__function {
      position: relative;
    }

    &__function--immosuche,
    &__function--login {
      display: block;
      width: 115px;
    }

    &__btn {
      width: auto;
      height: 45px;
    }

    &__btn--small {
      font-size: type-scale(-1);
      line-height: 1.6;
      background-color: transparent;
      color: $green-gray;
      padding-top: 0;
      padding-bottom: 0;
      padding-left: 0.8em;
      padding-right: 0.8em;
      border-right: 1px solid $green-gray;
      height: 1.5em;
      // margin-top: 0.6em;

      &:last-child {
        border-right: none;
        padding-right: 0;
      }

      &:hover,
      &:focus {
        color: $black;
        background-color: transparent;
      }
    }

    .header__btn--language {
      display: none;
    }

    .lang-switcher {
      display: none;
    }

    &__nav {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: space-between;
      padding-top: 1.55rem;
      padding-left: 1rem;
      padding-right: 1rem;
    }

    &__nav-item--close {
      position: absolute;
      top: 0;
      right: 0;
      display: block;
      width: 100%;
      text-align: right;
      font-size: type-scale(-1);
      margin: 0;
      padding: $grid-gutter-width;
    }

    // Switch the way gadient top border of navigation is shown
    &__nav-border-top {
      display: block;
    }

    &__nav-border-top--mobile {
      display: none;
    }

    &__nav-list-wrapper {
      flex-grow: 2;
      margin: 0;
      padding: 0;
    }

    // Reset font sizes for subnavigation
    &__nav-list ul,
    &__nav-list ul ul {
      font-size: type-scale(1);
    }

    // Subnavigation (dropdown styles)
    &__nav-list-dropdown {
      position: absolute;
      top: 68px;
      left: 0;
      z-index: 200;

      text-align: center;
      width: 100%;
      max-height: 0;

      opacity: 0;
      visibility: hidden;
      background-color: $white;

      -webkit-box-shadow: 0 60px 60px 13px rgba(0, 0, 0, 0.2);
      box-shadow: 0 60px 60px 13px rgba(0, 0, 0, 0.2);

    }

    .no-js &__nav-item--level1:hover &__nav-list-dropdown,
    &__nav-item--level1:focus &__nav-list-dropdown,
    .open &__nav-list-dropdown {
      min-height: 480px;
      max-height: 800px;
      opacity: 1;
      visibility: visible;
    }

    &__nav-list-dropdown--level3 {
      position: absolute;
      top: 0;
      left: 33%;
      width: 50%;
      height: 100%;
      padding-right: 10px;
      padding-left: 10px;
      background-color: $white;
      overflow: hidden;
      opacity: 0;

      transition: opacity .2s ease-out, width .2s ease-out;

    }

    &__nav-link--level1:focus,
    &__nav-link--level2:focus {
        outline-offset: -2px !important;
    }

    &__nav-link--level2:hover + &__nav-list-dropdown--level3,
    &__nav-link--level2:focus + &__nav-list-dropdown--level3,
    &__nav-item--level2.open &__nav-list-dropdown--level3,
    &__nav-list-dropdown--level3:hover {
      z-index: 197;
      opacity: 1;
      width: 67%;
      max-height: 600px;
      overflow: visible;
    }

    &__nav-list--level1 {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: center;
    }

    &--uneven &__nav-list--level1 {
      &::after {
        content: '';
        width: 4rem;
        height: 1rem;
        display: inline-block;
      }
    }

    &__nav-item--level1:nth-child(3) {
      padding-right: 4.5875rem;
    }

    &__nav-item--level1:nth-child(4) {
      padding-left: 4.5875rem;
    }

    &__nav-link {
      color: $nav-link-color;
      display: block;
      padding: 0.375rem 0.500rem;
      &:hover,
      &:focus {
        color: $nav-link-color;
        text-decoration: none;
      }
    }

    &__nav-link--level1 {
      line-height: 1.5;
      padding: 0.7em 0.8em 0.5em 0.8em;
    }

    //Menu item arrows (up and down) in dropdown
    .header__nav-item-dropdown > &__nav-link {
      background-image: svg-url('<svg xmlns="http://www.w3.org/2000/svg" viewBox="2 0 6 10" width="6" height="10"><path d="M2 8.5L5.2 5 2 1.5 3.4 0 8 5l-4.6 5z"/></svg>');
      background-size: 0.457rem;
    }
    .header__nav-item-dropdown.open > &__nav-link {
      background-image: svg-url('<svg xmlns="http://www.w3.org/2000/svg" viewBox="2 0 6 10" width="6" height="10"><path d="M2 8.5L5.2 5 2 1.5 3.4 0 8 5l-4.6 5z"/></svg>');
      background-size: 0.457rem;
    }
    .header__nav-item-dropdown > &__nav-link--level1,
    .header__nav-item-dropdown.open > &__nav-link--level1 {
      background-image: none;
      color: $black;
    }

    &__nav-list--twocols {
      column-count: 2;
      column-gap: 0;
    }

    &__nav-list--threecols {
      column-count: 3;
      column-gap: 0;
    }

    &__nav-link--level3 {
      max-width: 50%;
    }

    &__nav-list--twocols &__nav-link--level3 {
      display: inline-block;
      max-width: 100%;
      width: 100%;
    }

    &__nav-link--level1:hover,
    &__nav-link--level1:focus {
      background-color: transparent;
    }

    &__nav-link--level2,
    &__nav-link--level3 {
      &:hover {
        background-color: $gray-lighter;
      }
    }

    &__nav-search {
      display: none;
    }

    &__nav-list-wrapper-level2 {
      max-width: 1140px;
      min-width: 1024px;
      margin-left: auto;
      margin-right: auto;
      text-align: left;
    }

    &__nav-list-grid {
      @include make-container-gutters($teaser-container-gutters);
      margin-top: ($grid-gutter-width * 6);
      margin-bottom: ($grid-gutter-width * 2);
    }

    &__nav-list-grid-row {
      position: relative;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-between;
      margin-right: -10px;
      margin-left: -10px;
    }

    &__nav-list-grid-item {
      width: 33%;
      padding-right: 10px;
      padding-left: 10px;
    }

    &__nav-list-grid-item:first-child {
      padding-right: 0;
    }

    &__nav-level1-indicator {
      display: none;
      position: absolute;
      bottom: 0;
      left: 50%;
      margin-left: -4px;
      width: 0;
      height: 0;
      border-left: 8px solid transparent;
      border-right: 8px solid transparent;
      border-bottom: 8px solid $black;
    }

    @each $navcolors, $color in $navcolors {
      &__nav-link--#{$navcolors} {
        border-left: 0;
        // border-bottom: 4px solid transparent;
        &:focus {
          color: $black;
          // border-bottom: 4px solid $color;
        }
      }
      &__nav-list--#{$navcolors} a {
        border-left: 0;
      }
      .open > &__nav-link--#{$navcolors} {
        color: $black;
        background-color: transparent;
        // border-bottom: 4px solid $color;
      }
      .open > &__nav-link--#{$navcolors} &__nav-level1-indicator {
        display: block;
        border-bottom-color: $color;
      }
    }

    &__nav-list--level1 {
      margin-left: -0.7rem;
    }

    &__nav-item--level1 {
      padding: 0;
    }

    &__nav-mobile-btns {
      display: none;
    }

    &__function--immosuche {
      position: relative;
      top: auto;
      left: auto;
      text-align: right;
    }

  }

  @include media-breakpoint-up('xlg') {
    // up > 1140px

    &__nav-logo {
      &--sub {
        top: -81px;
      }
    }

    &__nav-list-dropdown {
      top: 70px;
    }

    &__nav-list {
      font-size: type-scale(1);
    }

    &__nav-link--level1 {
      padding: 0.5em 0.8em 0.5em 0.8em;
    }
  }

  @include media-breakpoint-up('xl') {
    // up > 1240px

    &__function--immosuche,
    &__function--login {
      width: 300px;
      white-space: nowrap;
    }

    .header__btn--language {
      display: inline-block;
    }

    .header__btn-label {
      display: inline-block;
    }
  }

  @include media-breakpoint-up('xxl') {
    // up > 1480px
    min-height: 105px;

    &__nav-list-dropdown {
      top: 72px;
    }

    &__nav {
      padding-top: 1.72rem;
    }

    &__nav-logo {
      width: 164px;
      margin-left: -80px;

      &--sub {
        top: -83px;
      }
    }

    &__nav-list--level1 {
      margin-left: -1.220rem;
    }

    &__nav-link--level1 {
      padding: 0.5em 1em 0.5em 1em;
    }

    &__nav-item--level1:nth-child(3) {
      padding-right: 5.8545rem;
    }

    &__nav-item--level1:nth-child(4) {
      padding-left: 5.8545rem;
    }

    &__function--login {
      width: 300px;
      white-space: nowrap;
    }

    &__function--immosuche {
      position: relative;
      width: 290px;
      text-align: right;
    }

    &__btn {
      span.header__btn-desc {
        display: inline-block;
      }
    }
  }

}
