.tel-function {

  &__text {
    display: none;
  }

  @include media-breakpoint-up('lg') {
    &__btn {
      display: none;
    }

    &__text {
      font-size: 1.7rem;
      display: inline;
    }
  }
}
