/**
 * Responsive visibility classes
 */

.hidden-md-down {
  @include media-breakpoint-down('md') {
    display: none !important;
  }
}

.hidden-lg-down {
  @include media-breakpoint-down('lg') {
    display: none !important;
  }
}
