.hero-slider {
  position: relative;
  overflow: hidden;

  img {
    width: 100%;
    display: block;
    max-width: none;
  }

  .resize {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 50%;
    overflow: hidden;
  }

  .handle {
    /* Thin line seperator */
    position: absolute;
    left: 50%;
    top: 0;
    bottom: 0;
    width: 4px;
    margin-left: -2px;
    background-color: rgba(0, 0, 0, 0.5);
    cursor: ew-resize;

    &:after {
      /* Big orange knob  */
      position: absolute;
      top: 50%;
      width: 64px;
      height: 64px;
      margin: -32px 0 0 -32px;
      content: '\21d4';
      color: white;
      font-weight: bold;
      font-size: 36px;
      text-align: center;
      line-height: 64px;
      background-color: #ffb800;
      /* @orange */
      border: 1px solid #e6a600;
      /* darken(@orange, 5%) */
      border-radius: 50%;
      transition: all 0.3s ease;
      box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3), inset 0 2px 0 rgba(255, 255, 255, 0.5), inset 0 60px 50px -30px #ffd466;
      /* lighten(@orange, 20%)*/
    }

    &.draggable:after {
      width: 48px;
      height: 48px;
      margin: -24px 0 0 -24px;
      line-height: 50px;
      font-size: 30px;
    }
  }
}
