.pager {
  &,
  &__pages {
    display: flex;
    flex-wrap: wrap;
    line-height: 1;
    justify-content: center;
    align-items: center;
  }

  &__pages {
    @include list-unstyled;
    margin: 0;
  }

  &__page,
  &__current {
    margin: 0 .125rem;
  }

  &__current {
    .pager__page-link {
      padding: 0;
      color: #fff;
    }
  }

  &__current,
  &__total {
    padding: .25rem .5rem;
  }

  &__page-link,
  &__count {
    padding: .25rem .5rem;
    color: $body-color;

    &:hover {
      background-color: $brand-primary;
      color: #fff;
    }
  }

  &__prev,
  &__next {
    padding: .25rem .75rem;
    background-color: $brand-primary;
    color: #fff;

    &:hover,
    &:focus {
      color: #fff;
      background-color: darken($brand-primary, 8%);
    }
  }

  &--hidden {
    display: none;
  }

  // Minimal pager for smaller VPs
  @include media-breakpoint-down('sm') {
    &__page {
      display: none;
    }

    &__prev {
      margin-right: .75rem;
    }

    &__next {
      margin-left: .75rem;
    }
  }

  // Extended pager for larger VPs
  @include media-breakpoint-up('md') {
    &__current {
      background-color: $brand-primary;
      color: #fff;
    }

    &__prev {
      margin-right: 1.25rem;
    }

    &__next {
      margin-left: 1.25rem;
    }
  }
}
